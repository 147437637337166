.body-comment {
	@include breakpoint(mobile) {
		margin: 30px 0 0;
	}
}
.comment-area {
	margin: 30px 0;

	@include breakpoint(mobile) {
		margin-bottom: 0;
	}


	.avatar {
		position: relative;
		float: left;
		height: 72px;
		width: 72px;
		overflow: hidden;
		border-radius: 100%;

		@include breakpoint(mobile) {
			height: 50px;
			width: 50px;
		}

		img {
			position: absolute;
		    top: 50%;
		    left: 50%;
		    bottom: initial;
		    right: initial;
		    max-width: 150%;
		    max-height: 150%;
		    min-width: 100%;
		    min-height: 100%;
		    transform: translate(-50%, -50%);
		}

		.default {
			max-width: 100%;
			// max-height: 100%;
		}
	}

	.comment-replay {
		max-height: 0;
    	overflow: hidden;
    	transition: all .3s ease-in-out;

    	&.show {
    		max-height: 300px;
    	}

		.form-comment {
			margin: 25px 0;

			@include breakpoint(mobile) {
				padding-left: 0;
				margin: 25px 0 0;
			}

			textarea {
				width: 100%;
	    		margin-left: 0;
			}
		}

		
	}
	.btn-area {
		text-align: right;
		.btn-primary {
			// float: right;
			display: inline-block;

			@include breakpoint(mobile) {
				width: auto;
				padding: 5px 30px;
    			font-size: 0.9em;
			}
		}	
	}
	

	.pagination {
		@include breakpoint(mobile) {
			margin-bottom: 40px;
		}
	}
}

.list-comment {
	display: block;
    overflow: hidden;
    margin: 50px 0 0;
    width: 100%;


}

.body-parent,
.body-child {

	.body-parent-wrap,
	.body-child-wrap {
		margin-bottom: 30px;
	}
	.body-child-wrap {
		margin-left: 90px;

		@include breakpoint(mobile) {
			margin-left: 0;
		}
	}

	.body-parent-detail-wrap {
		@include breakpoint(mobile) {
			margin-left: 10px;
		}
	}

	
}

.body-parent-detail,
.body-child-detail {
	margin-left: 90px;

	@include breakpoint(mobile) {
		margin-left: 65px;
	}
	
	h3 {
		font-size: 1em;
		display: inline-block;
		&.name {
			
			@include breakpoint(mobile) {
				width: 55%;
    			vertical-align: middle;
			}
			
		}
		&.publish-date {
			font-family: 'jenius-sans';
			color: $neutral-200;
			font-weight: lighter;

			@include breakpoint(mobile) {
				width: 40%;
    			vertical-align: middle;
			}
		}

		a {
			text-decoration: none;
			color: $neutral-300;	
			font-weight: 800;
			margin-right: 20px;

			@include breakpoint(mobile) {
				color: $neutral-200;
			}
		}
	}

	p {
		font-family: 'jenius-sans';
		font-size: 1em;
		color: $neutral-400;
		margin: 20px 0 5px;

		@include breakpoint(mobile) {
			margin: 15px 0 5px;
		}
	}

	h4 {
		font-family: 'jenius-sans';
		display: inline-block;
		font-size: 1em;
		color: $accent-300;

		@include breakpoint(mobile) {
			// display: none;
		}

		a {
			text-decoration: none;
			color: $accent-300;
		}
		img {
			vertical-align: bottom;
			margin: 0 20px;
			height: 1.5em;
			width: auto;
		}
		span {
			font-size: 2em;
		    margin: 0 10px;
		    font-weight: 600;
		}
	}
}

.body-child-detail {
	margin-left: 50px;

	@include breakpoint(tablet) {
		margin-left: 35px;
	}
}

.body-child {
	margin-top: 30px;

	@include breakpoint(tablet) {
		margin-left: 65px;
	}
}