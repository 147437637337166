.navigation {
    position: relative;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    ul {
        display: block;
        padding: 10px 0;
        margin: 0;
    }

    li {
        list-style: none;
        display: inline-block;
        padding: 0 8px;

        @media screen and(max-width: 1200px) {
            padding: 0 7px;
        }

        > a {
            position: relative;
            color: $accent-300;
            text-decoration: none;
            font-family: "jenius-sans";
            //font-size: 0.9em;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: all 0.3s ease-in-out;
            text-transform: uppercase;

            @media screen and(max-width: 1200px) {
                font-size: 0.8em;
            }

            &:after {
                position: relative;
                top: 5px;
                display: block;
                content: "";
                margin: 0 auto;
                height: 2px;
                background: $tertiary-300;
                width: 0;
                transition: opacity 0.3s ease, width 0.3s ease;
            }

            &:hover {
                color: $tertiary-300;
            }
        }

        &.active {
            > a {

                &:after {
                    position: relative;
                    top: 5px;
                    display: block;
                    content: "";
                    margin: 0 auto;
                    height: 2px;
                    background: none;
                    width: 100%;
                    background: $tertiary-300;
                    transition: all 0.3s ease-in-out;
                }

                &:hover {
                    &:after {
                        background: $tertiary-300;
                        width: 100%;
                    }
                }
            }

            ul {
                > li {
                    > a {
                        color: $accent-300;

                        &:after {
                            display: none;
                        }

                        &:hover {
                            color: $tertiary-300;
                        }
                    }
                }
            }
        }

        &.dropdown {
            &:hover {
                > ul {
                    visibility: visible;
                    opacity: 1;
                }
            }
            ul {
                a {
                    &:hover {
                        &:after {
                            background: none;
                            width: 0;
                        }
                    }
                }
            }
        }

        ul {
            background: $white;
            position: absolute;
            top: 52px;
            transition: all 0.3s ease-in-out;
            z-index: 9;
            box-shadow: 0px 5px 25px -8px rgba(47, 47, 47, 0.86);
            visibility: hidden;
            opacity: 0;
            z-index: 99;
        }

        li {
            width: 205px;
            a {
                display: block;
                padding: 10px 0;
                padding-left: 0;
                background: #fefefe;
                text-align: left;
                color: $accent-300;
                font-family: "jenius-sans";
                font-size: 0.9em;
                text-transform: uppercase;
                text-decoration: none;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: $tertiary-300;
                }
            }
        }
    }

    &.hide {
        visibility: hidden;
        opacity: 0;
    }
}

.navigation-hi-xs {
    @include breakpoint(desktop-down) {
        display: none;
    }
}

.navigation-vi-xs {
    display: none;
    @include breakpoint(desktop-down) {
        display: block;
    }
}

.mobile-navigation {
    margin: 0 -15px;
    max-height: 0;
    overflow: hidden;
    overflow-y: auto;
    height: 100vh;
    background: white;
    background-size: cover;
    background-position: center;
    z-index: 99;
    transition: all 0.5s ease-in-out;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);

    @include breakpoint(desktop-down) {
        padding: 0 20px;
    }

    // @media screen and (min-width: 992px) {
    // 	display: none;
    // }

    .lougout-content {
		padding: 0px 15px;
        .btn-accent {
			color: #fff;
			background: #fdb916;
			border: 1px solid #fdb916;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 0.775em;
			font-family: "jenius-sans";
			font-weight: 400;
			padding: 0 35px;
			-webkit-border-radius: 30px;
			-moz-border-radius: 30px;
			border-radius: 30px;
			letter-spacing: 1px;
			-webkit-transition: all 0.3s ease-in-out;
			-o-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
			padding: 11px;
			width: 160px;
			display: block;
			text-align: center;
			&:hover {
				background: #cb8f00;
			}
        }
    }

    @include breakpoint(desktop-down) {
        &.show {
            max-height: 600px;
            z-index: 9999;
        }
    }

    .register-area {
        // margin-top: 70px;

        @media screen and(max-width: 1030px) {
            margin-top: 30px;
        }
        ul {
            padding: 0;
            margin: 15px 0 0;
            display: block;
            text-align: center;
        }

        li {
            list-style: none;
            display: inline-block;
            padding: 0 5px;

            &.signin {
                a {
                    font-weight: 600;
                    color: $accent-300;
                    background: transparent;
                    border: 1px solid $tertiary-300;

                    &:hover {
                        border-color: $tertiary-300;
                        background: $tertiary-300;
                    }
                }
            }
            &.signup {
                a {
                    font-weight: 600;
                    color: $accent-300;
                    background: $tertiary-300;
                    border: 1px solid $tertiary-300;

                    &:hover {
                        color: $white;
                        border-color: $accent-300;
                        background: $accent-300;
                    }
                }
            }
        }

        a {
            text-decoration: none;
            text-transform: uppercase;
            font-size: 0.775em;
            font-family: "jenius-sans";
            font-weight: 400;
            padding: 0 35px;
            border-radius: 30px;
            letter-spacing: 1px;
            transition: all 0.3s ease-in-out;
        }
    }

    .signout-area {
        margin: 0 15px 0;
        position: relative;
        z-index: 1;
        .signout {
            font-family: "jenius-sans";
            font-size: 1em;
            color: $accent-300;
            text-transform: uppercase;
            padding: 8px 50px;
            border: 1px solid $accent-300;
            border-radius: 30px;
        }
    }

    .info-area {
        margin: 30px 15px 0;
        padding-bottom: 10px;
        h3 {
            font-family: 'jenius-sans';
            display: inline-block;
            font-size: 1.1em;
            font-weight: bold;
            color: $accent-300;
            line-height: 1.5;
            border-bottom: 1px solid $tertiary-300;
        }
        p {
            font-family: 'jenius-sans';
            font-size: 0.8em;
            color: $accent-300;
            margin-top: 10px;
            line-height: 1.8;
            width: 65%;
        }
    }

    ul {
        display: block;
        padding: 15px;
        margin: 0;
        > li {
            list-style: none;
            display: block;

            > a {
                font-family: 'jenius-sans';
                position: relative;
                display: block;
                text-decoration: none;
                font-size: 1em;
                font-weight: 600;
                color: $accent-300;
                line-height: 3.2;
                border-bottom: 1px solid $accent-50;
                z-index: 9;
            }

            &:last-child {
                > a {
                    border-bottom: none;
                }
            }
            &.profile {
                position: relative;
                span {
                    position: absolute;
                    right: 0;
                    top: 12px;
                    text-align: center;
                    display: block;
                    background: $red;
                    color: $white;
                    font-family: "jenius-sans";
                    font-size: 1em;
                    padding: 0 4px;
                    height: 25px;
                    width: max-content;
                    margin: 0;
                    border-radius: 16px;
                }
                p {
                    margin: 0;
                    color: $white;
                    position: relative;
                    top: -9px;
                }
            }
            &.dropdown {
                position: relative;
                .arrow-dropdown {
                    cursor: pointer;
                    position: absolute;
                    content: "";
                    display: block;
                    height: 45px;
                    top: 0;
                    right: 0;
                    width: 30px;
                    background: url(../img/sprite-arrow-brown.png) no-repeat;
                    background-position: 7px 17px;
                    background-size: contain;
                    background-size: 40px;
                    z-index: 9;
                }
                ul {
                    padding: 0;
                    display: none;
                    > li {
                        > a {
                            padding: 0 30px;
                            font-weight: 400;
                        }
                        &:last-child {
                            a {
                                border-bottom: 1px solid $accent-50;
                                // border-bottom: none;
                            }
                        }
                    }
                }

                &.active {
                    .arrow-dropdown {
                        background: url(../img/sprite-arrow-brown.png) no-repeat;
                        background-position: -16px 15px;
                    }
                }
            }
        }
    }
}
