.produk-wrapper{
	background: url(../img/backgroun_produk_keuangan.png) no-repeat;
	height: 100vh;
    background-size: cover;
	background-position: top center;
	position: relative;

	&::before {
		background: white;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		opacity: 0.8;
		position: absolute;
	}
}

.breadcrumbs {
	&.breadcrumbs-landing-produk {
		position: absolute;
		top: 60px;
		left: 0px;
	
		ul {
			li {
				color: $accent-300;
				font-weight: normal;
				font-family: 'jenius-sans';
				font-style: italic;
				
				&.active {
					font-family: 'jenius-sans';
					font-weight: bold;
				}
			}

			a {
				color: $accent-300;
			}
		}
	}
}

.container {
	//produk keuangan form
	&.produk-content {
		margin-top: 300px;

		@media screen and (max-width:768px) {
			margin-top: 150px;
		}

		@media screen and (max-width:1920px){
			margin-top: 150px;
		}

		@media screen and (max-width:1366px){
			margin-top: 150px;
		}
	
		.produk-header {
			margin-top: 16px;
			color: $neutral-300;
			font-family: 'jenius-sans';
			padding-left: 0px;
			margin-bottom: 10px;

			h2 {
				font-size: 2.2rem;
				line-height: 1.5;

				@media screen and (max-width:768px) {
					font-size: 5.6vw;
					line-height: 1.5;
				}
			}

			p {
				//font-size: 1.3em;
				font-family: 'jenius-sans' !important;
				margin-bottom: 20px;

				@media screen and (max-width:768px) {
					//font-size: 4vw;
				}
			}
		}
	
		.produk-form {
			.produk-form-content {
				margin-left: 0px;

				.form-group {
					.input-custom {
						background-color: $neutral-50;
						.label-input {
							font-weight: bold;
							font-family: 'jenius-sans';
							line-height: 3.1;
							color: $neutral-200;
							font-size: 1.2rem;
							display: inline-block;

							@media screen and (max-width:768px) {
								line-height: 3.5;
								margin: 0px;
								font-size: 9px;
								padding-right: 0px;
							}
						}

						p {
							display: inline-block;
							color: $neutral-200;
							line-height: 4;
							font-weight: bold;

							@media screen and (max-width:768px) {
								line-height: 2.5;
							}
						}

						.list-kategori-produk {
							padding-left: 0px;
							span.jcf-option.jcf-disabled{
								display: none;
							}
							
						}

						select {
							display: inline-block;
							cursor: pointer;
							padding-left: 0px;
						}

						.form-control {
							optgroup { 
								line-height: 4;
								color: $neutral-200;
								margin-left: 0px;
								background-color: $neutral-75;
								font-size: 12px;
								
								option {
									font-size: 18px;
									background-color: $neutral-50;
								}
							}
						}

						.jcf-select {
							border: none;
							margin: 15px 0px auto 0px;
							height: 35px;
							background-color: $neutral-50;
							width: 100%;

							@media screen and (max-width:768px) {
								margin: 0px;
							}

							.jcf-select-text {
								font-size: 16px;
								margin-left: 35px;
								line-height: 35px;
								background-color: $neutral-50;
								margin: 0 auto 0 8px;

								@media screen and (max-width:768px) {
									font-size: 12px;
								}
							}

							.jcf-select-opener {
								background-color: $white;
								background: url(../img/icon-arrow-down.png) no-repeat;
								position: absolute;
								top: 35%;
							}

							&.jcf-focus {
								.jcf-select-opener {
									background-color: $white;
									background: url(../img/icon-arrow-up.png) no-repeat;
									position: absolute;
									top: 35%;
								}
							}

							.jcf-select-drop {
								//custom width option list
								&.jcf-select-form-control {
									&.jcf-select-jcf-hidden {
										&.jcf-unselectable {
											width: 258px!important;
											@media screen and (max-width:768px) {
												width: 150px!important;
											}
										}
									}
								}

								//custom option list
								.jcf-list {
									ul {
										li {
											padding: 10px;
											font-size: 18px;

											@media screen and (max-width:768px) {
												font-size: 14px;
												padding: 0px;
											}

											span {
												//custom label option group
												.jcf-optgroup-caption {
													color: $neutral-200;
													margin-left: 0px;
													background-color: $neutral-75;
												}
											}
										}
									}
								}

								//custom when list hover
								.jcf-option {
									&.jcf-hover {
										background: none!important;
										cursor: pointer;
									}
									&.jcf-selected {
										background: $accent-300 !important;
										color: $tertiary-300 !important;
										@media screen and (min-width: 767px)  {
											margin: -10px;
											padding: 15px 19px;
										}
									}
								}
							}
						}
					}

					.form-submit {
						margin-left: 0px;

						@media screen and (max-width:768px) {
							background-color: $accent-300;
							border-radius: 50px;
							text-align: center;
						}
						
						.btn-submit {
							background: $accent-300;
							padding: 10px 20px 10px 20px;
							border-radius: 50px;
							color: white;
							font-size: 18px;

							@media screen and (max-width:768px) {
								padding: 5px;
								font-family: 'jenius-sans';
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}

	//produk keuangan landing page
	.produk-content-detail {
		margin-top: 70px;
		padding-left: 15px;
		padding-right: 15px;

		@media screen and (max-width:768px) {
			margin: 110px 0px 0px 0px;
		}

		.content-breadcrumbs {
			padding: 0px;

			ul {
				li {
					font-weight: normal;
					font-family: 'jenius-sans';
					font-style: italic;
					
					&.active {
						font-family: 'jenius-sans';
						font-weight: bold;
					}
				}
			}
		}

		// .tab-sorting{
		// 	&.tab-sorting-mobile{
		// 		//Sorting Landing Page Mobile
		// 		.dropdown-child {
		// 			a {
		// 				&::after {
		// 					position: absolute;
		// 					content: "";
		// 					right: 18px;
		// 					top: 21px;
		// 					height: 15px;
		// 					width: 15px;
		// 					background: url(../img/icon-arrow-down.png);
		// 					background-repeat: no-repeat;
		// 					-webkit-background-size: 20px 20px;
		// 					-moz-background-size: 20px;
		// 					background-size: 20px;
		// 					background-position: center;
		// 				}
		// 			}
		// 		}

		// 		ul {
		// 			&.parent-category {
		// 				>li {
		// 					width: 100%;
		// 				}
		// 			}
		// 		}

		// 		.category-wrap {
		// 			ul {
		// 				> li {
		// 					padding: 0px;

		// 					&.selected {
		// 						a {
		// 							@media screen and (max-width:768px) {
		// 								color: $accent-300;
		// 							}
		// 						}
		// 					}

		// 					&.disabled {
		// 						a {
		// 							@media screen and (max-width:768px) {
		// 								color: #777777;
		// 								font-weight: bold;
		// 							}
		// 						}
		// 					}
		// 				}
		// 			}

		// 			.landing-mobile {
		// 				@media screen and (max-width:768px) {
		// 					text-align: center;

		// 					.btn-gold-white {
		// 						padding: 5px 20px!important;
		// 						background-color: $accent-300!important;
		// 						border-radius: 50px!important;
		// 						margin: 30px auto;
		// 						color: $white;
		// 						font-family: 'Helvetica'!important;
		// 						width: 50%!important;
		// 						font-size: 3.5vw!important;
		// 					}
		// 				}
		// 			}
		// 		}

		// 	}
		// }

		.produk-caption {
			@media screen and (max-width:768px) {
				margin-bottom: 20px;
			}
			h2 {
				color: $accent-300;
				line-height: 2;
				font-size: 2.25em;

				@media screen and (max-width:768px) {
					line-height: 1.2;
    				font-size: 2em;
				}
				
			}
		}

		.produk-description {
			@media screen and (max-width:768px) {
				margin-bottom: 20px;
			}
			h4 {
				font-family: 'jenius-sans';
				line-height: 1.7;
				@media screen and (max-width:768px) {
					font-size: 14px;
					line-height: 1.3;
				}
			}
		}

		.produk-kategori {
			margin: 30px 0px 30px 0px;
			padding-left: 15px;
			padding-right: 15px;

			.produk-kategori-wrap {
				background-color: $neutral-50;

				@media screen and (max-width:768px) {
					position: absolute;
					top: 60px;
					left: 15px;
					width: 100%;
					right: 15px;
					background-color: $white;
					z-index: 10;
				}

				.m-kategori {
					@media screen and (max-width:768px) {
						padding:0px;
					}
				}

				.produk-kategori-label {
					text-align: center;

					@media screen and (max-width:768px) {
						padding-left: 0px;
						padding-right: 0px;

						
					}
					
					label {
						color: $neutral-200;
						font-size: 15px;
						padding: 0px;
						margin: 0px;
						display: inline-block;
						line-height: 3.5;

						&::after{
							@media screen and (max-width:768px) {
								position: absolute;
								content: "";
								right: 18px;
								top: 21px;
								height: 15px;
								width: 15px;
								background: url(../img/icon-arrow-down.png);
								background-repeat: no-repeat;
								-moz-background-size: 20px;
								background-size: 20px;
								background-position: center;
							}

							
						}

						&.down {
							background: transparent;

							&::after{
								@media screen and (max-width:768px) {
									position: absolute;
									content: "";
									right: 18px;
									top: 21px;
									height: 15px;
									width: 15px;
									background: url(../img/cancel_19_19.png);
									background-repeat: no-repeat;
									-moz-background-size: 20px;
									background-size: 20px;
									background-position: center;
								}
							}
						}

						

						@media screen and (max-width:768px) {
							font-family: 'jenius-sans';
							position: relative;
							display: block;
							background: $neutral-50;
							text-decoration: none;
							font-size: 1.2em;
							font-weight: 600;
							color: $neutral-200;
						}
					}
				}

				.produk-kategori-list {
					span {
						cursor: pointer;
						font-size: 15px;

						&.jcf-select {
							&.jcf-unselectable {
								&.jcf-select-form-control{
									height: 50px;
									width: 100%;
									margin-bottom: 0px;
									border: none;
									background-color: transparent;

									.jcf-select-text {
										margin: 0px;
										padding: 13px;
										background-color:$neutral-50;

										@media screen and (max-width:768px) {
											background-color:$white;
										}

										span.jcf-option-visible-xs {
											@media screen and (max-width:768px) {
												text-decoration: none;
												font-family: 'jenius-sans';
												font-size: 1.2em;
												font-weight: 600;
												color: $neutral-200;
											}
										}
									}

									.jcf-select-opener {
										background-color:$neutral-50;
										background: url(../img/icon-arrow-down.png) no-repeat;
										position: absolute;
										top: 35%;
									}

									&.jcf-focus {
										border: none;

										.jcf-select-opener {
											background-color: $neutral-50;
											background: url(../img/icon-arrow-down.png) no-repeat;
											position: absolute;
											top: 35%;

											@media screen and (max-width:768px) {
												background: url(../img/icon-arrow-down.png) no-repeat;
											}
										}

										&.jcf-drop-active{
											.jcf-select-opener {
												background-color: $neutral-50;
												background: url(../img/icon-arrow-up.png) no-repeat;
												position: absolute;
												top: 35%;

												@media screen and (max-width:768px) {
													background-color: $neutral-50;
													background: url(../img/icon-arrow-up.png) no-repeat;
													position: absolute;
													top: 35%;
												}
											}
										}
									}

									.jcf-list-content {
										//Untuk menghilangkan list pertama option
										@media screen and (max-width:768px) {
											ul {
												li:nth-child(1){
													border-bottom: none;
													padding: 0px;
												}
											}

											li {
												padding: 10px 5px;
												border-bottom: 1px solid $neutral-200;

												ul {
													li {
														border-bottom: none;
														padding-left: 0px;
													}
												}
											}
										}
									}

									.jcf-select-drop {
										.jcf-selected  {
											background: $accent-300;
											color: $tertiary-300;
										}
									}

								}
							}
						}
					}

					span.jcf-option.jcf-disabled{
						display: none;
					}

					@media screen and (max-width:768px) {
						border-bottom: 1px solid $neutral-50;
						display: none;
					}

					&.active {
						@media screen and (max-width:768px) {
							border-bottom: 1px solid $neutral-50;
							display: block;
						}
					}

					
				}

				.m-btn-temukan-produk {
					@media screen and (max-width:768px) {
						//margin: 30px;
						display: none;
					}

					&.active {
						@media screen and (max-width:768px) {
							border-bottom: 1px solid $neutral-50;
							display: block;
						}
					}
				}

				.produk-kategori-button {
					margin-left: 0px;
					line-height: 3.5;

					@media screen and (max-width:768px) {
						// background-color: $accent-300;
						border-radius: 50px;
						text-align: center;
					}
					
					button {
						&.btn {
							&.btn-gold-white {
								margin: 0px;
								width: auto;
								padding: 6px 12px;
								&:focus,
								&:hover {
									background-color: $accent-300;
									border: 1px solid $accent-400;
									color: $white;
								}
							}
						}

						@media screen and (max-width:768px) {
							padding: 5px 15px 5px 15px!important;
							font-size: 12px;
						}
					}

					
				}
			}
		}

		.produk-list {
			margin: 20px 0px 0px 0px;

			@media screen and (max-width:768px) {
				margin: 0px;
			}
			.produk-list-box {
				padding-left: 15px;
				padding-right: 15px;
				margin-bottom: 20px;
				position: relative;

				&:nth-child(3n+1){
					clear:left;
				}
				
				@media screen and (max-width:768px) {
					margin-bottom: 20px;
					-webkit-box-shadow: 0px 10px 16px -14px rgba(0,0,0,0.75);
					-moz-box-shadow: 0px 10px 16px -14px rgba(0,0,0,0.75);
					box-shadow: 0px 10px 16px -14px rgba(0,0,0,0.75);
				}

				.produk-list-box-border {
					border: 1px solid $neutral-75;
					border-radius: 10px 10px 0px 0px;
					min-height:470px;

					@media screen and (max-width:768px) {
						border-radius: 5px;
						min-height:auto;
					}

					.produk-list-image {
						width: 100%;
						cursor: pointer;

						@media screen and (max-width:768px) {
							margin-bottom: 10px;
						}

						img {
							object-fit: cover;
							border-radius: 10px 10px 0px 0px;
							width: 100%;
							aspect-ratio: 5/4;

							@media screen and (max-width:768px) {
								aspect-ratio: 16/9;
								border-radius: 5px 5px 0px 0px;
							}
						}
					}

					.produk-list-caption {
						h4 {
							@include font-jenius-serif;
						}
						padding-left: 20px;
						padding-right: 20px;

						@media screen and (max-width:768px) {
							margin-bottom: 10px;
						}

						h4 {
							color: $accent-300;
							line-height: 2;
							font-size: 1.5em;

							@media screen and (max-width:768px) {
								line-height: 1.5;
								font-size: 5vw;
							}
						}
					}
		
					.produk-list-description {
						margin: 0px 0px 10px 0px;
						padding-left: 20px;
						padding-right: 20px;

						h5 {
							line-height: 1.5;
							color: $neutral-200;
							font-family: 'jenius-sans';
							font-size: 16px;

							@media screen and (max-width:768px) {
								font-size: 4vw;
							}
						}
					}
		
					.produk-list-link {
						text-align: right;
						margin: 15px;
						position: absolute;
						bottom: 0;
						right: 15px;
						@media screen and (max-width:768px) {
							position: relative;
							bottom: 0;
							right: 0;
						}

						a {
							color: $accent-300;
							font-family: 'jenius-sans';	
							cursor: pointer;

							@media screen and (max-width:768px) {
								font-size: 3.5vw;
								font-weight: bold;
							}
						}
					}
				}
			}
		}
	}
}

// .navigation {
// 	li {
// 		> a {
// 			font-size: 12px!important;
// 		}
// 	}
// }

.perhitungan-pinjaman-konten {
 > table {
	 display: block;
	 padding:15px;
 }

	@media screen and (max-width:768px) {
 		.form-control{
			font-size: 17px !important;
		}
	}
}

.form-area-business {
	.form-area {
		.form-group {
			height: auto !important;
			margin:0;
			clear:both;

			.input-wrap {
				overflow: visible;
			}
		}
	}
}