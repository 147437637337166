header {
	&.site-header {
		position: fixed;
	    left: 0;
	    right: 0;
	    top: 0;
	    z-index: 99;
		background: $white;
		padding: 20px 0;
		box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);

		@media screen and(max-width: 1200px) {
			padding: 0;
			height: 60px;
		}


		&:after {
			position: absolute;
		    content: "";
		    left: 0;
		    right: 0;
		    top: 65px;
		    width: 100%;
		    height: 10px;
    		z-index: 99;

    		@media screen and(max-width: 1030px) {
    			box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
    			top: 50px;
    		}
		}

		>.container {
			@media screen and(max-width: 1200px) {
				height: 60px;
			}
			.row {
				position: relative;

				@media screen and(max-width: 1200px) {
					position: relative;
    				top: 5px;
					// height: 60px;
				}

				@media screen and(max-width: 1115px) {
					top: 0;
					position: initial;
				}

				>.col-md-1 {
					@media screen and(max-width: 1200px) {
						position: relative;
						top: 15px;
					}
					@media screen and(max-width: 1115px) {
						position: absolute;
    					top: 50%;
    					transform: translateY(-50%);
    					z-index: 1;
					}
				}
			}
		}

		.site-brand {
			a {
				display: inline-block;
    			position: relative;
    			z-index: 2;

				img {
					height: 48px;
					width: auto;
				}
			}
		}


		.site-account {
			float: right;
			display: flex;
			align-items: center;

			@include breakpoint(desktop) {
				float: none;
				margin-right: 0;
			}

			ul {
				padding: 5px 0;
				margin: 0;
				display: block;

				
			}

			li {
				list-style: none;
				display: inline-block;
			}

			a {
				text-decoration: none;
			}

			.search-btn-wrap {
				display: inline-block;

				&.before-login {
					// position: relative;
					// top: 8px;
				}
			}

			
		}

		.list-account {
			position: relative;
		    display: flex;
			align-items: center;
		    z-index: 1;
		    height: 45px;
			@include breakpoint(desktop) {
				margin-left: 1rem;
			}

		    .avatar-url {
		    	display: flex;
				align-items: center;
		    	cursor: pointer;
		    	position: relative;
				padding: 10px 0;
				z-index: 1;
			}

			.notif-red{
				position: absolute;
				background-color: $red;
				width: 8px;
				height: 8px;
				z-index: 1;
				border-radius: 10px;
				right: 0;
				top: 0;
			}
			&.search-item {
				position: relative;
				top: 5px;
				margin-right: 12px;

				@include breakpoint(tablet) {
					top: 8px;
					margin-right: 0;
				}
			}


			&.avatar-item {
				
				ul {
					background: $white;
					padding: 0;
					position: absolute;
					top: 63px;
					transition: all .3s ease-in-out;
					z-index: 9;
					visibility: hidden;
					opacity: 0;
					z-index: 99;
					box-shadow: 0px 5px 25px -8px rgba(47,47,47,0.86);

					

					&.show {
						visibility: visible;
						opacity: 1;
					}
				}

				li {
					width: 190px;
					
					&:first-child {
						>a {
							padding-top: 25px;
						}
					}
					&:last-child {
						>a {
							padding-bottom: 25px;
						}
					}
				}

				a {
					display: block;
					padding: 10px 15px;
					background: $neutral-50;
					text-align: left;
					color: $neutral-300;
					font-size: 0.9em;
					text-transform: uppercase;
					text-decoration: none;
					transition: all .3s ease-in-out;

					&:hover {
						color: $tertiary-300;
					}

					
				}
			}
			&.register-item {
				ul {
					position: relative;
					// top: 10px;
					display: block;
					padding: 0;
					margin: 0;
					
				}

				li {
					list-style: none;
					display: inline-block;
					margin-left: 5px;
					
					&.signin {
						a {
							color: $accent-300;
							background: transparent;
							border: 1px solid $tertiary-300;

							&:hover {
								background: $tertiary-300;
							}
							&:disabled {
								background: $neutral-75;
							}
						}
					}
					&.signup {
						a {
							color: $accent-300;
							background: $tertiary-300;
							border: 3px solid transparent;

							&:hover {
								background: $accent-300;
								color: $white;
							}
							&:disabled {
								background: $neutral-75;
							}
						}
					}
				}

				a {
					text-decoration: none;
					text-transform: uppercase;
					font-size: 0.875em;
					padding: 10px 20px;
					border-radius: 30px;
					letter-spacing: 1px;
					transition: all .3s ease-in-out;

					@media screen and(max-width: 1200px) {
						font-size: 0.7em;
					}
				}
			}
			span {
				
				&.text-account {
					padding-right: 20px;
					&:after {
						position: absolute;
					    content: "";
					    right: 0;
					    top: 5px;
					    background: url(../img/icon-arrow-down.png);
					    background-repeat: no-repeat;
					    height: 10px;
					    width: 20px;
						@include breakpoint(mobile) {
							padding-right: 0;
							display: none;
						}
					}
				}
			}
			
			a {
				text-decoration: none;
			}

			.avatar {
				position: relative;
				display: block;
				height: 35px;
				width: 35px;
				// overflow: hidden;
				float: left;
				margin-right: 10px;
				

				img {
					position: absolute;
				    top: 50%;
				    left: 50%;
					border-radius: 100%;
				    bottom: initial;
				    right: initial;
				    width: 100%;
					height: 100%;
					object-fit: cover;
				    transform: translate(-50%, -50%);
				}

				.default {
					max-width: 100%;
					// max-height: 100%;
				}
			}
		}	

		span {
					
			&.text-account {
				font-size: 1em;
				color: $accent-300;
				position: relative;
				// top: 5px;
				padding-right: 30px;
				// padding-right: 40px;

				@include breakpoint(tablet) {
					padding-right: 10px;
				}

				strong {
					color: $accent-300;
					font-weight: 600;
					letter-spacing: 1px;
				}

				&.hide {
					display: none;
				}
			}
		}

		.site-mobile {
			box-shadow: 0px 2px 5px -2px #c3c3c3;
		    overflow: hidden;
		    height: 13px;
			// z-index: 2; // disable for safari

		    @media screen and(max-width: 1200px) {
		    	box-shadow: initial;
		    }
			ul {
				display: block;
				padding: 0;
				margin: 0;
				position: absolute;
				right: 0;
				top: 0;
				
			}

			li {
				list-style: none;
				display: inline-block;
				vertical-align: middle;

				&.search {
					margin: 0 20px;
				}
				
			}

			a {
				text-decoration: none;

				&.search-mobile {
					position: relative;
					margin-right: 10px;
					z-index: 99;
					transition: all .3s ease-in-out;
					

					.icomoon.icomoon-search {
						color: $accent-300;
						font-size: 24px;
						height: 24px;
						line-height: 24px;
						display: inline-block;
					}

					&.hide {
						opacity: 0;
						visibility: hidden;
					}

					img {
						width: 20px;
					    position: relative;
					    top: 3px;
					}
				}

				&.menu-mobile {
					position: relative;
					display: block;
				    background: $tertiary-300;
				    width: 73px;
				    height: 60px;
				    padding: 10px 20px;
				    text-align: center;
				    z-index: 999;
				    transition: all .3s ease-in-out;

				    &.hide {
				    	opacity: 0;
				    	visibility: hidden;
				    }

					span {
						display: block;
						font-size: 0.750em;
						text-transform: uppercase;
						color: $accent-300;
						margin-top: 5px;
						position: absolute;
					    bottom: 4px;
					    left: 0;
					    right: 0;
					    margin: auto;
					}
				}
				&.search {
					margin-right: 20px;
				}
			}
		}
		.icon-wrap {
			width: 33px;
		  	height: 2px;
			&:after,
			&:before {
				width: 33px;
		  		height: 2px;
			}
		}
		.icon-wrap {
			
			position: relative;
			top: -10px;
			transform: translateY(25px);
			background: $accent-300;
			transition: all 0ms 300ms;

			

		  	&.animate {
		  		background: rgba(255, 255, 255, 0);

		  		&:after {
		  			top: 0;
		  			transform: rotate(45deg);
		  			transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
		  		}
		  		&:before {
		  			bottom: 0;
		  			transform: rotate(-45deg);
		  			transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
		  		}
		  	}

		  	&:before {
		  		content: "";
				position: absolute;
				left: 0;
				bottom: 8px;
				background: $accent-300;
				transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		  	}

		  	&:after {
		  		content: "";
				position: absolute;
				left: 0;
				top: 8px;
				background: $accent-300;
				transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
		  	}

		}

		.search {
			position: relative;
	    	vertical-align: middle;
		    margin-right: 20px;
		    z-index: 1;
			.icomoon.icomoon-search {
				color: $accent-300;
				font-size: 24px;
				height: 24px;
				line-height: 24px;
				display: inline-block;
			}

		    @media screen and(max-width: 1024px) {
		    	margin-right: 10px;
		    }

		    @include breakpoint(desktop) {
		    	margin-right: 0;
		    }

	    }

		.search-block {
			position: fixed;
		    left: 0;
		    top: 18px;
			height: calc(89px - 18px);
		    overflow: hidden;
		    left: 0;
		    // z-index: -1;

		   &.search-block-mobile {
				top: 0;
				left: 0;
				height: 60px;
				z-index: 1;
				.search-wrap {
					height: 100%;
				}
				@include breakpoint(desktop) {
					display: none;
				}
		   }

		   @include breakpoint(desktop-down) {
			   left: 0;
			   right: 0;
			   top: 0;
			   width: 100%;
		   }

		    

		    .search-wrap {
		    	position: relative;
		    	background: $tertiary-300;
		    	height: 50px;
    			margin: 0;
    			width: 0%;
    			overflow: hidden;
    			transition: all .3s ease-in-out;
    			transform: translateX(-105%);
    			z-index: 999;

    			@media screen and(max-width: 1024px) {
    				margin: 0;
    				height: 60px;
    			}

    			&.show {
    				width: 100%;
				    transform: translateX(0);
				   
    			}
			    
		    }

		    form {
		    	position: absolute;
    			top: 50%;
				width: 100%;
				transform: translateY(-50%);

    			// @include breakpoint(tablet) {
    			// 	top: 15px;
    			// }


    			
		    }

		    &.show-z {
		    	z-index: 3;
		    }

		    .form-search {
				width: 95%;
				margin: 0 10px;
				padding: 5px 0;
				outline: none;
				font-size: 1em;
				font-style: italic;
				color: $accent-300;

				&::-webkit-input-placeholder { 
					color: $accent-300;
					font-size: 1em;

					@include breakpoint(tablet) {
						font-size: 0.9em;
					}
				}
				&::-moz-placeholder { 
					color: $accent-300;
					font-size: 1em;

					@include breakpoint(tablet) {
						font-size: 0.9em;
					}
				}
				&::-ms-input-placeholder { 
					color: $accent-300;
					font-size: 1em;

					@include breakpoint(tablet) {
						font-size: 0.9em;
					}
				}
				&::-moz-placeholder { 
					color: $accent-300;
					font-size: 1em;

					@include breakpoint(tablet) {
						font-size: 0.9em;
					}
				}
			}
			.submit {
				position: absolute;
				right: 0;
				top: 0;
				opacity: 0;
				visibility: hidden;
			}
			.close {
				position: absolute;
			    right: 15px;
			    top: 0;
			    cursor: pointer;
			    opacity: 1;
			    outline: none;
				.icomoon.icomoon-close {
					color: $accent-300;
				}
			}
		}
	}

	.navbar-wrapper {
		display: flex;
		height: 100%;
		gap: 1rem;
		align-items: center;
		.site-brand,
		.site-account-wrapper,
		.site-mobile {
			flex-shrink: 0;
		}

		.navigation-wrapper {
			margin: 0 auto;
		}

	}
}