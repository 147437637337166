

/* ==== Begin Thumbnails default */

.thumbnails-default {
	.post-thumbnails-default {
		// margin: 0 -12px;
		font-size: 0;

		@include breakpoint(mobile) {
			margin: 0;
		}
	}

	&.thumbnails-default-market {
		.post-thumbnails {
			&.small {
				width: 100%;
				margin-left: 0;

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
	.post-thumbnails {
		position: relative;
		overflow: hidden;
		background-size: cover;
		background-position: top center;
		background-repeat: no-repeat;
		border-radius: 5px;
		margin-bottom: 25px;
		transition: all .3s ease-in-out;

		&:before {
			position: absolute;
			content: "";
    		left: -70%;
    		top: 35%;
			height: 800px;
			width: 200px;
			background: rgba(255,255,255,0.15);
			background: -moz-linear-gradient(45deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%);
			background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(255,255,255,0.15)), color-stop(100%, rgba(255,255,255,0)));
			background: -webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%);
			background: -o-linear-gradient(45deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%);
			background: -ms-linear-gradient(45deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%);
			background: linear-gradient(45deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
			transform: rotate(-30deg);
			z-index: 9;
			transition: all .6s ease-in-out;

			@include breakpoint(tablet) {
				background: none;
			}
		}

		

		@include breakpoint(tablet) {
			margin-bottom: 15px;
		}


		&:hover {
			opacity: .8;

			&:before {
				top: -135%;
			    left: 135%;
			}
		}

		&:after {
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba(181,181,181,0);
			background: -moz-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(0,0,0,0.7) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(181,181,181,0)), color-stop(33%, rgba(181,181,181,0.23)), color-stop(100%, rgba(0,0,0,0.7)));
			background: -webkit-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(0,0,0,0.7) 100%);
			background: -o-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(0,0,0,0.7) 100%);
			background: -ms-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(0,0,0,0.7) 100%);
			background: linear-gradient(to bottom, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(0,0,0,0.7) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5b5b5', endColorstr='#000000', GradientType=0 );
			z-index: 0;
		}

		// &.big {
		// 	height: 425px;
		//     width: calc(50% - 15px);
		//     float: left;
		//     margin-right: 12px;

		//     &:before {
		//     	width: 400px;

		//     }

		    

		// 	@include breakpoint(tablet) {
		// 		height: 250px;
		// 		width: 100%;
		// 	}
		// }
		// &.small {
		// 	height: 200px;
		//     width: calc(25% - 25px);
		//     float: left;
		//     margin: 0 12px 25px 12px;

		//     &:before {
		//     	width: 200px;
		//     }

		//     @include breakpoint(mobile) {
	 //    		// margin-left: 0;
	 //    		margin: 8px 0;
	 //    	}

		//     &:nth-child(4n+5) {
		//     	//margin-left: 0;
		//     }

		//     &:first-child {
		//     	// margin-left: 25px;

		//     	@include breakpoint(mobile) {
		//     		margin-left: 0;
		//     	}
		//     }

		//     &:last-child {
		//     	@include breakpoint(mobile) {
		//     		margin-bottom: 0;
		//     	}
		//     }

		// 	@include breakpoint(tablet) {
		// 		height: 150px;
		// 		width: 100%;
		// 	}

		// }

		.category-wrap {
			h3 {
				font-family: 'jenius-sans';
				font-size: 0.875em;
			}
		}

		&.big {
			height: 425px;
		    width: calc(50% - 15px);
		    float: left;

			@media screen and(max-width: 1030px) {
				height: 250px;
				width: 100%;
			}

            @include breakpoint(mobile) {
                height: 270px;
            }
		}
		&.small {
			height: 200px;
		    width: calc(25% - 20px);
		    float: left;
		    margin-left: 25px;

		    &:nth-child(4n+1) {
		    	margin-left: 0;
		    }

		    @media screen and(max-width: 1030px) {
	    		margin-left: 0;
	    	}

		    @media screen and(max-width: 1030px) {
		    	margin-left: 0;
		    }

		    &:first-child {
		    	margin-left: 25px;

		    	@media screen and(max-width: 1030px) {
		    		margin-left: 0;
		    	}
		    }

		    &:last-child {
		    	@include breakpoint(mobile) {
		    		// margin-bottom: 0;
		    	}
		    }

		    @media screen and(max-width: 1030px) {
		    	height: 220px;
		    	width: 100%;
		    }

			@include breakpoint(mobile) {
				// height: 150px;
                height: 270px;
			}

		}

	}

	.category-wrap {
		position: absolute;
		left: 20px;
		top: 15px;

		h3 {
			text-shadow: 1px 1px 1px rgba(150, 150, 150, 0.69);

			@include breakpoint(mobile) {
				font-size: 0.9em;
			}
		}
	}
	.heading-Alt {
		position: absolute;
	    left: 20px;
	    right: 20px;
	    bottom: 15px;
	    z-index: 9;
	    font-size: initial;
		h3, h4, p {
			color: $white;
		}
		h3 {
			@include font-jenius-serif;
		}
		h4, p {
			// text-transform: uppercase;
			font-family: 'jenius-sans';
			margin: 5px 0;
			font-weight: 600;	
		}
		h4 {
			font-weight: normal !important;
			display: inline-block;
			height: max-content;
			width: max-content;
			padding: 0.25rem 1rem;
			color: $accent-300 !important;
			background: $tertiary-300;
			border-radius: 999px;
		}

		&.small-title h4 {
			font-weight: normal !important;
			padding: 0.15rem 0.5rem;
		}

		span {
			text-transform: capitalize;
		    font-family: 'jenius-sans';
		    font-weight: 300;
		}

		&.lowercase {
			h4 {
				text-transform: capitalize;
			}
		}

		&.relative {
			position: relative;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	.body-text-1 {
			
		p {
			width: 55%;
    		@include breakpoint(tablet) {
    			width: 100%;
    		}
		}
	}

	&.no-shadow {
		.post-thumbnails {
			background: $white;
			border: 1px solid $accent-300;
			img {
				margin: auto;
				position: absolute;
			    left: 0;
			    right: 0;
			    top: 50%;
			    transform: translateY(-50%);
			    // width: 90%;
			    padding: 20px;

			    @include breakpoint(mobile) {
			    	padding: 5px;
			    }
			}
			&:after {
				position: relative;
				
			}

			&:hover {
				opacity: 1;
			}

			&.big {
				@include breakpoint(mobile) {
					height: 150px;
					width: 49%;
				}

				img {
					@include breakpoint(mobile) {
						width: 100px;
					}
				}
			}
			&.small {
				@include breakpoint(mobile) {
					height: 67px;
					width: 20%;
    				margin-left: 15px;
				}

				// img {
				// 	@include breakpoint(mobile) {
				// 		width: 45px;
				// 	}
				// }
			}

			&:last-child {
				@include breakpoint(tablet) {
					margin-bottom: 15px;
				}
			}
		}
		.content-area {
			.col-xs-6 {
				&.big-thumbnails {}
				&.small-thumbnails {
					>.row {
						.col-xs-6 {
							&:nth-child(1n) {

								@include breakpoint(tablet) {
									padding-left: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.sub-text-area {
		margin-bottom: 20px;
		.subheading {
			h3 {
				font-weight: 600;
				span {
					font-weight: lighter;
				}
			}
		}
	}
	.content-area {
		.col-md-6 {
			&.big-thumbnails {

			}

			&.small-thumbnails {

				>.row {
					.col-md-6 {
						&:nth-child(2n) {

							@include breakpoint(tablet) {
								padding-right: 15px;
							}
						}
					}
				}
			}
		}
	}
}

/* ==== End Thumbnails default */


/* ==== Begin Thumbnails person =====*/

.thumbnails-person {
	.content-area {
		margin-bottom: 30px;
		.col-md-6 {
			&.big-thumbnails {
				padding-left: 0;
			}
		}
	}

	.btn-area {
		text-align: left;

		@include breakpoint(mobile) {
			text-align: center;
		}
	}

	&.thumbnails-person-white {
		ul {
			li {
				background-size: cover;
			    background-position: center top;
				&:before {
					position: absolute;
					content: "";
					left: 0;
					right: 0;
					bottom: 0;
					height: 100%;
					background: url(../img/bg-white.png);
					background-size: cover;
					background-position: center;
				}
			}

			&.desc-wrap {
				li {
					&:before {
						background: none;
					}

					h3 {
						@include breakpoint(mobile) {
							height: auto !important;
						}
					}
				}
			}
		}
	}

	&.thumbnails-person-gray {
		ul {
			li {
				background-size: cover;
			    background-position: center top;
				&:before {
					position: absolute;
					content: "";
					left: 0;
					right: 0;
					bottom: 0;
					height: 100%;
					background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.9));
				}
			}

			&.desc-wrap {

				@include breakpoint(mobile) {
					bottom: 15px
				}
				
				li {
					&:before {
						background: none;
					}
				}
			}
		}
	}

	&.thumbnails-person-article {
		ul {
			// text-align: left;
			li {
				height: auto !important;
				width: 100%;
			    padding: 0;
			    margin: 0;
				text-align: left;

				>img {
					width: initial;
				}
			}

			&.desc-wrap {
				text-align: left;
				position: relative;
				margin: 20px 0;

				li {
					display: inline-block;
					&:first-child {
						background: transparent !important;
						h3, p {
							color: $accent-300 !important;
						}
					}
				}

				&.center {
					text-align: center;
				}

				.btn-area {
					text-align: center !important;
					.fa-chevron-right {
						color: $tertiary-300;
						vertical-align: middle;
					}
				}
			}
		}

		&.thumbnails-person-detail {
			ul {
				li {
					display: block;
				}
			}
		}
	}

	&.thumbnails-person-question {
		li {
			text-align: center;
			float: left;
			position: relative;
			width: 345px;
			height: 300px;
			border-bottom: none;


			@include breakpoint(mobile) {
				position: relative;
			    width: 115px;
			    height: 295px;
			    float: left;
			}
			ul {
				@include breakpoint(mobile) {
					// bottom: 60px;
				}
				

				&.desc-wrap {
					li {
						overflow: initial;
						float: none;
						min-height: auto;

						@include breakpoint(mobile) {
							//height: 145px;
						    bottom: 0;

						}
					}
				}
			}

			li {
				height: auto;
				@include breakpoint(mobile) {
					bottom: 0;
				    left: 0;
				    right: 0;
				    margin: 0;
				    bottom: 15px;
				    height: auto;
				}
			}

			.btn-area {
				position: absolute;
			    left: 0;
			    right: 0;
			    bottom: 0;

			    @include breakpoint(mobile) {
			    	position: relative;
			    	margin: 0;
			    }
			}

			.btn-primary {
				font-size: 0.9em;
				width: 100%;
			}

			&:nth-child(2n) {
				@include breakpoint(mobile) {
					float: right;
				}
			}
		}

		> ul {
			display: flex;
			flex-wrap: wrap;
			> li {
				width: calc((100% / 3) - 20px);
				margin: 10px;

				@include breakpoint(wide-down) {
					width: calc((100% / 2) - 20px);
				}

				@include breakpoint(small-mobile) {
					width: 100%;
				}
			}
		}
	}

	ul {
		padding: 0;
		margin: 0;
		text-align: center;

		&.desc-wrap {
			// position: absolute;
			// bottom: 0;
			// left: 50%;
			position: relative;
			width: 100%;
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			z-index: 1;
			// transform: translateX(-50%);

			@include breakpoint(mobile) {
				position: relative;
				margin-top: 0;
			}

			li {
				height: auto;
				width: auto;
				display: flex;
				overflow: initial;
				margin: 0;

				&:first-child {
					background: $accent-300;
					border-bottom-left-radius: 999px;
					border-top-right-radius: 999px;
					border-bottom-right-radius: 999px;
					padding: 0.5rem 1rem;
					margin-bottom: 4px;
					width: 100%;
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					@include breakpoint(tablet-down) {
						padding-left: 0.5rem;
						padding-right: 0.5rem;
						border-bottom-left-radius: 1.5rem;
						border-top-right-radius: 1.5rem;
						border-bottom-right-radius: 1.5rem;
						
					}
				}

				&:before {
					background: none;
				}
				h3 {
					@include font-jenius-serif;
					color: $tertiary-300;
					font-size: 1.5em;
					font-weight: 800;

					@include breakpoint(mobile) {
						font-size: 1em;
						height: auto;
						margin-bottom: 4px;
					}
				}
				p {
					color: $tertiary-300;
					font-family: 'jenius-sans';
					font-size: 1em;
					margin: 0;
					// margin: 10px 0;

					// @include breakpoint(mobile) {
					// 	margin: 0 0 10px;
					// }
				}

			}
		}
	}

	> ul {
		padding: 0;
		margin: 0;
		display: flex;
		text-align: center;
		flex-wrap: wrap;
		justify-content: center;
		> li, .slick-track > li {
			position: relative;
			vertical-align: middle;
			position: relative;
			list-style: none;
			width: 220px;
			min-height: 290px;
			align-self: stretch;
			text-align: center;
			margin: 10px;
			outline: none;
			overflow: hidden;
			background-size: cover;
			background-position: center top;
			background-repeat: no-repeat;
			display: inline-flex;
			flex-direction: column;
			img {
				margin: auto;
				aspect-ratio: 1;
				object-fit: contain;
				object-position: center bottom;
				@include breakpoint(mobile) {
					aspect-ratio: 3/4;
				}
			}


		}
	}

	.slick-next {
		height: 40px;
		width: 22px;
		background: url(../img/icon-sprite-slider.png) no-repeat;
		background-size: 45px;
		background-position: -22px 0;
		right: 0;
		z-index: 9;
		&:before {
			content: "";
			opacity: 1;
		}
	}
	.slick-prev {
		height: 40px;
		width: 22px;
		background: url(../img/icon-sprite-slider.png) no-repeat;
		background-size: 45px;
		background-position: 0 0;
		left: 0;
		z-index: 9;
		&:before {
			content: "";
			opacity: 1;
		}
	}
}

/* ==== End Thumbnails person =====*/

/* ==== Begin thumbnails article ==== */

.thumbnails-article {
	margin: 30px 0 0;
	article {
		&.post-thumbnails {
			margin-bottom: 30px;
			.img-wrap {
				height: 127px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
				border-radius: 5px;
				transition: all .3s ease-in-out;

				&:hover {
					opacity: .8;
				}
			}
			.text-wrap {
				h3 {
					font-size: 1.313em;
					line-height: 1.5;

					@include breakpoint(mobile) {
						font-size: 1em;
					}


					a {
						text-decoration: none;
						@include font-jenius-serif;
						color: $neutral-300;

						&:hover {
							text-decoration: underline;
							opacity: 0.9;
						}
					}
				}
				h4, p {
					font-family: 'jenius-sans';
					font-size: 1em;
					font-weight: 600;
					text-transform: capitalize;
					line-height: 1.5;

					@include breakpoint(tablet) {
						font-size: 0.8em;
    					line-height: 1.5;
					}

					span {
						color: $accent-300;
						text-transform: capitalize;
						font-weight: lighter;
					}
				}

				&.gray {
					h4 {
						color: $neutral-300;
					}
				}

				&.orange {
					p {
						color: $accent-300;
					}
				}

				&.small {
					h3 {
						font-size: 0.9em;

						a {
							color: $neutral-400;
						}
					}
					p {
						font-size: 0.9em;
						text-transform: uppercase;

						span {
							text-transform: capitalize;
						}
					}
				}
			}

			&.border {
				padding-bottom: 10px;
    			margin-bottom: 10px;
				border-bottom: 1px solid $neutral-75;

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}
	
}

/* ==== End thumbnails article ===== */


/* ==== Begin thumbnails training ==== */

.thumbnails-training {
	&-1 {
		article {
			&.post-thumbnails {
				position: relative;
				height: 435px;
				overflow: hidden;
				border: 1px solid $neutral-75;
				border-radius: 5px;
				margin-bottom: 30px;

				@media screen and(max-width: 1030px) {
					height: 300px;
				}

				@include breakpoint(mobile) {
					// height: 190px;
                    height: auto;
				}

				.img-wrap {
					height: 270px;
					background-size: cover;
					background-position: top center;
					background-repeat: no-repeat;
					transition: all .3s ease-in-out;

					@media screen and(max-width: 1030px) {
						height: 300px;
					}

					@include breakpoint(mobile) {
						// height: 190px;
                        height: auto;
                        padding-bottom: 100%;
					}
				}

				.bottom-area .icomoon {
					font-size: 24px;
					color: $accent-300;
					vertical-align: middle;
				}

				ul {
					padding: 10px;
					display: inline-block;
    				width: 100%;
					>li {
						border-bottom: 0;
						padding: 0;

						&:first-child {
							border-top: 0;
						}

						h4 {
							font-family: 'jenius-sans';
						}

						h3 {
							@include font-jenius-serif;
							line-height: 1.5;

						}

						a {
							color: $accent-300;
							text-decoration: none;
						}

						span {
							&.author {
								@include breakpoint(mobile) {
									position: absolute;
		    						bottom: 55px;
								}
							}
						}
					}
				}

				&:hover {
					.img-wrap {
						opacity: .8;
					}
				}

				.row {
					.col-md-12 {
						&:last-child {
							height: 140px;

							@media screen and(max-width: 1030px) {
								height: 275px;
							}

							@include breakpoint(mobile) {
								// height: 190px;
                                height: auto;
                                position: unset;
							}
						}
					}
				}
			}

			.bottom-area {
				height: 45px;
				position: absolute;
				left: 0;
	    		right: 0;
				bottom: -20px;
				border-top: 1px solid $neutral-100;

				@include breakpoint(mobile) {
                    left: auto;
                    right: 0;
					bottom: 0;
                    width: 58.33333%;
				}

				ul {
					padding: 10px;

					
				}

				li {
					display: inline-block;

					&:first-child {
						float: left;
					}

					&:last-child {
						float: right;
					}

					
				}

				span {
					color: $neutral-100;
					font-size: 0.750em;
				}
			}
		}
	}	
	&-2 {
		article {
			&.post-thumbnails {
				position: relative;
				height: 310px;
				border-radius: 5px;
				overflow: hidden;
				border: 1px solid $neutral-75;
				margin-bottom: 30px;

				@include breakpoint(mobile) {
					height: 505px;
				}
				.col-md-7 {
					height: 310px;

					@include breakpoint(mobile) {
						height: 400px;
					}
				}
				.img-wrap {
					height: 310px;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;

					@include breakpoint(mobile) {
						height: 130px;
					}
				}

				.btn-primary {
					@include breakpoint(mobile) {
						width: 100%;
						padding: 8px 10px;
						font-size: 0.9em;
					}
				}

				.block-text {
					padding: 15px;
				}

				.middle-text-wrap {
					padding: 15px 0;
					border-top: 1px solid $secondary-300;
					border-bottom: 1px solid $secondary-300;
				}

				.time-wrap {
					overflow: hidden;
					padding-bottom: 10px;
					ul {
						padding: 0;
						margin: 0;
						display: block;

						
					}

					li {
						display: inline-block;
						font-family: 'jenius-sans';
						color: $neutral-300;
						font-size: 1.125em;

						&.date {
							float: left;
							font-weight: 600;
						}

						&.time {
							float: right;
						}
					}
				}

				.title-area {
					.subheading-6 {
						h3 {
							margin-top: 0;
						}
					}
					.body-text-3 {
						p {
							width: 80%;

							@include breakpoint(mobile) {
								font-size: 0.9em;
							    color: $neutral-75;
							    margin-top: 10px;
							}
						}
					}
				}

				.avatar-area {
					display: inline-block;
					margin-top: 25px;
					
				}

				.avatar {
					position: relative;
					float: left;
					height: 60px;
					width: 60px;
					overflow: hidden;
					border-radius: 100%;
					background: $neutral-50;

					img {
						position: absolute;
					    top: 50%;
					    left: 50%;
					    bottom: initial;
					    right: initial;
					    max-width: 150%;
					    max-height: 150%;
					    min-width: 100%;
					    min-height: 100%;
					    transform: translate(-50%, -25%);
					}
				}

				.author-wrap {
					display: inline-block;
					position: relative;
				    left: 10px;
				    top: 10px;

				    .subheading-6 {
				    	h3 {
				    		margin-top: 0;
				    		line-height: 1.3
				    	}
				    }
				}

				.address-wrap {
					position: absolute;
					bottom: 10px;
				    right: 10px;
				    left: 15px;

				    @include breakpoint(mobile) {
				    	position: relative;
				    	bottom: 0;
				    	right: 0;
				    	left: 0;
				    	margin-top: 20px;
				    }
				}

				.caption {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					opacity: 0;
					visibility: hidden;
					transition: all .3s ease-in-out;

					@include breakpoint(mobile) {
						display: none;
					}
					
					&:before {
						position: absolute;
						content: "";
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						background: $white;
						opacity: .8;
					}

					.text-area {
						margin-top: 0;
					    margin-bottom: 0;
					    position: absolute;
					    left: 0;
					    right: 0;
					    top: -50%;
					    transform: translateY(-50%);
	    				margin: auto;
	    				transition: all .3s ease-in-out;


					}

					.title-area {
						.body-text-3 {
	    					p {
	    						width: 100%;
	    					}
	    				}
					}

					.avatar-area {
						.subheading-6 {
							h3 {
								text-align: left;
							}
						}
					}

					.block-text {
						position: relative;
						height: 310px;

						.middle-text-wrap {
							text-align: center;
							border: none;
							position: absolute;
						    left: 0;
						    right: 0;
						    margin: auto;
						    top: -50%;
						    width: 70%;
						    transform: translateY(-50%);
						    transition: all .3s ease-in-out;
						}
					}
				}

				&:hover {
					border-color: $white;
				}

				&:hover .caption {
					visibility: visible;
					opacity: 1;
				}

				&:hover .caption .block-text .middle-text-wrap {
					top: 50%;
				}
			}
		}
	}

	&-3 {
		article {
			&.post-thumbnails {
				height: 520px;
				position: relative;
				overflow: hidden;
				border: 1px solid $neutral-75;
				border-radius: 5px;
				margin-bottom: 30px;

				@include breakpoint(mobile) {
					min-height: 440px;
					box-shadow: 0px 3px 21px 0px rgba(199,199,199,1);
				}

				.img-wrap {
					height: 195px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;

					@include breakpoint(mobile) {
						position: relative;
						height: 150px;
					}
				}

				.subheading-6 {
					h3 {
						font-size: 12px;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;
						color: $neutral-300;
					}
					span {
						display: block;
						font-size: 12px;
						font-weight: 300;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;
						color: $neutral-300;
					}
				}

				.avatar-wrap {
					position: relative;
					background: $white;
					height: 34px;
					width: 34px;
					overflow: hidden;
					border-radius: 100%;

					@include breakpoint(mobile) {
						height: 34px;
						width: 34px;
					}

					img {
						max-width: 100%;
					}
				}

				.block-text {
					position: relative;
					padding: 15px;
					.new-heading {
						h3 {
							@include font-jenius-serif;
							font-size: 22px;
							font-weight: bold;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.18;
							letter-spacing: normal;
							text-align: left;
							color: $accent-300;
							margin-bottom: 14px;
							text-overflow: ellipsis;
							overflow: hidden;
							overflow-wrap: break-word;
							height: 50px;
						}
					}

					.subheading-hover {
						margin-left: 6px;
						margin-top: 6px;
						h3 {
							font-family: 'jenius-sans';
							margin: 0px;
							font-size: 12px;
							font-weight: 700;
							font-stretch: normal;
							font-style: normal;
							letter-spacing: normal;
							text-align: left;
							color: $neutral-300;
							overflow: hidden;
							height: 12px;
							text-overflow: ellipsis;
						}
						span {
							font-family: 'jenius-sans';
							font-size: 12px;
							font-weight: 300;
							font-stretch: normal;
							font-style: normal;
							line-height: 2px;
							letter-spacing: normal;
							text-align: left;
							color: $neutral-300;
							overflow: hidden;
							height: 10px;
							text-overflow: ellipsis;
						}
					}
					ul {
						display: flex !important;
						margin-top: 17px !important;
						li {
							display: flex;
							margin-right: 33px;
							&:last-child {
								margin: 0px;
							}
							span {
								display: block;
								font-family: 'jenius-sans';
								font-size: 14px;
								font-weight: bold;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.71;
								letter-spacing: normal;
								text-align: left;
								color: $neutral-300;
							}
							img {
								margin-right: 6px;
							}
						}
					}
					.address-wrap {
						display: flex;
						img {
							margin-right: 6px;
						}
						.text-wrap {
							position: relative;
							top: 19px;
							width: 100%;
							padding-right: 15px;
							span {
								font-family: 'jenius-sans';
								font-size: 14px !important;
								font-weight: bold;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.71;
								letter-spacing: normal;
								text-align: left;
								color: $neutral-300 !important;
							}
							p {
								font-family: 'jenius-sans';
								font-size: 14px;
								font-weight: bold;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.71;
								letter-spacing: normal;
								text-align: left;
								color: $neutral-300;
								overflow: hidden;
								height: 50px;
								text-overflow: ellipsis;
							}
						}
					}
					.btn-area {
						margin-top: 20px;
						margin-bottom: 0px;
						.btn-primary {
							text-align: center;
							width: 100%;
							height: 45px;
							padding: 8px 25px;
							text-transform: uppercase;
							font-weight: 600;
							font-size: 18px;
							font-weight: bold;
							font-stretch: normal;
							font-style: normal;
							line-height: 30px;
							letter-spacing: normal;
							color: $white;
						}
					}

					.author-wrap {
						display: flex;
						padding-bottom: 18px;
						border-bottom: solid 1px $neutral-75;
						.body-text-1 {
							margin-top: 0;
						}
					}
				}

				.nav-wrap {
					.nav {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						z-index: 9;

						img {
							max-width: 20px;
						}

						&.nav-prev {
							left: 0;
							display: none;
						}
						&.nav-next {
							right: 0;
						}
					}
				}

				.btn-area {
					@include breakpoint(mobile) {
						position: static;
					    left: 10px;
					    right: 10px;
					    bottom: 0;
					}

				    .btn-primary {
				    	@include breakpoint(mobile) {
				    		font-size: 0.9em;
				    	}
				    }
				}

			}
		}
	}
}

/* ==== End thumbnails training ==== */

/* ==== Begin thumbnails business ==== */

.thumbnails-business {
	article {
		&.post-thumbnails {
			position: relative;
			height: 280px;
			background: $white;
			border: 1px solid $neutral-75;
			border-radius: 5px;
			margin-bottom: 30px;
			transition: all .3s ease-in-out;

			@include breakpoint(mobile) {
				height: 140px;
				margin-bottom: 10px;
			}

			&:hover {
				opacity: .5;
			}

			img {
				position: absolute;
				margin: auto;
				left: 0;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 200px;

				@include breakpoint(mobile) {
					width: 100px;
				}
			}
		}
	}

	.row-5 {
		margin-left: -15px;
    	margin-right: -15px;

    	@include breakpoint(mobile) {
    		margin-left: -5px;
    		margin-right: -5px;
    	}

		>.col-md-3 {
			padding-left: 15px;
			padding-right: 15px;
		}
		>.col-xs-6 {
			@include breakpoint(tablet) {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}
}

/* ==== End thumbnails business ==== */


/* ==== Begin thumbnails market ==== */

.thumbnails-market {
	article {
		&.post-thumbnails {
			overflow: hidden;
			position: relative;
			border: 1px solid $neutral-50;
			border-radius: 5px;
			margin-bottom: 25px;
			transition: all .3s ease-in-out;		

			&.big {
				height: 295px;

				@include breakpoint(mobile) {
					height: 190px;
				}

				>.row {
					height: 295px;

					@include breakpoint(mobile) {
						height: 190px;
					}

					>.col-md-6 {
						height: 295px;

						@include breakpoint(mobile) {
							height: 190px;
						}

					}					
				}

				.img-wrap {
					text-align: center;
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}

				.text-area {
					margin-top: 20px;
					margin-left: 20px;
					width: 89%;

					@include breakpoint(tablet) {
						width: 95%;
						margin-left: 10px;
					}
				}

				&:hover .text-area {
					top: 50%;
				}
				.subheading-6 {
					h3 {
						
						@include breakpoint(wide) {
							display: -webkit-box;
							-webkit-line-clamp: 2;
							line-clamp: 2;
							-webkit-box-orient: vertical;
							// height: 63px;
							overflow: hidden;
							-o-text-overflow: ellipsis;
							text-overflow: ellipsis;
						}
						@include breakpoint(mobile) {
							display: -webkit-box;
							-webkit-line-clamp: 2;
							line-clamp: 2;
							-webkit-box-orient: vertical;
							margin: 0px;
							// height: 49px;
							overflow: hidden;
							-o-text-overflow: ellipsis;
							text-overflow: ellipsis;
						}
					}
				}

			}

			&.small {
				height: 347px;

				@media screen and(max-width: 1030px) {
					height: 200px;
				}

				@include breakpoint(mobile) {
					height: 165px;
				}

				.img-wrap {
					height: 195px;
					overflow: hidden;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;

					// img {
					// 	min-width: 100%;
					//     min-height: 100%;
					//     max-width: 130%;
					//     position: relative;
					//     left: 50%;
					//     transform: translateX(-50%);
					// }sidebar-profile
				}

				.text-area {
					margin: 15px;
					width: 89%;
				}

				.subheading-6 {
					h3 {
						@include breakpoint(wide) {
							display: -webkit-box;
							-webkit-line-clamp: 2;
							line-clamp: 2;
							-webkit-box-orient: vertical;
							// font-size: 1.2rem;
							// height: 35px;
							overflow: hidden;
							text-overflow: ellipsis;
						}
						@include breakpoint(mobile) {
							display: -webkit-box;
							-webkit-line-clamp: 2;
							line-clamp: 2;
							-webkit-box-orient: vertical;
							// font-size: 1rem;
							margin: 0px;
							// height: 49px;
							overflow: hidden;
							-o-text-overflow: ellipsis;
							text-overflow: ellipsis;
						}
					}
				}

				&:hover .text-area {
					top: 50%;
				}

				.btn-area {
					margin: 16px 0 5px;
					@include breakpoint(mobile) {
						margin: 16px 0 5px;

					}
				}
			}

			

			.btn-area {
				margin: 5px 0;
				.btn-primary {
					padding: 8px 20px;
					font-size: 1em;
					width: 100%;
					text-align: center;
				}
			}

			.body-text-4 {
				p {
					@include breakpoint(mobile) {
						line-height: 1.5;
					}
				}
			}

			// .caption {
			// 	text-align: center;
			// 	position: absolute;
			// 	top: 0;
			// 	left: 0;
			// 	right: 0;
			// 	bottom: 0;
			// 	opacity: 0;
			// 	visibility: hidden;
			// 	transition: all .3s ease-in-out;

			// 	@include breakpoint(mobile) {
			// 		display: none;
			// 	}
				
			// 	&:before {
			// 		position: absolute;
			// 		content: "";
			// 		left: 0;
			// 		right: 0;
			// 		top: 0;
			// 		bottom: 0;
			// 		background: $white;
			// 		opacity: .8;
			// 	}

			// 	.text-area {
			// 		margin-top: 0;
			// 	    margin-bottom: 0;
			// 	    position: absolute;
			// 	    left: 0;
			// 	    right: 0;
			// 	    top: -50%;
			// 	    transform: translateY(-50%);
    		// 		margin: auto;
    		// 		transition: all .3s ease-in-out;
			// 	}

			// 	.text-area {
			// 		padding: 0 15px;
			// 		width: 100%;
			// 	}
			// }

			// &:hover {
			// 	border-color: $white;
			// }

			// &:hover .caption {
			// 	visibility: visible;
			// 	opacity: 1;
			// }
		}
	}

	&.more-thumbnails-market{
		padding-top: 50px;

		.col-md-3 {
			&:last-child {
				article {
					&.post-thumbnails {
						@include breakpoint(mobile) {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		@include breakpoint(mobile) {
			padding-top: 20px;
		}
	}
}

/* ==== End thumbnails market ==== */