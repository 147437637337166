@import "bourbon";

/* ABSTRACTS */
@import
    '../Abstracts/variables',
    '../Abstracts/functions',
	'../Abstracts/mixins',
	'../Base/fonts',
	'../Base/fonts-icon'; 

.block-market {
    padding-bottom: 50px;
    .box-product {
    	.left {
    		float: left;

    		@include breakpoint(tablet) {
    			float: none;
    		}
    	}
    	.right {
    		float: right;

    		@include breakpoint(tablet) {
    			float: none;
    		}
    	}
    }
    .product {
    	&.small-product {

            @include breakpoint(tablet) {
                text-align: center;
                margin: 40px 0 20px;
            }

    		a {
                position: relative;
                background: $white;
                height: 80px;
                width: 80px;
                overflow: hidden;
                margin-bottom: 10px;
                display: block;
                border: 1px solid transparent;
                transition: all .3s ease-in-out;

                @include breakpoint(tablet) {
                    margin: 0 3px;
                }

                &.active {
                    width: 85px;
                    border: 1px solid $accent-300 !important;

                    @include breakpoint(tablet) {
                        width: 60px;
                    }
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    transform: translateY(-50%);
                }

                @include breakpoint(tablet) {
                    display: inline-block;
                    height: 60px;
                    width: 60px;
                }

                &:first-child {
                    width: 85px;
                    border: 1px solid $accent-300;

                    @include breakpoint(tablet) {
                        width: 60px;
                    }
                }
            }
    	}

    	&.big-product {
            position: relative;
    		img {
				position: absolute;
                max-height: 100%;
				top: 50%;
				left: 0;
				right: 0;
				transform: translateY(-50%);
				margin: auto;
			}

            .zoomWrapper {
               height: 350px !important;

                @include breakpoint(tablet) {
                    height: 180px !important;
                }
            }
    	}
    }

    .btn-area {
    	@include breakpoint(mobile) {
    		margin-bottom: 0;
    	}

    	.btn-secondary {
    		@include breakpoint(mobile) {
    			width: 90%;
    		}
    	}
    }
    .heading {
    	&.heading-more-article {
    		@include breakpoint(mobile) {
    			width: 80%;
    			margin: auto;
    			text-align: center;
    		}
    	}
    }
    .subheading-4 {
    	@include breakpoint(mobile) {
    		margin: 20px 0;
    		h3 {
    			font-size: 2em;
    		}
    	}
    }
}

.zoomContainer {
    @media screen and(max-width: 1200px) {
        display: none;
    }
}