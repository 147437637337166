.form-group {
    position: relative;

    @include breakpoint(mobile) {
        display: inline-block;
        width: 100%;
    }

    &.form-comment-parent {
        label {
            &.error {
                margin-left: 97px;

                @include breakpoint(mobile) {
                    margin-left: 25px;
                }
            }
        }
    }

    label {
        font-family: "jenius-sans";
        color: $neutral-400;
        font-size: 0.9em;
        font-weight: lighter;

        &.label-field {
            display: block;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;

            &.show {
                overflow: hidden;
                visibility: visible;
                opacity: 1;
            }
        }

        &.error {
            font-family: "jenius-sans";
            opacity: 1;
            visibility: visible;
            display: block;
            font-size: 1em;
            color: #e23013 !important;
            font-weight: 400;
            margin-bottom: 0;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            transition: all 0.3s ease-in-out;

            &.show-error {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    .form-control {
        font-family: "jenius-sans";
        font-size: 1.125em;
        color: $neutral-400;
        outline: none;
        border: 1px solid #d5d5d5;
        box-shadow: none;
        height: 50px;
        padding-left: 65px;
        border-radius: 3px;

        &::-webkit-input-placeholder {
            font-family: "jenius-sans";
            color: rgba(45, 45, 45, 0.4);
            font-size: 0.9em;
            letter-spacing: 0;

            @include breakpoint(mobile) {
                font-size: 0.8em;
            }
        }
        &::-moz-placeholder {
            font-family: "jenius-sans";
            color: rgba(45, 45, 45, 0.4);
            font-size: 0.9em;
            letter-spacing: 0;

            @include breakpoint(mobile) {
                font-size: 0.8em;
            }
        }
        &::-ms-input-placeholder {
            font-family: "jenius-sans";
            color: rgba(45, 45, 45, 0.4);
            font-size: 0.9em;
            letter-spacing: 0;

            @include breakpoint(mobile) {
                font-size: 0.8em;
            }
        }
        &::-moz-placeholder {
            font-family: "jenius-sans";
            color: rgba(45, 45, 45, 0.4);
            font-size: 0.9em;
            letter-spacing: 0;

            @include breakpoint(mobile) {
                font-size: 0.8em;
            }
        }

        &.birthdate {
            letter-spacing: 1px;
        }
    }

    #captcha {
        padding-left: 20px;
        margin-top: 20px;
    }

    .captcha-wrap {
        display: block;
        margin: 10px 0;
        img {
            display: block;
        }

        #refresh-captcha {
            font-family: 'jenius-sans';
            font-size: 1em;
            color: $accent-300;
            text-transform: capitalize !important;
            margin-top: 10px;
            display: inline-block;
            text-decoration: none !important;
        }
    }

    .hideShowPassword-toggle {
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
        height: 20px;
        width: 45px;
        outline: none;

        &:after {
            position: absolute;
            content: "";
            right: 15px;
            height: 20px;
            width: 29px;
            top: 0;
            background: url(../img/icon-sprite-eye.png) no-repeat;
            background-position: 2px center;
            background-size: 50px;
            transition: all 0.3s ease-in-out;
        }

        &.hideShowPassword-toggle-hide {
            &:after {
                width: 29px;
                background: url(../img/icon-sprite-eye.png) no-repeat;
                background-position: -23px center;
                background-size: 50px;
            }
        }
    }

    &.remember {
        margin-top: 30px;
        &.white {
            .check {
                position: relative;
                font-family: 'jenius-sans';
                color: $white;
                left: 30px;
                top: -5px;
                font-size: 1.2em;
                font-weight: lighter;

                @include breakpoint(mobile) {
                    width: 90%;
                    display: block;
                    font-size: 1em;
                    top: 0;
                }

                a {
                    font-family: 'jenius-sans';
                    display: inline-block;
                    color: $white;
                    font-weight: 600;
                }
            }
            .jcf-checkbox {
                position: relative;
                position: absolute;
                left: 0;
            }
        }
    }
    .dropdown {
        position: absolute;
        right: 15px;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        background: url(../img/sprite-arrow-brown.png) no-repeat;
        background-position: 1px center;
        pointer-events: none;
    }
    .input-wrap {
        position: relative;
        label {
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            width: 50px;
            height: 50px;
            border: 1px solid #d5d5d5;
            display: block;
            background: $white;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            z-index: 1;

            img {
                vertical-align: middle;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &#inputOtp {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
        }

        .input-otp {
            width: 50px;
            height: 50px;
            border: 1px solid #d5d5d5;
            text-align: center;
            font-size: 16px;
            cursor: not-allowed;
            pointer-events: none;
        }

        .input-otp:focus {
            // integrasi dengan design system
            border-bottom: 3px solid $tertiary-300;
            outline: none;
        }

        .input-otp:nth-child(1) {
            cursor: pointer;
            pointer-events: all;
        }
    }
    &.radio-group {
        .input-wrap {
            margin: 10px 0 0;
            font-family: "jenius-sans";
            font-size: 1em;
            font-weight: lighter;
            color: $neutral-400;
        }
        .jcf-radio {
            margin-left: 25px;
            &:first-child {
                margin-left: 0;
            }
        }
    }

    &.checkbox-group {
        margin: 30px 0 0;
        overflow: hidden;
        .jcf-checkbox {
            float: left;
        }
        label {
            font-family: "jenius-sans";
            font-weight: lighter;
            width: 85%;
            float: left;
            margin-left: 10px;
            position: relative;
            top: -4px;
            color: $neutral-400;
            line-height: 1.5;
        }
        a {
            font-weight: 600;
            text-decoration: none;
            color: $neutral-400;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: #fbb832;
            }
        }
    }
    &.form-comment {
        // padding-left: 90px;

        @include breakpoint(mobile) {
            padding-left: 65px;
        }

        textarea {
            display: inline-block;
            width: 87%;
            margin-left: 25px;
            height: 120px;
            padding: 15px;

            @media screen and(max-width: 1200px) {
                width: 85%;
            }

            &::-webkit-input-placeholder {
                font-family: "jenius-sans";
                color: #d6d4d4;
                font-weight: lighter;
            }
            &::-moz-placeholder {
                font-family: "jenius-sans";
                color: #d6d4d4;
                font-weight: lighter;
            }
            &::-ms-input-placeholder {
                font-family: "jenius-sans";
                color: #d6d4d4;
                font-weight: lighter;
            }
            &::-moz-placeholder {
                font-family: "jenius-sans";
                color: #d6d4d4;
                font-weight: lighter;
            }
        }

        &.form-comment-parent {
            @include breakpoint(mobile) {
                width: 80%;
                padding-left: 0;

                textarea {
                    width: 92%;
                }
            }
        }
    }

    &.otp-group {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 8px;

        font-size: 14px;
        line-height: 16px;
        // integrasi design-system
        color: #3d4148;

        label,
        & > label {
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            // integrasi design-system
            color: #3d4148;
        }

        label,
        & > label,
        p {
            padding: 0;
            margin: 0;
        }

        .timer {
            font-weight: 600;

            .restart-timer {
                // integrasi design-system
                color: $accent-300;
                border-bottom: 1px solid $tertiary-300;
            }
        }
    }
}

.form-wrap {
    &.form-register-training {
        margin: 30px 0;

        .list {
            border-bottom: 1px solid $neutral-75;
        }

        // .form-group {
        // 	margin-bottom: 25px;
        // }

        .jcf-select {
            padding: 0;
            margin-left: 0;
            border-bottom: 0;

            .jcf-select-text {
                font-weight: 400;
            }

            &:after {
                background: none;
            }
        }
        .jcf-select {
            width: 100%;
            cursor: pointer;
            background: none;
            border: none;
            float: left;
            font-family: "jenius-sans";
            font-size: 0.9em;
            margin-bottom: 0;
            color: $neutral-200;
            text-align: left;
            height: 50px;

            &.jcf-drop-active {
                .jcf-select-text {
                    &:after {
                        background: url(../img/icon-arrow-up.png);
                    }
                }

                &:after {
                    background: none;
                }
            }
        }

        .jcf-select-opener {
            background: none;
        }
        .jcf-select-text {
            background: $white;
            cursor: pointer;
            position: relative;
            margin: 0;
            padding: 11px 22px;
            border: 1px solid #d5d5d5;
            font-family: "jenius-sans";
            color: $neutral-75;
            font-size: 1.2em;
            padding-left: 60px;
            border-radius: 3px;

            span {
                color: $neutral-400;
            }

            &:after {
                position: absolute;
                content: "";
                right: 10px;
                top: 17px;
                height: 15px;
                width: 20px;
                background: url(../img/icon-arrow-down.png);
                background-repeat: no-repeat;
                background-size: 20px;
                background-position: right;
            }
        }

        .jcf-select-drop {
            padding-left: 50px;
        }
    }

    &.form-login-popup {
        margin: auto;
    }
}

.jcf-checkbox {
    background: $white;
    border: 1px solid #d5d5d5 !important;
    border-radius: 3px;
    border: none;
    width: 20px;
    height: 20px;

    &.jcf-checked {
        background: $tertiary-300;
        position: relative;
        &::before {
            display: block;
            font-family: "FontAwesome" !important;
            color: $accent-300;
            content: "\f00c";
            font-size: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
        span {
            display: none;
            // display: block;
            // font-family: 'FontAwesome' !important;
            // color: $accent-300;
            // content: '\f00d';
            // font-size: 12px;
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            // speak: never;
            // font-style: normal;
            // font-weight: normal;
            // font-variant: normal;
            // text-transform: none;
            // line-height: 1;
            // -webkit-font-smoothing: antialiased;
            // -moz-osx-font-smoothing: grayscale;
            // background-image: url(../img/icon-checklist.png);
            // background-position: center;
            // -moz-background-size: 10px;
            // -o-background-size: 10px;
            // background-size: 10px;
            // height: 7px;
            // background-repeat: no-repeat;
            // border: none;
            // border-width: 0;
            // transform: initial;
        }
    }
}

.jcf-radio {
    border-color: #d5d5d5;
    margin-right: 10px;
    &.jcf-checked {
        span {
            background: $tertiary-300;
        }
    }
}
.box-question {
    margin-top: 15px;
    // padding: 20px 30px;
    // box-shadow: 0px 0px 15px -2px rgba(224,224,224,1);

    @include breakpoint(mobile) {
        padding: 20px;
        overflow: hidden;
    }

    .subheading-2 {
        h4 {
            color: #fdc544;
            font-family: 'jenius-sans';
        }
    }
    textarea {
        height: 180px;
        width: 100%;
        border: 1px solid $neutral-75;
        border-radius: 3px;
        outline: none;
        font-family: "jenius-sans";
        color: $neutral-400;
        font-size: 1em;
        padding: 20px;
        margin-top: 10px;
    }

    .btn-secondary {
        @include breakpoint(mobile) {
            width: 40%;
            float: right;
        }
    }
}
#form-child-comment-replay {
    .error {
        visibility: visible;
        opacity: 1;
    }
}

.form-group .form-control {
    @include breakpoint(iphone) {
        font-size: 14px;
    }
}

#formBusiness {
    .label-field {
        opacity: 0;
        visibility: hidden;
    }
}

.form-group .form-custom-jcf {
    padding-left: 60px;
}

.warning-message {
    color: #e23013 !important;
    font-size: 11px;
    text-align: center;

    span {
        display: block;
        margin: auto;
        width: 75%;
    }
}

.d-none {
    display: none !important;
}
