@import "bourbon";

/* ABSTRACTS */
@import "../Abstracts/variables", "../Abstracts/functions",
    "../Abstracts/mixins", "../Base/fonts", "../Base/fonts-icon";

.content-login {
    position: relative;
    margin-top: 80px;

    @media screen and(max-width: 1030px) {
        margin-top: 60px;
    }

    &.forgot-pass {
        height: calc(100vh - 260px);

        .section-form {
            height: 100%;
        }
    }
}

.item-login {
    &.section-img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: initial;
        width: 58%;
        overflow: hidden;

        @include breakpoint(tablet) {
            display: none;
        }

        .img-wrap {
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    &.form-register-training {
        margin-top: 30px;
        .col-md-6 {
            &:nth-child(3n) {
                clear: both;
            }
        }
        .jcf-select {
            width: 100%;
            cursor: pointer;
            background: none;
            border: none;
            float: left;
            font-family: 'jenius-sans';
            font-size: 0.9em;
            margin-bottom: 0;
            color: $neutral-200;
            text-align: left;
            height: 50px;
            padding: 0;

            &:after {
                background: none;
            }

            &.jcf-drop-active {
                &:after {
                    background: none;
                }
            }
            .jcf-select-opener {
                background: none;
                display: none;
            }
            .jcf-select-text {
                background: $white;
                cursor: pointer;
                position: relative;
                margin: 0;
                padding: 11px 22px;
                border: 1px solid $neutral-75;
                font-family: 'jenius-sans';
                color: $neutral-75;
                font-size: 1.2em;
                padding-left: 60px;
                border-radius: 3px;

                span {
                    color: $neutral-400;
                    font-weight: 400;
                }

                &:after {
                    position: absolute;
                    content: "";
                    right: 10px;
                    top: 17px;
                    height: 15px;
                    width: 20px;
                    background: url(../img/sprite-arrow-brown.png);
                    background-repeat: no-repeat;
                    background-size: 35px;
                    background-position: 0 5px;
                }
            }

            .jcf-select-drop {
                padding-left: 50px;
            }

            &.jcf-drop-active {
                .jcf-select-text {
                    &:after {
                        background: url(../img/sprite-arrow-brown.png);
                        background-repeat: no-repeat;
                        background-size: 35px;
                        background-position: -20px 5px;
                    }
                }
            }
        }
        .list {
            height: 1px;
            width: 100%;
            background: $neutral-75;
        }
    }

    .body-text-1 {
        p {
            @include breakpoint(mobile) {
                line-height: 1.2;
            }
        }
    }

    h2 {
        @include breakpoint(mobile) {
            width: 80%;
            margin: 0 auto 10px;
            line-height: 1.3;
        }
    }
}

.section-form {
    > .container {
        position: relative;
        &.for__register {
            padding: 40px 0;

            .form-auth-wrap {
                margin: 40px 15px;
            }

            .col-md-offset-8 {
                @media screen and(max-width: 1030px) {
                    margin-left: 63%;
                }

                @media screen and(max-width: 920px) {
                    margin-left: 0;
                }
            }
        }
        &.for__login {
            padding: 62px 0;

            @media screen and(max-width: 1366px) {
                padding: 40px 0;
            }

            @include breakpoint(mobile) {
                padding: 0;
            }

            .form-auth-wrap {
                margin: 40px 15px;
            }

            .col-md-offset-8 {
                @media screen and(max-width: 1030px) {
                    margin-left: 63%;
                }

                @media screen and(max-width: 920px) {
                    margin-left: 0;
                }
            }
        }
        &.for__activation {
            .heading {
                padding-top: 5rem;
            }
        }
        &.type2 {
            height: 100%;

            .col-md-offset-8 {
                @media screen and(max-width: 1030px) {
                    margin-left: 63%;
                }

                @media screen and(max-width: 920px) {
                    margin-left: 0;
                }
            }

            & > .row {
                // display: table;
                // width: 100%;
                height: 100%;

                .col-md-4 {
                    position: absolute;
                    top: 45%;
                    bottom: initial;
                    left: initial;
                    right: 0;
                    transform: translate(0, -50%);

                    @media screen and(max-width: 1030px) {
                        right: 25px;
                    }
                }
            }
        }

        // &.for__login {
        // 	top: 40%;
        // }

        // &.type2 {
        // 	top: 30%;
        // }
    }

    .form-group {
        @include breakpoint(mobile) {
            margin-bottom: 20px;
        }

        &.checkbox-group {
            @include breakpoint(mobile) {
                margin-bottom: 0;
            }
        }

        > label {
            display: block;
            color: $neutral-400;
        }

        &.remember {
            label {
                display: inline-block;
                opacity: 1;
                visibility: visible;
                &.error {
                    left: 0;
                }
            }
        }
        &.radio-group,
        &.checkbox-group {
            label {
                &.error {
                    left: 0;
                }
            }
        }
    }
    .btn-area {
        position: relative;
        ul {
            padding: 0;
            margin: 0;
            display: block;
            text-align: center;
        }

        li {
            list-style: none;
            display: inline-block;

            &:first-child {
                float: left;
            }
            &:last-child {
                float: right;

                @include breakpoint(tablet) {
                    float: none;
                    display: block;
                    width: 50%;
                    margin: auto;
                }
            }
        }

        .btn-primary {
            @include breakpoint(mobile) {
                display: inline-block;
                width: auto;
                padding: 8px 15px;
            }
            &.btn-login-popup {
                float: right;
                @include breakpoint(mobile) {
                    float: none;
                    padding: 8px 45px;
                }
            }
            &.btn-submit-popup {
                @include breakpoint(mobile) {
                    padding: 8px 45px;
                }
            }
        }

        a {
            text-decoration: none;
        }
    }

    .forgot {
        font-family: "jenius-sans";
        color: rgba(45, 45, 45, 0.4);
        font-size: 1em;
        font-style: italic;
        position: relative;
        top: 10px;

        @include breakpoint(tablet) {
            position: absolute;
            right: 0;
            top: 2px;
        }
    }

    .bottom-text-area {
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0 0 15px;
        overflow: hidden;

        .body-text-3 {
            width: 100%;
        }

        span {
            font-family: "jenius-sans";
            position: relative;
            display: block;
            font-weight: lighter;
            font-size: 1.1em;
            color: rgba(45, 45, 45, 0.4);
            &:before {
                position: absolute;
                content: "";
                left: 0;
                bottom: 8px;
                width: 43%;
                height: 0.6px;
                border-bottom: 1px dashed rgba(45, 45, 45, 0.4);
            }
            &:after {
                position: absolute;
                content: "";
                right: 0;
                bottom: 8px;
                width: 43%;
                height: 0.6px;
                border-bottom: 1px dashed rgba(45, 45, 45, 0.4);
            }
        }
        .body-text-1 {
            margin: 20px 0 0;
            p {
                width: 80%;
                margin: auto;
            }
            a {
                position: relative;
                color: $accent-300;
                font-family: "jenius-sans";
                text-decoration: none;

                &:after {
                    position: absolute;
                    content: "";
                    left: 0;
                    right: 0;
                    bottom: -3px;
                    height: 0.6px;
                    width: 100%;
                    background: $tertiary-300;
                }
            }
        }
    }

    .btn-area {
        margin: 30px 0;
        overflow: hidden;
    }
}

.form-wrap,
.form-area {
    @include breakpoint(mobile) {
        margin: 40px 0;
    }

    .form-control {
        width: 100%;
        border-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .check {
        font-family: "jenius-sans";
        display: inline-block;
        vertical-align: middle;
        color: rgba(45, 45, 45, 0.4);
        font-size: 1em;
        &.text {
            margin-left: 5px;
        }
        &.checkbox {
            height: 20px;
            width: 20px;
            background: $neutral-75;
        }
    }
}

.today {
    pointer-events: none;
}

// datepicker v2
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
    border: 1px solid $tertiary-300;
    background: $tertiary-300;
    color: $accent-300;
}

.ui-widget-header .ui-icon {
    background-image: url(../img/jqueryui/ui-icons_444444_256x240.png);
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button {
    border: 1px solid white;
}

.ui-state-active,
.ui-widget-content .ui-state-active {
    background: $accent-300;
    color: $tertiary-300;
}

html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
    background: $accent-100;
}

.ui-datepicker-year,
.ui-datepicker-month {
    background: white;
    border: 1px solid grey;
    padding: 0px 2px;
}
.btn-area .btn-blue {
    // font-weight: bold;
}
.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 2px;
}
.btn-area .btn-primary {
    margin-bottom: 20px;
    background: $tertiary-300;
}
.btn-area .btn-blue {
    margin-top: 20px;
}

.grecaptcha-badge {
    z-index: 9;
}
