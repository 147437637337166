.kurs-table {
	width: 100%;
	border: 1px solid #ededed;
	margin-bottom: 50px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
    overflow: hidden;
	th {
		font-family: 'jenius-sans';
		background: $tertiary-300;
		font-weight: normal;
		color: $accent-300;
		text-align: center;
		padding: 15px 30px;
		font-size: 1em;
		border: 1px solid #ededed;

		@include breakpoint(mobile) {
			padding: 15px 0;
		}
	}
	td {
		background: $white;
		text-align: center;
		padding: 10px 30px;
		font-family: 'jenius-sans';
		font-size: 0.875em;
		color: #424242;
		border: 1px solid #ededed;

		@include breakpoint(mobile) {
			padding: 15px 0;
		}

	}
}
