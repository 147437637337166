.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    &:after {
        position: fixed;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $neutral-400;
        opacity: 0.8;
    }

    &.show {
        visibility: visible;
        opacity: 1;
        z-index: 999;
    }

    .content-modal {
        padding: 30px;
        background: $white;
        position: absolute;
        top: 50%;
        margin: auto;
        transform: translateY(-50%);
        z-index: 2;

        @include breakpoint(mobile) {
            padding: 0;
        }

        .row {
            margin: 0;
        }

        .form-group {
            label {
                &.error {
                    @include breakpoint(mobile) {
                        overflow: initial;
                    }
                }
            }
        }
        &.content-register {
            left: 30%;
            right: 30%;

            @include breakpoint(tablet) {
                left: 5%;
                right: 5%;
            }
        }

        &.content-confirm {
            left: 25%;
            right: 25%;

            @include breakpoint(tablet) {
                left: 5%;
                right: 5%;
            }
        }

        &.content-thanks {
            left: 25%;
            right: 25%;

            .btn-area {
                @include breakpoint(mobile) {
                    width: 80%;
                    margin: 30px auto;
                }
            }

            @media screen and(max-width: 1366px) {
                left: 15%;
                right: 15%;
            }

            @include breakpoint(tablet) {
                left: 5%;
                right: 5%;
                padding: 50px 16px 16px 16px;
            }
        }

        &.content-login {
            left: 30%;
            right: 30%;

            @media screen and(max-width: 1366px) {
                left: 20%;
                right: 20%;
            }

            @include breakpoint(tablet) {
                left: 5%;
                right: 5%;
            }
        }

        //  &.margin {
        //  	@include breakpoint(tablet) {
        //  		left: 0;
        //  		right: 0;
        //  		height: 100vh;
        // overflow-y: scroll;
        // padding-top: 140px;
        //  	}
        //  }

        .close-modal {
            position: absolute;
            right: 15px;
            top: 15px;
            outline: none;

            @include breakpoint(mobile) {
                position: absolute;
                right: 10px;
                top: 10px;
                outline: none;
            }
        }

        .jcf-select-text {
            span {
                font-size: 0.9em;
            }
        }

        .form-control {
            font-size: 1em;
            &::-webkit-input-placeholder {
                font-family: "jenius-sans";
                color: rgba(45, 45, 45, 0.4);
                font-size: 0.8em;
                letter-spacing: 0;
            }
            &::-moz-placeholder {
                font-family: "jenius-sans";
                color: rgba(45, 45, 45, 0.4);
                font-size: 0.8em;
                letter-spacing: 0;
            }
            &::-ms-input-placeholder {
                font-family: "jenius-sans";
                color: rgba(45, 45, 45, 0.4);
                font-size: 0.8em;
                letter-spacing: 0;
            }
            &::-moz-placeholder {
                font-family: "jenius-sans";
                color: rgba(45, 45, 45, 0.4);
                font-size: 0.8em;
                letter-spacing: 0;
            }
        }

        .subheading-3 {
            h3 {
                font-family: "jenius-serif";
                font-weight: 600;
            }
        }

        .jcf-select-drop-content {
            border: none;
        }

        .jcf-select-drop-content {
            top: 2px;
            border: none;
            position: relative;
            box-shadow: 0px 1px 1px 1px rgba(240, 240, 240, 1);
        }
        .jcf-option {
            cursor: pointer;
            font-family: "jenius-sans";
            font-size: 0.9em;
            color: $neutral-200;
            font-weight: lighter;
        }
        .jcf-list-content {
            ul {
                margin: 0;
                text-align: left;
            }
        }

        .jcf-select {
            position: relative;
            border: none;
            min-width: auto;
            padding: 0 30px;
            border-bottom: 1px solid $accent-300;
            margin-left: 10px;
            cursor: pointer;

            &:after {
                position: absolute;
                content: "";
                right: 0;
                top: 5px;
                background: url(../img/icon-arrow-down.png);
                background-repeat: no-repeat;
                background-size: 20px;
                height: 15px;
                width: 20px;
            }

            &.jcf-drop-active {
                &:after {
                    background: url(../img/icon-arrow-top-orange.png);
                }
            }
        }

        .jcf-select-opener {
            background: none;
        }

        .jcf-select-text {
            position: relative;
            margin: 0;
            font-weight: 600;
            cursor: pointer;
        }
    }

    .erorr-form-popup {
        > li {
            position: relative;
            list-style: none;
            font-size: 1em;
            font-family: "jenius-sans";
            color: #e23013;
            line-height: 1.5;
            padding-left: 20px;

            &:before {
                position: absolute;
                content: "";
                left: 0;
                top: 7px;
                height: 5px;
                width: 5px;
                background: #e23013;
                overflow: hidden;
                border-radius: 100%;
            }
        }
    }

    .success-form-popup {
        > li {
            position: relative;
            list-style: none;
            font-size: 1em;
            font-family: "jenius-sans";
            color: $accent-300;
            line-height: 1.5;
            padding-left: 20px;
        }
    }

    .subheading-3 {
        margin-bottom: 5px;

        @include breakpoint(mobile) {
            padding: 55px 20px 0;
        }
        h3 {
            font-size: 1.875em;
            line-height: 2;

            @include breakpoint(mobile) {
                font-size: 1.5em;
                line-height: 1.2;
            }
        }
        h4 {
            font-family: "jenius-sans";
            font-size: 1.375em;
            font-weight: 600;
            color: $accent-300;

            @include breakpoint(mobile) {
                font-size: 1em;
                margin-bottom: 10px;
            }

            strong {
                color: $secondary-300;
                font-weight: 600;
            }
        }
    }

    .body-modal {
        @include breakpoint(mobile) {
            max-height: 400px;
            overflow-y: auto;
        }
        ul {
            text-align: center;
            margin: 0;
            li {
                list-style: none;
                font-family: "jenius-sans";
                color: $accent-300;
                line-height: 2;
            }

            &.error-messages-list {
                text-align: center;

                li {
                    font-family: "jenius-sans";
                    font-size: 1em;
                    color: #e23013;
                    list-style: none;
                }
            }
        }

        .item-login {
            ul {
                > li {
                    margin: 0;
                }
            }
        }

        .error-messages {
            font-family: "jenius-sans";
            font-size: 1em;
            color: #e23013;
        }

        &.auto {
            @include breakpoint(mobile) {
                height: auto;
                padding: 0 10px;
            }
        }
    }

    .body-text-3 {
        // width: 70%;
        margin: auto;

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    .btn-area {
        ul {
            padding: 0;
            margin: 0;
            display: block;
        }

        li {
            list-style: none;
            display: inline-block;
            margin: 0 10px;

            @include breakpoint(mobile) {
                display: block;
                margin: 0 0 20px;
            }
        }

        a {
            text-transform: uppercase;

            @include breakpoint(mobile) {
                display: block;
                width: 100%;
                font-size: 0.8em;
            }

            &.forgot {
                text-transform: capitalize;
            }
        }
    }

    .block-login-popup {
        padding: 0 180px;

        @media screen and(max-width: 1700px) {
            padding: 0 25px;
        }

        .form-wrap {
            @include breakpoint(mobile) {
                margin: 0;
            }
        }
    }

    .jcf-focus {
        border-color: $accent-300 !important;
    }

    label {
        &.error {
            font-family: "jenius-sans";
            font-size: 1em;
            padding-right: 40px;
            font-weight: lighter;
            display: inline-block;
            color: #e23013 !important;
            position: relative;
            top: 5px;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;

            @include breakpoint(mobile) {
                padding-right: 0;
            }

            &.show-error {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    &.new-popup {
        .content-modal {
            padding: 32px 67px 44px;
            text-align: center;
            border-radius: 8px;
            right: unset;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;

            @include breakpoint(mobile) {
                padding: 12px 24px;
                width: 90%;
            }

            p {
                padding: 0;
                margin: 0;
            }
        }

        .subheading-3 {
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;

            @include breakpoint(mobile) {
                padding: 0;
            }
        }

        .close-icon {
            width: 56px;
            height: auto;
        }

        .description {
            font-size: 14px;
            line-height: 20px;
            //harus di integrate dengan design system
            color: #3d4148;
        }

        .close-modal {
            position: relative;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 99px;
            //harus di integrate dengan design system
            background: $tertiary-300;
            padding: 8px 24px;
            font-size: 14px;
            line-height: 16px;
            //harus di integrate dengan design system
            color: $accent-300;
            width: fit-content;
        }
    }
}

#errorFormValidation {
    .content-thanks {
        padding: 35px 10px 10px 10px;
    }

    .erorr-form-popup {
        width: 90%;
    }
}

#successUpdateProfile {
    .subheading-3 {
        padding: 50px 20px 30px;
    }
}

#registerAfterLogin {
    label {
        &.error {
            top: -5px;
        }
    }
}
