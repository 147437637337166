.banner {
    position: relative;
    height: 418px;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @include breakpoint(mobile) {
        height: 380px;
        background-attachment: scroll;
        background-position: left top;
        background-size: 100% 100%;
    }

    > .container {
        height: 418px;
        @include breakpoint(mobile) {
            height: 380px;
        }
        > .row {
            height: 418px;
            @include breakpoint(mobile) {
                height: 380px;
            }
            > .col-md-12 {
                height: 418px;
                @include breakpoint(mobile) {
                    height: 380px;
                }
            }
        }
    }

    &:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        opacity: .7;
        // bottom: 0;

        background: rgba(0, 0, 0, 1);
        background: rgba(0, 0, 0, 1);
        background: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.39) 32%
        );
        background: -webkit-gradient(
            left top,
            left bottom,
            color-stop(0%, rgba(0, 0, 0, 1)),
            color-stop(0%, rgba(0, 0, 0, 1)),
            color-stop(27%, rgba(0, 0, 0, 0.49)),
            color-stop(27%, rgba(0, 0, 0, 0.49)),
            color-stop(32%, rgba(0, 0, 0, 0.39))
        );
        background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.39) 32%
        );
        background: -o-linear-gradient(
            top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.39) 32%
        );
        background: -ms-linear-gradient(
            top,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.39) 32%
        );
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.49) 27%,
            rgba(0, 0, 0, 0.39) 32%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4f4336', endColorstr='#4f4336', GradientType=0 );

        @include breakpoint(mobile) {
            top: 0;
            // height: 380px;
            background: url(../img/gradient-hero-mobile.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    &:after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .breadcrumbs {
        margin: 0;
        a,
        li {
            color: $tertiary-300;
        }
    }

    .subheading-2 h4 {
        background: $tertiary-300;
        color: $accent-300;
        font-weight: normal;
        display: inline-block;
        border-radius: 24px;
        padding: 4px 12px;
    }

    &.banner-about {
        &:before {
            height: 418px;
            top: 5px;
            background: url(../img/shadow.png);
            background-size: cover;
            background-repeat: no-repeat;

            @include breakpoint(mobile) {
                top: -5px;
            }
        }
        &:after {
            background: none;
        }
    }

    .text-wrap {
        &.heading-text-detail {
            position: absolute;
            bottom: 30px;

            @include breakpoint(mobile) {
                bottom: 10px;
            }
        }

        &.heading-text-middle {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
}
