@mixin breakpoint($value) {
    @each $screen, $width in $media-query {
        @if ($value == $screen) {
            @media #{$width} {
                @content;
            } 
        }
    }
}

@mixin text-row($Line-height, $Rows) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $Rows; /* number of lines to show */
    line-height: $Line-height;        /* fallback */
    max-height: $Line-height*$Rows;
} 

// ANIMATED 
@mixin animate($sec: false) {
  @if $sec {
    transition: all $sec ease;
  } @else { 
    transition: all .3s ease;
  }
}