.tab-sorting {
	position: relative;
	padding: 10px 0;
	margin: 0 0 30px;
	z-index: 10;
	.sorting-wrap {
		background: $neutral-50;
		
	}

	.item-sorting {
		position: relative;
		padding: 20px;
		display: inline-block;
		vertical-align: middle;

		&:after {
			position: absolute;
			content: "";
			right: 0;
			top: 50%;
			height: 60%;
			width: 1px;
			background: #e5e5e5;
			transform: translateY(-50%);
		}

		&:last-child {
			&:after {
				background: none;
			}
		}
		
		h4 {
			font-family: 'jenius-sans';
			font-size: 0.9em;
			color: $neutral-300;
			text-transform: uppercase;
			font-weight: 600;
		    line-height: 1.5;
		    // span.pull-right{
		    // 	margin-left: 10px;
		    // }
			
		}
		.item {
			width: 49%;
			display: inline-block;
		    vertical-align: baseline;
			//height: 20px;
			//vertical-align: middle;
			&.width-1 {
				width: 1%;
			}
		}

		&.width-99 {
			width: 102%;

			.jcf-select-drop-content {
				left: -18px;
			}
		}
		&.width-48 {
			width: 48%;
			margin-left: -5px;

			&.width-48-drop1 {
				.jcf-select-drop-content {
					width: 118%;
				}
			}

			&.width-48-drop2 {
				.jcf-select-drop-content {
					width: 138.5%;
				}
			}

			&.width-48-drop3 {
				.jcf-select-drop-content {
					width: 124%;
				}
			}

			&.width-48-drop4 {
				.jcf-select-drop-content {
					width: 123%;
					left: -6px;
				}
			}
		}
		&.width-40 {
			width: 40%;
			margin-left: 70px;

			&:after {
				right: -30px;
			}
		}
		&.width-32 {
			width: 32%;
			margin-left: -5px;

			&.width-32-drop1 {
				.jcf-select-drop-content {
					width: 123%;
				}
			}
		}
	}

	.jcf-select-drop-content {
    	font-family: 'jenius-sans';
		width: 120%;
    	left: -5px;
		// width: 250px;
		border: none;
		position: relative;
    	top: 20px;
		font-size: 0.9em;
		color: $neutral-300;
		box-shadow: 0px 5px 21px 0px rgba(227,227,227,1);
	}
	.jcf-option {
		padding: 15px 20px;
		cursor: pointer;
		white-space: normal;
		line-height: 1.5;
		font-size: 15px;
	}

	.jcf-focus {
	    border-color: #d5d5d5 !important;
	}
	
	.jcf-select {
		cursor: pointer;
		background: none;
		border: none;
		/*float: left;*/
		width: 100%;
		font-family: 'jenius-sans';
		font-size: 0.9em;
		margin-bottom: 0;
		color: $neutral-300;
		text-align: left;
		height: 20px;

		&.jcf-drop-active {
			.jcf-select-text {
				&:after {
					background: url(../img/sprite-arrow-brown.png);
					background-repeat: no-repeat;
					background-size: 35px;
		    		background-position: -20px 5px;
				}
			}
		}
		
	}

	.jcf-selected {
		background-color: $accent-300;
		color: $tertiary-300;
	}

	.jcf-select-opener {
		background: none;
		width: 15px;
	}
	.jcf-select-text {
		height: 20px;
		cursor: pointer;
		margin-right: 0;
		margin-left: 0;
		position: relative;
		&:after {
			position: absolute;
			content: "";
			right: 0;
			top: 50%;
			height: 15px;
			width: 15px;
			transform: translateY(-50%);
			background: url(../img/sprite-arrow-brown.png);
			background-repeat: no-repeat;
			background-size: 35px;
    		background-position: 0 5px;
		}

		span {
			width: 95%;
    		display: block;
    		overflow: hidden;
    		white-space: normal;
			line-height: 20px;
			font-size: 15px;
    		height: 20px;
		}
	}
		
	.category-wrap {
		overflow-x: hidden;
    	overflow-y: auto;
    	
    	&.active {
    		// height: 200px;
    	}
	}
	&.tab-sorting-mobile {
    	padding: 0;
    	position: fixed;
    	top: 60px;
	    left: 0;
	    right: 0;
		background: $white;
		margin: 0;
		z-index: 91;

		&.show {
			box-shadow: 0px 2px 8px 0px rgba(189,189,189,1);
			z-index: 999;
		}
		ul {
			&.parent-category {
				padding: 0;
				margin: 0;
				display: block;
				text-align: center;
				>li {
					width: 49%;
					list-style: none;
					display: inline-block;

					&:first-child {
						a {
							padding-left: 0;
						}
					}
					>a {
						position: relative;
						display: block;
						background: $neutral-50;
						padding: 20px 0;
						padding-right: 20px;
						text-decoration: none;
						font-family: 'jenius-sans';
						font-size: 0.9em;
						font-weight: 600;
						color: $neutral-300;

						&.active-white {
							background: #fff;
						}

						&:after {
							position: absolute;
							font-family: 'FontAwesome';
						    content: "\f078";
						    right: 18px;
						    top: 21px;
						    height: 15px;
						    width: 15px;
						    // background: url(../img/icon-arrow-down.png);
						    // background-repeat: no-repeat;
						    // background-size: 20px;
						    // background-position: center;
						}
					}

					&.active {
						a {
							// background: $white;
							&:after {
								content: '\f00d';
								color: $accent-300;
								// background: url(../img/icon-close-sorting.png);
								// background-size: 12px;
    							// background-repeat: no-repeat;
    							// top: 23px;
							}
						}
					}
				}

				&.parent-category-one {
					>li {
						width: 100%;
					}
				}
			}
			&.children-category-primary {
				>li {
					>a {
						font-weight: 300;
					}
				}
			}
			&.children-category-primary,
			&.children-category-secondary {
				display: none;
				
				>li {
					display: block;
					margin: 0 15px;
					border-bottom: 1px solid rgba(113, 113, 113, 0.30);

					&:last-child {
						border-bottom: none;
					}

					>a {
						position: relative;
						display: block;
						font-family: 'jenius-sans';
						font-size: 1em;
						font-weight: lighter;
						position: relative;
						display: block;
						background: $white;
						padding: 20px 0;
						padding-right: 20px;
						text-decoration: none;
						color: $neutral-300;

						&.active {
							margin: 0 -15px;
							padding: 20px 15px;
							background-color: $accent-300;
							color: $tertiary-300;
						}
						
					}
				}
			}

			&.children-category-secondary {

				&.no-child {
					>li {
						>a {
							&:after {
								background: none;
							}
						}

						&.active {
							>a {
								&:after {
									background: none;
								}
							}
						}
					}
				}

				>li {
					list-style: none;
					>a {
						font-weight: 600;

						&.active {
							color: $accent-300;
						}

						&:after {
							position: absolute;
						    content: "";
						    right: 10px;
						    top: 20px;
						    height: 20px;
						    width: 23px;
						    background: url(../img/icon-sprite-plusminus.png) no-repeat;
						    background-size: 40px;
						    background-position: 2px 1px;
						}
					}
					&.active {
						>a {
						    &:after {
						    	background: url(../img/icon-sprite-plusminus.png) no-repeat;
    							background-size: 40px;
						    	background-position: -19px 1px;
						    }	
						}
					}

					ul {
						display: none;
						padding: 0;
						margin: 0 0 10px;

						>li {
							list-style: none;
							display: block;
							font-family: 'jenius-sans';
							font-size: 1em;
							color: $neutral-300;
							padding: 10px 0;
							
							label {
								font-family: 'jenius-sans';
								font-weight: 400;
							}
						}
					}
				}
			}
		}
		// .btn-area {
		// 	display: none;
		// 	margin: 50px 0;
			
		// }
		// .btn-primary {
		// 	width: 50%;
		// }
	}
}


.sorting-hi-xs {
	@media screen and(max-width: 1024px) {
		display: none;
	}
}

.sorting-vi-xs {
	@media screen and(max-width: 1030px) {
		display: block;
	}

	@media screen and(min-width: 1030px) {
		display: none;
	}
}