@import "bourbon";

/* ABSTRACTS */
@import
    '../Abstracts/variables',
    '../Abstracts/functions',
	'../Abstracts/mixins',
	'../Base/fonts',
	'../Base/fonts-icon';

.block-dashboard {
	padding: 20px 0 50px;

	@include breakpoint(mobile) {
		padding: 0 0 10px;
	}

	.list-tanya-ahli {
		transition: all .3s ease-in-out;

		&.swipe {
			transform: translateX(-120%);
		}
		&.hide {
			display: none;
		}
	}

	.profile-wrap {
		@include breakpoint(mobile) {
			margin-top: 30px;
		}
	}

	.avatar {
		position: relative;
		margin: auto;
		overflow: hidden;
		border-radius: 100%;
		margin-left: auto !important;

		img {
			position: absolute;
		    top: 50%;
		    left: 50%;
		    bottom: initial;
		    right: initial;
		    max-width: 150%;
		    max-height: 150%;
		    min-width: 100%;
		    min-height: 100%;
		    transform: translate(-50%, -50%);
		}

		.default {
			max-width: 100%;
			max-height: 100%;
		}

		&.small {
			height: 75px;
			width: 75px;
		}
		&.big {
			height: 145px;
			width: 145px;

			@include breakpoint(mobile) {
				height: 100px;
				width: 100px;
			}
		}
	}

	.text-area {
		margin: 20px 0 0;
	}

	.subheading-4 {
		margin-bottom: 10px;
	}

	.body-text-1 {
		p {
			margin-bottom: 10px;
			line-height: 1.1;
			strong {
				font-weight: 600;
				color: $accent-300;
				display: block;
			}
		}
	}

	.option-area {

		.subheading-3 {
			 padding: 15px 10px;
		}

		ul {
			display: block;
			margin: 0;
			padding: 0;

			@include breakpoint(mobile) {
				text-align: center;
			}

			>li {
				list-style: none;
				display: block;
				border-bottom: 1px solid #e5e5e5;

				@include breakpoint(mobile) {
					position: relative;
					display: inline-block;
					width: 32%;
    				vertical-align: middle;
    				border-bottom: none;

    				&:after {
    					position: absolute;
					    content: "";
					    right: 0;
					    top: 50%;
					    bottom: 0;
					    height: 55%;
					    width: 1px;
					    background: #e5e5e5;
					    transform: translateY(-50%);
    				}

    				&:last-child {
    					&:after {
    						display: none;
    					}
    				}
				}

				&:first-child {
					border-top: none;
				}

				&:last-child {
					border-bottom: none;
				}

				&.active {
					>a {
						font-family: 'jenius-sans';
						font-weight: 600;
					}
				}

				>a {
					text-align: left;
				    position: relative;
				    display: block;
				    padding: 15px 5px;
				    padding-right: 30px;
				    font-family: 'jenius-sans';
				    color: $accent-300;
					font-size: 0.9em;
					text-decoration: none;
					transition: all .3s ease-in-out;

					&:hover {
						color: $accent-300;
					}

					span {
						padding: 3px 0;
					    position: absolute;
						text-align: center;
					    right: 4px;
					    top: 50%;
					    display: block;
					    height: 25px;
					    width: 25px;
					    background: #e6514b;
					    color: #fff;
					    font-family: 'jenius-sans';
					    font-size: 0.8em;
						border-radius: 100%;
						transform: translateY(-50%);
						text-align: center;
						line-height:16px;
					}
				}
			}
		}

  }

	.upload-area {
		position: relative;
    	left: 40px;

    	@include breakpoint(tablet) {
    		left: 0;
    	}

		.heading {
			margin-bottom: 30px;

			@include breakpoint(mobile) {
				text-align: center;
			}
		}

		.file-upload {
			display: none;
		}

		.upload-link {
			position: absolute;
		    display: inline-block;
		    right: 45px;
		    bottom: 0;

		    @include breakpoint(mobile) {
		    	right: 95px;
		    }

		    img {
		    	@include breakpoint(mobile) {
		    		width: 40px;
		    	}
		    }
		}

		.avatar-wrap {
			position: relative;
		}

		.dropdown-wrap {
			.subheading-3 {
				margin-top: 50px;
				margin-bottom: 10px;

				@include breakpoint(mobile) {
					margin-top: 30px;
				}


				h3 {
					text-transform: uppercase;

					@include breakpoint(mobile) {
						color: $neutral-200;
					}
				}
			}
		}

		.jcf-select {
			position: relative;
			cursor: pointer;
			width: 100%;
			border: none;
			height: auto;

			&:after {
				position: absolute;
				content: "";
				right: 0;
				top: 50%;
				background: url(../img/icon-arrow-down.png);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20px;
				height: 20px;
				width: 20px;
				transform: translateY(-50%);

				@include breakpoint(mobile) {
					right: 10px;
				}
			}

			.jcf-select-text {
				cursor: pointer;
				width: 100%;
				text-align: center;
				border-bottom: 1px solid $accent-300;
				padding: 10px 0;
				margin: 0;

				@include breakpoint(mobile) {
					border-bottom: 1px solid $neutral-200;
				}

				span {
					font-family: 'jenius-sans';
					font-size: 1em;
					font-weight: 400;
					color: $accent-300;

					@include breakpoint(mobile) {
						color: $neutral-200;
					}
				}
			}

			.jcf-select-opener {
				background: none;
			}
		}

		.jcf-select-drop {
			left: 0;
		}

		.jcf-select-drop-content {
			box-shadow: 0px 0px 5px 0px rgba(194,194,194,1);
			ul {
				>li {

					.jcf-option.jcf-selected {
						background-color: $accent-300 !important;
						color: $tertiary-300 !important;
					}

					span {
						padding: 15px 30px;
						font-family: 'jenius-sans';
						font-size: 1em;
						font-weight: 400;
						color: $accent-300;

						@include breakpoint(mobile) {
							color: $neutral-200;
						}
					}
				}
			}
		}
	}

	.form-area {
		@include breakpoint(mobile) {
			margin-top: 20px;
    		padding: 0 10px
		}

		.btn-area {
			margin-top: 50px;
			ul {
				text-align: center;
				>li {
					margin: 0 10px;
					list-style: none;
					display: inline-block;

					.btn-secondary {
						padding: 8px 40px;

						@include breakpoint(tablet) {
							padding: 8px 25px;
						    display: inline-block;
						    width: 100%;
						}
					}
				}
			}
		}

		label {
			color: rgba(45, 45, 45, 0.40);
		}
	}

	// .breadcrumbs {
	// 	margin-bottom: 20px;
	// }

	.block-update-profile {
		padding: 30px 10px;

		@include breakpoint(mobile) {
			margin-bottom: 50px;
		}

		&.shadow {
			box-shadow: 0px 0px 45px 0px rgba(240,240,240,1);
		}
	}

	.block-chat {
	 	height: 560px;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 50px 30px 0;
		background: #f1f1f1;
		transition: all .3s ease-in-out;

		&.empty-wrap {
			@include breakpoint(mobile) {
				display: block;
				margin: 0;
	    		text-align: center;
	    		height: auto;
	    		padding-bottom: 50px;
	    		background: none;
			}
		}

		@include breakpoint(mobile) {
			position: relative;
    		// top: -520px;
		}

		&.swipe {
			transform: translateX(120%);
		}

		@include breakpoint(mobile) {
			margin: 20px -15px 0;
		}

		&::-webkit-scrollbar {
		    width: 6px;
		}

		&::-webkit-scrollbar-track {
		    background: #d6d6d6;
		}

		&::-webkit-scrollbar-thumb {
		    background: #7b7b7b;
		}

	}

	.block-ratting-question {
		background: #f1f1f1;
		padding: 30px 30px 0;
	    // position: absolute;
	    left: 20px;
	    right: 20px;
	    bottom: 20px;

		@include breakpoint(mobile) {
			padding: 30px 0 0;
			margin: 0 -15px;
		}
	}

	.block-tabs-training {
		.nav-pills {
			@include breakpoint(mobile) {
				text-align: center;
				border-bottom: 1px solid #8b837a;
			}

			li {
				@include breakpoint(mobile) {
					padding: 5px 4px;
					margin: 0;
					margin-left: -5px;


					&:first-child {
						margin-left: 0;
						float: left;
					}

					&:nth-child(2) {
						width: 28%;
					}

					&:last-child {
						float: right;
					}
				}
			}
		}

		&.block-tabs-ahli {
			.nav-pills {
				@include breakpoint(mobile) {
					border-bottom: none;
				}
				li {
					@include breakpoint(mobile) {
						width: 49%;
						&:first-child {
							margin-left: 0;
							float: none;
						}

						&:nth-child(2) {
							width: 49%;
						}

						&:last-child {
							float: none;
						}
					}
				}
			}
		}
	}

	.empty-wrap-mobile {
		background: none;
	}

	.chat-list-empty-mobile {
		display: none;

		@include breakpoint(mobile) {
			display: block;
		}
	}

	.list-question {
		position: relative;
		transition: all .3s ease-in-out;

		&.hide {
			height: 0;
			overflow: hidden;
		}
    	&.list-question-left {
    		height: 380px;
    		overflow-y: auto;

    		&::-webkit-scrollbar {
			    width: 2px;
			}

			&::-webkit-scrollbar-track {
			    background: #d6d6d6;
			}

			&::-webkit-scrollbar-thumb {
			    background: #7b7b7b;
			}

    	}
    	&.list-question-right {}



    	.body-empty-data {
    		position: absolute;
    		left: 0;
    		right: 0;
    		top: 50%;
    		transform: translateY(-50%);
    		text-align: center;

    		p {
				font-family: 'jenius-sans';
				color: $neutral-400;
				font-size: 0.875em;
				font-weight: 400;
			}
    	}

		.tab-content {
			// margin-left: -5px;

			&.process-wrap {
				display: block;
				transition: all .3s ease-in-out;

				@include breakpoint(mobile) {
					display: none;

					&.showing {
						display: block;
						opacity: 1;
						visibility: visible;
					}
				}

				&.hide {
					display: none;
				}


			}
			&.answer-wrap {
				display: none;
				transition: all .3s ease-in-out;

				@include breakpoint(mobile) {
					&.showing {
						display: block;
						opacity: 1;
						visibility: visible;
					}
				}

				&.showing {
					display: block;

					@include breakpoint(mobile) {
						display: none;
					}
				}
			}
		}
		ul {
			display: block;
			padding: 0;
			margin: 0;

			>li {
				position: relative;
				list-style: none;
				border-bottom: 1px solid #e5e5e5;
				margin-bottom: 0;

				a {
					display: block;
					text-decoration: none;
					padding: 20px 10px;
				}

				p {
					color: $neutral-200;
					font-size: 0.875em;
					font-family: 'jenius-sans';
				}

				&.unread {
					p {
						font-family: 'jenius-sans';
						font-weight: 800;
					}
				}

				&.active {
					background: #f1f1f1;
				}

				span {
					font-family: 'jenius-sans';
					float: right;
					font-size: 0.875em;
					color: $neutral-200;
					position: relative;
    				left: -10px;
    				top: -5px;
				}
				h3 {
					color: $accent-300;
					font-size: 0.875em;
					font-family: 'jenius-sans';
					font-weight: 600;
					text-transform: uppercase;
					line-height: 1.3;
					margin-bottom: 5px;
				}
				p {
					color: $neutral-400;
					font-size: 1em;
					font-family: 'jenius-sans';
					font-weight: 400;
					line-height: 1.5;
				}
			}
		}

		.body-chat {
			.chat-list {

				&.chat-list-empty {
					text-align: center;
					width: 50%;
					margin: auto;
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					transform: translateY(-50%);

					p {
						font-family: 'jenius-sans';
						font-size: 0.875em;
						color: $neutral-200;
						margin: 20px 0;
					}
				}

				.loading-wrap {
					text-align: center;
					margin: 20px 0;
					span {
						display: inline-block;
						margin: auto 5px;
						position: relative;
						height: 7px;
						width: 7px;
						background: #cecece;
						overflow: hidden;
						border-radius: 100%;
						transform: transformZ(0);
    					animation: loading 2s infinite;

						&:nth-child(1n) {
					      animation-delay: 0s;
					    }
					    &:nth-child(2n) {
					      animation-delay: 0.2s;
					    }
					    &:nth-child(3n) {
					      animation-delay: 0.4s;
					    }


					}
					p {
						margin: 5px auto 0;
						width: 50%;
						font-family: 'jenius-sans';
						color: #999999;
						font-size: 0.875em;
						font-weight: 400;

						@include breakpoint(mobile) {
							width: 100%;
						}
					}

					@keyframes loading {
					  0% {
					    background: $accent-300;
					  }
					  50% {
					    background: #cecece;
					  }
					  100% {
					    background: $accent-300;
					  }
					}
				}

				.form-question-wrap {
					textarea {
						padding: 15px 30px;
						font-family: 'jenius-sans';
						font-size: 1em;
						font-weight: 400;
						color: #424242;
						outline: none;
						background: $white;
						width: 100%;
						height: 100px;
						border: 1px solid $neutral-75;
					}
					.btn-area {
						text-align: right;

						.btn-secondary {
							@include breakpoint(mobile) {
								width: 40%;
								display: inline-block;
								margin-right: 20px;
							}
						}
					}
				}

				.question-popup {
					// position: absolute;
					margin-bottom: 20px;
				    left: 20px;
				    right: 20px;
				    bottom: 20px;
					background: $white;
					border: 1px solid #d5d5d5;
					border-radius: 3px;
					padding: 20px;

					p {
						font-family: 'jenius-sans';
						color: $neutral-400;
						font-size: 0.875em;
						font-weight: 400;
					}

					.btn-area {
						margin-bottom: 0;
					}

					a {
						width: 20%;
						padding: 5px 10px;
						margin: 0 10px;

						@include breakpoint(mobile) {
							width: 35%;
							display: inline-block;
						}
					}
				}
			}

			.chat {
				position: relative;
				margin: auto;
				width: 70%;
				background: $white;
				padding: 10px 15px;
				margin-bottom: 50px;

				&:last-child {
					margin-bottom: 0;
				}

				h3 {
					text-transform: uppercase;
					margin-bottom: 5px;

					@include breakpoint(mobile) {
						width: 70%;
					}
				}

				p {
					line-height: 1.5;
				}

				span {
					font-family: 'jenius-sans';
				    float: right;
				    font-size: 0.875em;
				    color: $neutral-300;
				    position: absolute;
				    right: 20px;
				    top: 10px;
				}

				.avatar {
					position: absolute;
					height: 55px;
					width: 55px;
					overflow: hidden;
					border-radius: 100%;
					top: -20px;

					img {
						position: initial;
						transform: initial;
						max-width: 125%;
    					max-height: 125%;
					}

				}

				&.question {

					@include breakpoint(mobile) {
						left: -40px;
					}

					h3 {
						color: $accent-300;
					}
					.avatar {
						right: -90px;
					}

					&:before {
						position: absolute;
						content: "";
						right: -25px;
						top: 0;
						width: 0px;
						height: 0px;
						border-color: transparent;
						border-style: solid;
						border-width: 35px 35px 0 0;
  						border-top-color: $white;
  						border-right-color: rgba(0, 0, 255, 0);
					}
				}
				&.answer {

					@include breakpoint(mobile) {
						right: -40px;
					}

					h3 {
						color: $accent-300;
					}
					.avatar {
						left: -90px;
					}

					&:before {
						position: absolute;
					    content: "";
					    left: -25px;
					    top: 0;
					    width: 0px;
					    height: 0px;
					    border-color: transparent;
					    border-style: solid;
					    border-width: 0 35px 35px 0;
					    border-right-color: $white;
					    border-bottom-color: rgba(0,255,255,0);
					}
				}
			}

			a {
				text-decoration: none;
			}
		}

		.ratting-popup {
			background: $white;
			border: 1px solid #d5d5d5;
			border-radius: 3px;
			padding: 20px;

			&.bg-white {
				background: none;
				border: none;
				border-top: 1px solid #d5d5d5;
			}

			p {
				line-height: 1.8;
				font-family: 'jenius-sans';
				color: $neutral-400;
				font-size: 0.875em;
				font-weight: 400;
			}

			ul {
				padding: 0;
				margin: 0;
				display: block;

				>li {
					display: inline-block;
					list-style: none;
					margin: 0 2px;
					border-bottom: none;
					padding: 0;
					width: 25px;
					height: 25px;
					background-image: url(../img/icon-star.png);
					background-repeat: no-repeat;
					background-size: 25px;
					text-decoration: none;

					&.active {
						background: none;
						background-image: url(../img/icon-star-active.png);
						background-repeat: no-repeat;
					}
				}
			}


		}

		.ratting-wrap {
			&.ratting-wrap-post {
				position: absolute;
			    bottom: 30px;
			}
			.btn-area {
				text-align: right;

				.btn-secondary {
					@include breakpoint(mobile) {
						width: 40%;
						display: inline-block;
						margin-right: 20px;
					}
				}
			}
		}
	}
}

.rating {
	display: inline-block;
	border: none !important;
	height: 25px;
    width: 25px;
    background: url(../img/icon-star.png);
    background-size: 25px;
    background-repeat: no-repeat;
	border-radius: 0;
	transition: all .3s ease-in-out;
	cursor: pointer;
	margin: 0 2px;

	@include breakpoint(mobile) {
		height: 20px;
		width: 20px;
		background-size: 20px;
	}

	&.active {
		background: url(../img/icon-star-active.png);
    	background-size: 25px;
    	background-repeat: no-repeat;

    	@include breakpoint(mobile) {
    		background-size: 20px;
    	}
	}

}

.tab-content{
  .tab-pane{
    .wrapper{
      height: 598px;
      display: flex;
      flex-direction: column;
      @include breakpoint(mobile) {
        height: auto;
      }
      .pagination{
        margin-top: auto;
      }
    }
  }
}

// .datepicker {
// 	top: 315px !important;

// 	@include breakpoint(tablet) {
// 		top: 954px !important;
// 	}
// }
