body {
	overflow-x: hidden;
}

p {
	font-family: 'jenius-sans';
	font-size: 1em;
	color: $neutral-300;
}

span {
	font-family: 'jenius-sans';
}



.site-content {
	position: relative;
}

.heading {
	h2 {
		@include font-jenius-serif;
		font-size: 2.250em;
		color: $accent-300;
		margin-bottom: 15px;

		@include breakpoint(mobile) {
			font-size: 1.5em;
			margin-bottom: 8px;
		}
	}
	h3 {
		@include font-jenius-serif;
		font-size: 1.313em;
		color: $accent-300;
		margin-bottom: 15px;

		@include breakpoint(mobile) {
			font-size: 1em;
			margin-bottom: 8px;
		}
	}

	&.color-white {
		h2 {
			color: $white !important;
		}
	}
}

.heading-Alt {
	h3 {
		@include font-jenius-serif;
		line-height: 1.3;
	}

	h4 {
		font-family: 'jenius-sans';
		line-height: 1;
	}

	p {
		font-family: 'jenius-sans';
		line-height: 1;
	}

	&.big-title {
		h4 {
			font-family: 'jenius-sans';
			font-size: 20px;
			color: $tertiary-300;
			


			@include breakpoint(mobile) {
				font-size: 16px;
			}
		}
		h3 {
			@include font-jenius-serif;
			font-size: 26px;
			
			color:#fff;
			@include breakpoint(mobile) {
				font-size: 20px;
			}
		}
		.date {
			font-family: 'jenius-sans';
			font-size: 16px;
			color: #fff;
			
			@include breakpoint(mobile) {
				font-size: 14px;
			}
		}
		p {
			font-size: 1.125em;

			@include breakpoint(mobile) {
				font-size: 0.9em;
			}
		}
	}

	&.big-title-training {
		h4 {
			font-family: 'jenius-sans';
			font-size: 20px;
			color: $tertiary-300;
			


			@include breakpoint(mobile) {
				font-size: 16px;
			}
		}
		h3 {
			@include font-jenius-serif;
			font-size: 26px;
			
			color:#fff;
			@include breakpoint(mobile) {
				font-size: 20px;
			}
		}
		.date {
			font-family: 'jenius-sans';
			font-size: 16px;
			color: #fff;
			
			@include breakpoint(mobile) {
				font-size: 14px;
			}
		}
		p {
			font-size: 1.125em;

			@include breakpoint(mobile) {
				font-size: 0.9em;
			}
		}
	}

	&.small-title {
		h4 {
			font-size: 12px;
			color: $tertiary-300;
			font-family: 'jenius-sans';
			

			@include breakpoint(mobile) {
				font-size: 14px;
			}

		}
		h3 {
			font-size: 16px;
			color: #fff;
			
			@include font-jenius-serif;

			@include breakpoint(mobile) {
				font-size: 16px;
			}

		}
		.date {
			font-family: 'jenius-sans';
			font-size: 10px;
			color: #fff;
			
			@include breakpoint(mobile) {
				font-size: 12px;
			}
		}

		p {
			font-size: 0.875em;
			@include breakpoint(mobile) {
				font-size: 0.9em;
			}
		}

		&.orange {
			h3 {
				color: $tertiary-300;
			}
		}
	}
	&.small-title-training {
		h4 {
			font-size: 14px;
			color: $tertiary-300;
			font-family: 'jenius-sans';
			

			@include breakpoint(mobile) {
				font-size: 16px;
			}

		}
		h3 {
			font-size: 16px;
			color: #fff;
			
			@include font-jenius-serif;

			@include breakpoint(mobile) {
				font-size: 20px;
			}

		}
		.date {
			font-family: 'jenius-sans';
			font-size: 10px;
			color: #fff;
			
			@include breakpoint(mobile) {
				font-size: 12px;
			}
		}

		p {
			font-size: 0.875em;
			@include breakpoint(mobile) {
				font-size: 0.9em;
			}
		}

		&.orange {
			h3 {
				color: $tertiary-300;
			}
		}
	}

}

.subheading {
	&-1 {
		h3 {
			@include font-jenius-serif;
			color: $neutral-400;
			font-size: 1.250em;
			line-height: 1.5;

			@include breakpoint(mobile) {
				font-size: 1em;
			}
		}
	}
	

	&-2 {
		h1 {

		}
		h2 {
			
		}
		h3 {
			@include font-jenius-serif;
			color: $white;
			font-size: 2.625em;
			line-height: 1.5;
			

			@include breakpoint(mobile) {
				font-size: 1.8em;
				
			}
		}
		h4 {
			font-family: 'jenius-sans';
			color: $tertiary-300;
			font-size: 1.5em;
			line-height: 1.5;
			

			@include breakpoint(mobile) {
				font-size: 1em;
				text-transform: uppercase;
			}
		}

		&.white {
			h3 {
				@include font-jenius-serif;
				color: #f5821f;
				font-size: 1.8em;
				line-height: 1.5;
				

				@include breakpoint(mobile) {
					font-size: 1em;
					
				}
			}

			&.small {
				h3 {
					font-size: 1.6em;
				}
			}
		}
	}

	&-3 {
		h2 {
			font-size: 2.625em;
			@include font-jenius-serif;
			color: $accent-300;
			line-height: 1.5;
			margin-bottom: 20px;

			@include breakpoint(mobile) {
				font-size: 1.8em;	
			}
		}
		h3 {
			font-family: 'jenius-sans';
			color: $accent-300;
			font-size: 1em;
			
			line-height: 1.5;

			@include breakpoint(mobile) {
				
			}
		}
	}

	&-4 {
		h3 {
			@include font-jenius-serif;
			font-size: 1.5em;
			line-height: 1.2;
			color: $accent-300;
			

			@include breakpoint(mobile) {
				
			}
		}
	}

	&-5 {
		h2 {
			@include font-jenius-serif;
			color: $neutral-400;
			font-size: 2.625em;

			@include breakpoint(mobile) {
				font-size: 1.8em;
			}
		}
		h3 {
			font-family: 'jenius-sans';
			font-size: 1.5em;
			
			line-height: 1.8;

			@include breakpoint(mobile) {
				font-size: 1.2em;
			}
		}
	}

	&-6 {
		h3 {
			@include font-jenius-serif;
			font-size: 2em;
			
			color: $accent-300;;
			margin-top: 10px;

			@include breakpoint(mobile) {
				font-size: 1.5em;
			}
		}
		h4 {
			font-family: 'jenius-sans';	
			font-size: 1.5em;
			
			color: $accent-300;

			@include breakpoint(mobile) {
				font-size: 1em;
			}

		}

		&.small {
			h3 {
				font-size: 1.125em;

				@include breakpoint(mobile) {
					font-size: 1.5em;
				}
			}	

			&.white {
				h3 {
					color: $white;
				}
			}
		}

		&.small-italic {
			h3 {
				font-size: 1.125em;
				font-style: italic;

				@include breakpoint(mobile) {
					font-size: 1em;
				}
			}	
		}
	}

	&-7 {
		h4 {
			font-family: 'jenius-sans';
			font-size: 1.125em;
			
			color: $secondary-300;

			@include breakpoint(mobile) {
				font-size: 1em;
			}
		}
	}

}



.body-text- {
	&1 {
		p {
			font-family: 'jenius-sans';
			font-size: 1.125em;
			color: $neutral-300;
			line-height: 1.9;

			@include breakpoint(mobile) {
				font-size: 1.1em;
			}
		}

		ul {
			>li {
				font-family: 'jenius-sans';
				font-size: 1.125em;
				color: $neutral-300;
				line-height: 1.9;

				@include breakpoint(mobile) {
					font-size: 1.1em;
				}
			}
		}

		table {
			tr {
				td {
					font-family: 'jenius-sans';
					font-size: 1.125em;
					color: $neutral-300;
					line-height: 1.9;
					padding: 0 5px;

					@include breakpoint(mobile) {
						font-size: 1.1em; 
					}
				}
			}
		}

		&.orange {
			p {
				color: $blue;
			}
		}

		&.brown {
			p {
				color: $accent-300;
			}
		}

		&.white {
			color: $white;
			p {
				color: #e5e5e5;
			}

			&.italic {
				p {
					font-size: 0.9em;
					font-style: italic;
				}
			}
		}

		&.big {
			p {
				font-size: 1.2em;
			}
		}
	}

	&2 {
		p {
			font-family: 'jenius-sans';
			font-size: 0.875em;
			color: $neutral-400;
			margin-bottom: 0;
		}

		&.italic {
			font-style: italic;
		}
	}

	&3 {
		p {
			font-family: 'jenius-sans';
			font-size: 1.125em;
			color: $neutral-200;
			margin-bottom: 0; 
		}
	}

	&4 {
		p {
			font-family: 'jenius-sans';
			font-size: 1em;
			color: $neutral-300;
			line-height: 2.5;
			margin-bottom: 0;
		}
	}
	&5 {
		p {
			font-family: 'jenius-sans';
			font-size: 1.125em;
			color: $neutral-200;
			line-height: 1.9;

			@include breakpoint(mobile) {
				font-size: 1.1em;
			}
		}

		ul, 
		ol {
			li {
				color: $neutral-200;
			}
		}

		td {
			color: $neutral-200;
		}
	}
}

.text-area {
	padding: 0 0 20px;
	.btn-area {
		.btn-primary {
			@include breakpoint(mobile) {
				width: fit-content;
				padding: 8px 40px;
				font-size: 1em;
			}
		}
	}
	&.vertical-middle {
		@include breakpoint(tablet) {
			transform: initial;
		}
	}

	&.homepage {}

	&.single-page {
		width: 62%;

		@include breakpoint(mobile) {
			width: 100%;
		}
	}
}

.orange {
	h3, 
	h4 {
		color: $accent-300;
	}
}
.dark-green {
	h3,
	h4 {
		color: $accent-300;
	}
}
.red {
	h3, 
	h4 {
		color: $accent-300;
	}
}
.light-green {
	h3, 
	h4 {
		color: $accent-300;
	}
}
.blue {
	h3, 
	h4 {
		color: $accent-300;
	}
}
.color-brown {
	h3, 
	h4 {
		color: $accent-300;
	}
}

.vertical-middle {
	padding-bottom: 0;
	position: relative;
	top: 50%;
	transform: translateY(50%);

	&.reset-vertical {
		transform: inherit;
	}
}

.bg-white {
	background: $white;
}
.bg-light-gray {
	background: $neutral-50;

	&-2 {
		background: rgba(255,255,255,1);
		background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 69%, rgba(249,249,249,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(69%, rgba(255,255,255,1)), color-stop(100%, rgba(249,249,249,1)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 69%, rgba(249,249,249,1) 100%);
		background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 69%, rgba(249,249,249,1) 100%);
		background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 69%, rgba(249,249,249,1) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 69%, rgba(249,249,249,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9', GradientType=0 );
	}

	&-3 {
		// background: rgba(255,255,255,1);
		// background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		// background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(59%, rgba(249,249,249,1)), color-stop(100%, rgba(246,246,246,1)));
		// background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		// background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		// background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		// background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6', GradientType=0 );
		background: #fcfcfc;

		@include breakpoint(mobile) {
			background: $white;
		}
	}

	&-4 {
		background: rgba(255,255,255,1);
		background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(59%, rgba(249,249,249,1)), color-stop(100%, rgba(246,246,246,1)));
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 59%, rgba(246,246,246,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6', GradientType=0 );

	}
}
.bg-orange {
	background: $secondary-300;
}


.bg-light-orange {
	&-1 {
		background: rgba(254,254,254,1);
		background: -moz-linear-gradient(top, rgba(254,254,254,1) 0%, rgba(254,254,254,0.57) 71%, rgba(226,209,194,0.4) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,254,1)), color-stop(71%, rgba(254,254,254,0.57)), color-stop(100%, rgba(226,209,194,0.4)));
		background: -webkit-linear-gradient(top, rgba(254,254,254,1) 0%, rgba(254,254,254,0.57) 71%, rgba(226,209,194,0.4) 100%);
		background: -o-linear-gradient(top, rgba(254,254,254,1) 0%, rgba(254,254,254,0.57) 71%, rgba(226,209,194,0.4) 100%);
		background: -ms-linear-gradient(top, rgba(254,254,254,1) 0%, rgba(254,254,254,0.57) 71%, rgba(226,209,194,0.4) 100%);
		background: linear-gradient(to bottom, rgba(254,254,254,1) 0%, rgba(254,254,254,0.57) 71%, rgba(226,209,194,0.4) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefefe', endColorstr='#e2d1c2', GradientType=0 );

		@include breakpoint(mobile) {
			background: $white;
		}
	}
	&-2 {
		background: #fffcf5;
	}
}

//without slider

.content-page {
	margin-top: 60px;

	&.content-page-result {
		margin-top: 120px;

		@include breakpoint(mobile) {
			margin-top: 60px;
		}
	}
}


.video-js {
	width: 100%;
	height: 480px;

	@include breakpoint(mobile) {
		height: 200px;
	}

	.vjs-poster {
		position: relative;
		height: 100%;
    	width: 100%;
		background-size: cover;
		background-position: center;

		&:before {
			position: absolute;
			content: "";
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: #000000;
			opacity: .5;
		}
	}
	.vjs-big-play-button {
		height: 75px;
		width: 55px;
		background-color: initial;
		border: none;
		background: url(../img/icon-play.png);
		background-repeat: no-repeat;
		background-size: contain;
		margin: auto;
		left: 0;
		right: 0;
    	top: 50%;
    	transform: translateY(-50%);

    	@include breakpoint(mobile) {
    		height: 52px;
    		width: 42px;
    		background-size: 40px;
    	}

		&:before {
			content: "";
		}

		&:focus {
			background-color: initial;
			border: none;
		}
	}
}

.plyr__play-large {
	border: none;
}


.url {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	z-index: 9;
}


.content-description a * {
    font-style: normal;
    color: #000;
    font-size: 15px !important;
    margin: 0;
    
    padding: 0;
    border: none!important;
    list-style: none;
    text-align: left !important;
    position: static!important;
	text-decoration: none!important;
}

@include breakpoint(wide){
	#wa-desktop {
		display: none !important;
	}
}