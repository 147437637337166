@import "bourbon";

/* ABSTRACTS */
@import
    '../Abstracts/variables',
    '../Abstracts/functions',
	'../Abstracts/mixins',
	'../Base/fonts',
	'../Base/fonts-icon'; 


.block-content {
	
	 &.block-home {
	 	padding: 40px 0;

		 h1,h2,h3 {
			@include font-jenius-serif;
		}

		.sub-text-area .subheading-1 h3 {
			font-family: 'jenius-sans' !important;
		}

	 	@include breakpoint(mobile) {
	 		padding-bottom: 0;		
	 	}		

	 	.btn-area {
	 		@include breakpoint(mobile) {
	 			margin: 40px 0;
	 			text-align: center;
	 		}

			 .btn-primary,
			 .btn-secondary {
				padding: 9px 15px;
	 			@include breakpoint(mobile) {
	 				width: auto;
	 				display: inline-block;
	 			}
	 		}
	 	}

	 	.thumbnails-person {
	 		ul.desc-wrap {
	 			@include breakpoint(mobile) {
	 				// position: absolute;
	 			}
	 			li {
	 				
	 				h3 {
	 					@include breakpoint(mobile) {
	 						font-size: 1.5em;
	 					}
	 				}
	 			}
	 		}

	 		ul li img {
	 			@include breakpoint(mobile) {
	 				max-height: 340px;
	 			}
	 		}

	 		&.thumbnails-person-white ul li:before {
	 			@include breakpoint(mobile) {
	 				position: absolute;
				    content: "";
				    left: 0;
				    right: 0;
				    bottom: 0;
				    height: 100%;
				    background: url(../img/bg-white-mobile.png);
				    -moz-background-size: cover;
				    background-size: cover;
				    background-position: center;
				}	


	 		}

	 		&.thumbnails-person-white ul.desc-wrap li:before {
	 			background: none;
	 		}

	 	}

	 }

}
