@import "bourbon";

/* ABSTRACTS */
@import
    '../Abstracts/variables',
    '../Abstracts/functions',
	'../Abstracts/mixins',
	'../Base/fonts',
	'../Base/fonts-icon'; 

.block-tabs-training  {
	margin: 0 0 40px;

	@include breakpoint(mobile) {
		margin: 30px 0 40px;
	}

	&.block-tabs-result {
		.nav {
			margin-top: 30px;
			width: 70%;

			@include breakpoint(mobile) {
				width: 100%;
				text-align: center;
				border-bottom: none;
			}

			>li {

				@include breakpoint(mobile) {
					width: 48%;
					margin-left: -8px;
					border-bottom: 1px solid #8b837a;
					padding: 15px 0;
				}

				>a {
					padding: 0 15px;
				}

				&.active {
					&:after {
						height: 3px;
						
						@include breakpoint(mobile) {
							z-index: 1;
    						bottom: -2px;
						}
					}
				}
			}


		}

		.col-md-1 {
			width: 5%;

			@include breakpoint(mobile) {
				width: initial;
				margin-right: 10px;
			}
		}

		.heading {
			text-align: left;
			h2 {
				font-family: 'jenius-sans';
				font-weight: 400;
			}

			strong {
				position: relative;
				font-family: 'jenius-sans';

				&:before {

				}
			}
		}

		.tab-content {
			margin-top: 0;
		}
	}
}

.more-article {
	&.more-article-result {
		border-top: 0;
		padding: 10px 0 0;

		.thumbnails-article {
			margin-top: 20px;
		}

		article {
			&.post-thumbnails {
				margin: 0 15px 30px;
				h3 {
					a {
						color: $neutral-300;
					}
				}
				.text-wrap {
					@include breakpoint(mobile) {
						padding-left: 0;
					}
				}

				.row {
					.col-xs-5,
					.col-xs-7 {
						padding-left: 5px;
						padding-right: 5px;
					}
				}
			}
		}
	}
}