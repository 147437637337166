@each $name, $file in $font_family {
    @if $font_idx==1 {
        // $font_default: $name;
        $font_idx: 2;
    }

    @font-face {
        font-family: $name;
        src: url('#{$file}.eot');
        src: url('#{$file}.eot') format('embedded-opentype'),
        //url('#{$file}.woff2') format('woff2'),
        url('#{$file}.woff') format('woff'),
        url('#{$file}.ttf') format('truetype'),
        url('#{$file}.svg#FuturaStdLight') format('svg');
        font-display: swap;
    }
}

// Rebranding font definitions for heading
@font-face {
    font-family: "jenius-serif";
    font-weight: 300;
    src: url('fonts/jenius-serif/JeniusSerif-Light.otf') format("opentype");
}
@font-face {
    font-family: "jenius-serif";
    font-weight: 300;
    font-style: italic;
    src: url('fonts/jenius-serif/JeniusSerif-LightItalic.otf') format("opentype");
}
@font-face {
    font-family: "jenius-serif";
    font-weight: normal;
    src: url('fonts/jenius-serif/JeniusSerif-Regular.otf') format("opentype");
}
@font-face {
    font-family: "jenius-serif";
    font-weight: normal;
    font-style: italic;
    src: url('fonts/jenius-serif/JeniusSerif-Italic.otf') format("opentype");
}
@font-face {
    font-family: "jenius-serif";
    font-weight: 600;
    src: url('fonts/jenius-serif/JeniusSerif-SemiBold.otf') format("opentype");
}
@font-face {
    font-family: "jenius-serif";
    font-weight: 600;
    font-style: italic;
    src: url('fonts/jenius-serif/JeniusSerif-SemiBoldItalic.otf') format("opentype");
}
@font-face {
    font-family: "jenius-serif";
    font-weight: 700;
    src: url('fonts/jenius-serif/JeniusSerif-Bold.otf') format("opentype");
}
@font-face {
    font-family: "jenius-serif";
    font-weight: 700;
    font-style: italic;
    src: url('fonts/jenius-serif/JeniusSerif-BoldItalic.otf') format("opentype");
}

// Rebranding font definitions for body 
@include font-face('jenius-sans', 'fonts/jenius-sans/Jenius Sans - Light', 300, $file_formats: ttf);
@include font-face('jenius-sans', 'fonts/jenius-sans/Jenius Sans - Regular', normal, $file_formats: ttf);
@include font-face('jenius-sans', 'fonts/jenius-sans/Jenius Sans - Bold', 700, $file_formats: ttf);
@include font-face('jenius-sans', 'fonts/jenius-sans/Jenius Sans - Light Italic', 300, italic, $file_formats: ttf);
@include font-face('jenius-sans', 'fonts/jenius-sans/Jenius Sans - Regular Italic', 400, italic, $file_formats: ttf);
@include font-face('jenius-sans', 'fonts/jenius-sans/Jenius Sans - Bold Italic', 700, italic, $file_formats: ttf);

// Global font override for rebranding

@mixin font-jenius-sans {
    font-family: 'jenius-sans' !important;
    letter-spacing: 0.5pt;
}

@mixin font-jenius-serif {
    font-family: 'jenius-serif' !important;
    font-weight: bold;
}

    // body, div, p, ul li span, td {
    //     &:not(.fa) {
    //         @include font-jenius-sans;
    //     }
    // }

h1,
h2,
h3
th {
    @include font-jenius-serif;
    & * {
        @include font-jenius-serif;
        color: $accent-300;
    }
}