footer {
	&.site-footer {
	    // padding-bottom: 70px;

	    &.small-content {
	    	position: absolute;
	    	left: 0;
	    	right: 0;
	    	bottom: 0;
	    }

		@include breakpoint(mobile) {
			padding-bottom: 0;
		}

		&.footer-iframe-tabungan {
			@include breakpoint(mobile) {
				  margin-top: -2.1rem;
			}
		}

		.footer-top {
			position: relative;
			padding: 15px 0;
			background: $accent-300;

			@media screen and(max-width: 1200px) {
				padding: 15px 0;
			}

			@include breakpoint(mobile) {
				padding: 15px 0 30px;
			}
			ul {
				padding: 0;
				margin: 0 15px;
				display: block;

				@include breakpoint(mobile) {
					text-align: center;
				}

				&.social-media-wrap {
					z-index: 9;
					display: block;
				    min-width: 400px;
				    text-align: center;
				    position: absolute;
				    bottom: 130%;
			    	left: -5px;
				    background: $accent-300;
				    margin: 0;
				    padding: 20px 10px;
				    visibility: hidden;
				    opacity: 0;
				    transition: all .3s ease-in-out;

				    @include breakpoint(tablet) {
				    	min-width: 295px;
					    bottom: 175%;
					    left: initial;
					    right: -20px;
				    }

					li {
						display: inline-block;
						float: none;
    					margin-right: 0;
    					text-align: left;
    					margin: 0 10px;
    					top: 0;

    					@include breakpoint(tablet) {
    						margin: 0 5px;
    					}


    					i {
    						color: $white;
						    font-size: 2.2em;
						    transition: all .3s ease-in-out;

						    @include breakpoint(tablet) {
						    	// position: absolute;
							    // top: 50%;
							    // left: 0;
							    // right: 0;
							    // transform: translateY(-50%);
							    font-size: 1.5em;
						    }
    					}
					}

					a {
						text-align: center;
						width: 50px;
						height: 50px;
						border: 1px solid $white;
						border-radius: 100%;
						overflow: hidden;
						transition: all .3s ease-in-out;
						display: flex;
						align-items: center;
						justify-content: center;

						@include breakpoint(tablet) {
							height: 40px;
							width: 40px;
						}

						&:hover {
							border: 1px solid $tertiary-100;
							background: $tertiary-100;

							i {
								color: $accent-300;
							}
						}
					}


					&.open {
						visibility: visible;
						opacity: 1;
					}

					&:after {
						position: absolute;
					    left: 22px;
					    bottom: -16px;
					    height: 0;
					    width: 0;
					    border-left: 16px solid transparent;
					    border-right: 16px solid transparent;
					    border-top: 16px solid $accent-300;
					    content: '';

					    @include breakpoint(tablet) {
					    	left: initial;
    						right: 34px;
					    }
					}
				}

			}

			li {
				position: relative;
				list-style: none;
				float: left;
				margin-right: 30px;
				text-align: center;

				&.open-media {
					span {
						margin-top: 5px;
					}
				}

				@include breakpoint(mobile) {
					margin: 0 15px;
					float: none;
				    text-align: center;
				    display: inline-block;
					vertical-align: top;
					width:15%;
				}
				>a {
					text-decoration: none;

					span {
						display: block;
						font-family: 'jenius-sans';
						font-size: 0.750em;
						color: $tertiary-50;
						margin-top: 5px;
					}

					strong {
						color: $tertiary-300;
						font-size: 0.9em;
					}

					img {
						transition: all .3s ease-in-out;

						@include breakpoint(mobile) {
							width: 35px;
						}
					}

					&:hover {
						img {
							filter: contrast(2);
							// opacity: .5;
						}
					}
				}

				&:first-child {
					@include breakpoint(mobile) {
						position: relative;
    					top: 0;
						margin-left: 0;
						width:15%;

					}
				}

				&:last-child {
					@include breakpoint(mobile) {
						margin-right: 0;
					}
				}

			}
		}
		.footer-bottom {
			//position: fixed;
		    left: 0;
		    right: 0;
		    bottom: 0;
			background: $neutral-75;
			padding: 20px 0;
			z-index: 9;

			@include breakpoint(tablet) {
				position: relative;
			}

			ul.sitemap-wrapper {
				padding: 0;
				margin: 0;
				display: block;
				@include breakpoint(mobile) {
					display: flex;
					align-items: center;
					justify-content: space-between;
					> li {
						flex: 1;
						text-align: center;
					}
				}
				@include breakpoint(tablet) {
					margin-bottom: 1rem;
				}

			}

			li {
				position: relative;
				list-style: none;
				display: inline-block;
				padding: 0 10px;

				&:after {
					position: absolute;
				    content: "";
				    right: -3px;
				    top: 50%;
				    height: 1rem;
					width: 1px;
					background: $accent-300;
					transform: translateY(-50%);

					@include breakpoint(mobile) {
						right: -1px;
					}
				}

				&:first-child {
					float: left;
					padding-left: 0;
				}

				@include breakpoint(mobile) {

					&:last-child {
						float: right;
						padding-right: 0;
					}
				}

				&:last-child {
					&:after {
						display: none;
					}
				}


			}

			a {
				position: relative;
				color: $accent-300;
				font-family: 'jenius-sans';
				font-size: 0.9em;
				text-decoration: none;

				@include breakpoint(mobile) {
					font-size: 0.8em;
				}

				&:before {
					position: absolute;
					content: "";
					left: 0;
					right: 0;
					bottom: -5px;
					width: 0;
					height: 1px;
					//background: #9a8f83;
					transition: all .3s ease-in-out;
				}

				&:hover {
					&:before {
						width: 100%;
					}
				}
			}

			p {
				font-family: 'jenius-sans';
				color: $accent-300;
				font-size: 0.9em;
				padding: 0;
				margin:0;

				@include breakpoint(mobile) {
					font-size: 0.8em;
				    padding: 0;
				}
			}
			.site-brand {
				text-align: right;
				padding-left: .5rem;

    			img {
    				position: relative;
					object-fit: contain;
    				top: -5px;
    				height:34px;

    				@include breakpoint(mobile) {
    					top: 22px;
    				}
    			}

			}
		}

	}
}
