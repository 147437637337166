 @import "bourbon";

/* ABSTRACTS */
@import
    '../Abstracts/variables',
    '../Abstracts/functions',
	'../Abstracts/mixins',
	'../Base/fonts',
	'../Base/fonts-icon';

.content-area {
    &.content-detail-business {
        .category-wrap {

            @include breakpoint(mobile) {
                margin: 20px 0;
            }
            p {
                line-height: 1;
            }
        }

        .heading {
            @include breakpoint(mobile) {
                margin-bottom: 20px;
            }
        }

        .template-area {
            margin-bottom: 60px;

            @include breakpoint(mobile) {
                margin-bottom: 20px;
            }

            .center {
                text-align: center;

                @include breakpoint(mobile) {
                    text-align: left;
                }
            }

            .body-text-area {
                @include breakpoint(mobile) {
                    margin: 20px 0 0;
                }
            }

           
        }
        .template-area-heading {
            margin-bottom: 30px;

            @include breakpoint(mobile) {
                margin-bottom: 0;
            }
        }
        .img-list-area {
            @include breakpoint(mobile) {
                padding-bottom: 25px;
            }
            ul {
                display: block;
                padding: 0;
                margin: 0;
                text-align: center;

                >li {
                    height: 150px;
                    width: 250px;
                    overflow: hidden;
                    list-style: none;
                    display: inline-block;

                    @include breakpoint(mobile) {
                        height: 80px;
                        width: 115px;
                    }

                    &:first-child {
                        margin-right: 50px;
                    }

                    &.offset {
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: auto;
                        top: 80px;
                    }
                }
            }
        }

        .table-area {
            
        }


        .info-area {
            margin-top: 30px;
            clear: both;

            @include breakpoint(mobile) {
                margin-top: 0;
            }

            img {
                margin-right: 10px;

                @include breakpoint(tablet) {
                    display: block;
                    margin: 0 auto 10px;
                }
            }
            strong {
                font-family: 'jenius-sans';
                color: $accent-300;
                font-size: 1.5em;
                font-weight: 600;

                 @include breakpoint(tablet) {
                    display: block;
                }
            }

            .heading {
                h3 {
                    @include breakpoint(mobile) {
                        line-height: 1.5;
                    }
                }
            }
        }
    }
}

.form-area-business {
    position: relative;
    padding: 100px 0;
    background-image: url(../img/bg-contact.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-attachment: fixed;
    color: $neutral-300;

    .heading h2, .check.text p {
        color: $neutral-300 !important;
    }

    .check.text a {
        font-family: 'jenius-sans';
        font-weight: normal;
        color: $accent-300 !important;
    }
    

    @include breakpoint(mobile) {
        padding: 30px 0;
        background-size: cover;
        background-position: center;
    }

    &:before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.8;
        background-color: $white;
        backdrop-filter: brightness(1.5) contrast(1.2);
        
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6b5c49', endColorstr='#6b5c49', GradientType=0 );

        // @include breakpoint(mobile) {
        //     position: absolute;
        //     content: "";
        //     left: 0;
        //     right: 0;
        //     top: 0;
        //     bottom: 0;
        //     opacity: 0.8;
        //     background-color: $accent-300;
        //     background: -moz-linear-gradient(top, $accent-300 0%, $accent-300 100%);
        //     background: -webkit-gradient(left top, left bottom, color-stop(0%, $accent-300), color-stop(100%, $accent-300));
        //     background: -webkit-linear-gradient(top, $accent-300 0%, $accent-300 100%);
        //     background: -o-linear-gradient(top, $accent-300 0%, $accent-300 100%);
        //     background: -ms-linear-gradient(top, $accent-300 0%, $accent-300 100%);
        //     background: linear-gradient(to bottom, $accent-300 0%, $accent-300 100%);
        //     // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6b5c49', endColorstr='#6b5c49', GradientType=0 );
        // }
    }

    &.form-area-about {
        position: relative;
        padding: 100px 0;
        background-image: url(../img/bg-contact2.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 0 100%;
        background-attachment: fixed;

        @include breakpoint(mobile) {
            padding: 30px 0;
            background-size: cover;
            background-position: center;
        }
    }

    .body-text-1 {
        p {
            width: 45%;
            margin: auto;
            color: $neutral-300;

            @include breakpoint(mobile) {
                width: 100%;
                line-height: 1.5;
            }
        }
    }

    .form-area {
        margin: 30px 0 0;
        &.form-area-white {
            label {
                color: $white;
            }
        }
        .form-group {
            height: 50px;

            @include breakpoint(mobile) {
                height: 85px;
                margin-bottom: 0;
            }

            &.remember {
                height: auto;
            }
        }
        label {
            
            &.error {
                display: inline-block;
                color: $white;
                position: relative;
                top: 5px;
                width: 100%;
                opacity: 0;
                visibility: hidden;
                transition: all .3s ease-in-out; 

                &.show-error {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
        .col-md-6 {
            margin-bottom: 30px;

            @include breakpoint(mobile) {
                margin-bottom: 0;
            }
        }
        .form-control {

            color: $neutral-400;

            &::-webkit-input-placeholder { 
                font-family: 'jenius-sans';
                color: rgba(45,45,45,0.4);
                font-size: 1em;

                @include breakpoint(mobile) {
                    font-size: 0.8em;
                }
            }
            &::-moz-placeholder { 
                font-family: 'jenius-sans';
                color: rgba(45,45,45,0.4);
                font-size: 1em;

                @include breakpoint(mobile) {
                    font-size: 0.8em;
                }
            }
            &::-ms-input-placeholder { 
                font-family: 'jenius-sans';
                color: rgba(45,45,45,0.4);
                font-size: 1em;

                @include breakpoint(mobile) {
                    font-size: 0.8em;
                }
            }
            &::-moz-placeholder { 
                font-family: 'jenius-sans';
                color: rgba(45,45,45,0.4);
                font-size: 1em;

                @include breakpoint(mobile) {
                    font-size: 0.8em;
                }
            }
        }

        .jcf-select {
            width: 100%;
            cursor: pointer;
            background: none;
            border: none;
            float: left;
            font-family: 'jenius-sans';
            font-size: 0.9em;
            margin-bottom: 0;
            color: $neutral-200;
            text-align: left;
            height: 50px;
            

            &.jcf-drop-active {
                .jcf-select-text {
                    &:after {
                        background: url(../img/sprite-arrow-brown.png);
                        background-repeat: no-repeat;
                        background-size: 35px;
                        background-position: -20px 5px;
                    }
                }
            }
        }

        .jcf-select-opener {
            background: none;
        }
        .jcf-select-text {
            background: $white;
            cursor: pointer;
            position: relative;
            margin: 0;
            padding: 11px 22px;
            border: 1px solid #d5d5d5;
            font-family: 'jenius-sans';
            color: $neutral-75;
            font-size: 1.2em;
            padding-left: 60px;
            border-radius: 3px;

            span {
                color: $neutral-400;
            }

            &:after {
                position: absolute;
                content: "";
                right: 10px;
                top: 17px;
                height: 14px;
                width: 20px;
                background: url(../img/sprite-arrow-brown.png);
                background-repeat: no-repeat;
                background-size: 35px;
                background-position: 0 5px;
                // transition: all .3s ease-in-out;
            }
        }

        .jcf-select-drop {
            padding-left: 50px;
        }

        .find-wrap {f
            p {
                font-family: 'jenius-sans';
                width: 100%;
                font-size: 1em;
                font-weight: lighter;
                letter-spacing: 1px;

                a {
                    text-decoration: none;
                    color: $accent-300;
                }
            }
        }

        .contact {
            margin-top: 20px;
            p {
                width: 100%;
            }
        }

        .btn-area {
            .btn-secondary {
                @include breakpoint(mobile) {
                    width: 40%;
                    background: #fbb832;
                    box-shadow: 0px 0px 22px 0px #fbb832;
                }
            }
        }
    }
}

.video-wrap {
    .slick-slide-share {
        &.full {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 93vh;
            padding: 40px;

            @-moz-document url-prefix() { 
                padding: 70px;
            }

            // @media screen and(max-width: 1366px) {
            //     height: 83vh;
            // }
        }
    }
    &.slide-share {
        .slick-content {
            height: 420px;
            overflow: hidden;
            background: $white;
            border: 1px solid #e1e1e1;

            img {
                width: inherit;
                height: inherit;
            }

            &.full-wrapper {
                height: 100%;

                img {
                    width: 80%;
                }
            }

            @include breakpoint(mobile) {
                height: 195px;
            }

            img {
                // height: 100%;

                &.zoom {
                    min-height: 200%;
                }
            }

            &.scroll {
                overflow: auto;
                position: relative;
                z-index: 1;
            }
        }
        .current-no {
            &.current {
                background: none;
                border: none;
                width: 40px;
            }
        }

        .slick-no {
            position: relative;
            right: 100px;

            @include breakpoint(mobile) {
                right: 5px;
            }
            .fullscr {
                width: 100%;
                position: relative;
                opacity: 1;

                @include breakpoint(mobile) {
                    right: 0;
                    width: 35px;
                    margin-top: 5px;
                }
            }    
        }
        

        .slick-bottom {
            width: 100% !important;
            height: 60px;
            position: relative;
            line-height: 1;
            overflow: hidden;
            padding-top: 12px;

        }

        .length-wrap {
            width: 100%;
            background: #4d4d4d;
            height: 8px;
            position: absolute;
            top: -8px;
        }

        .length {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            background: #007ab8;
            height: 8px;
            margin: 0;
            z-index: 9;
        }

        .zoom-inout {
            position: absolute;
            right: 0;
            top: 50%;
            right: 20%;
            transform: translateY(-50%);

            @include breakpoint(mobile) {
                right: 15%;
            }

            a {
                display: inline-block;
                margin: 0 5px;
            }

            img {
                width: 30px;

                @include breakpoint(mobile) {
                    width: 20px;
                }
            }
        }

        .zoom {
            &.active {
                img {
                    opacity: .3;
                }
            }
        }

        .slick-controls {
            text-align: center;
            padding-top: 10px;
            margin: auto;

            img {
                opacity: 1;
            }
        }
        
        .item {
            display: inline-block;
            font-family: 'jenius-sans';
            font-weight: 600;
            font-style: italic;

            &.active {
                img {
                    opacity: .3;
                }
            }

        }    
    }

}

#googdoc {
    .ndfHFb-c4YZDc-cYSp0e-DARUcf {
        display: none;
    }
}

