@font-face {
    font-family: "icomoon";
    src: url("fonts/icomoon/icomoon.eot");
    src: url("fonts/icomoon/icomoon.eot") format("embedded-opentype"),
        url("fonts/icomoon/icomoon.ttf") format("truetype"),
        url("fonts/icomoon/icomoon.woff") format("woff"),
        url("fonts/icomoon/icomoon.svg") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@mixin font-icomoon {
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa.fa-twitter,
.icomoon.icomoon-x,
.icomoon.icomoon-twitter {
    @include font-icomoon;

    &:before {
        content: "\e901";
    }
}

.icomoon.icomoon-download {
    @include font-icomoon;

    &:before {
        content: "\e900";
    }
}

.icomoon.icomoon-contact {
    @include font-icomoon;

    &:before {
        content: "\e902";
    }
}

.icomoon.icomoon-search {
    @include font-icomoon;

    &:before {
        content: "\e903";
    }
}


.icomoon.icomoon-close {
    @include font-icomoon;

    &:before {
        content: "\e904";
    }
}


.icomoon.icomoon-eye {
    @include font-icomoon;

    &:before {
        content: "\e905";
    }
}

.icomoon.icomoon-lock-circle {
    @include font-icomoon;

    &:before {
        content: "\e906";
    }
}