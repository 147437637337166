 @import "bourbon";

/* ABSTRACTS */
@import
    '../Abstracts/variables',
    '../Abstracts/functions',
	'../Abstracts/mixins',
	'../Base/fonts',
	'../Base/fonts-icon';
.block-success {
	padding: 50px 0 0;
}
.block-about {
	//margin-top: 30px;
	@include breakpoint(wide) {
		padding: 50px 0 0;

	}

	@include breakpoint(mobile) {
		//padding: 30px 0 0;
		margin-top: 30px;
	}

	.sahabatdaya-thumb {
		.plyr--ready {
			@include breakpoint(mobile) {
				width: 100%;
			}

		}

		.iphone-video-daya {
			@include breakpoint(mobile) {
				height: 170px;
			}
		}

		.plyr {
			&.plyr--fullscreen-active {
				@include breakpoint(mobile) {
					height: auto;
				}
			}
		}

		.plyr__video-wrapper {
			@include breakpoint(mobile) {
				height: 100%;
			}
		}
	}

	.heading {
		h2 {
			@include breakpoint(mobile) {
    			margin: 0 auto 15px;
			}
		}
	}
	.body-text {
		margin-bottom: 50px;

		@include breakpoint(mobile) {
			margin-bottom: 30px;
		}
	}
	.body-text-1 {
		p {
			@include breakpoint(mobile) {
				line-height: 1.5;
			}
		}
	}
	.box-area {
		margin: 50px 0;

		@include breakpoint(mobile) {
			margin: 50px 0 20px;
		}

		.box {

			@include breakpoint(mobile) {
				margin-bottom: 20px;
			}
		}

		.img-wrap {
			position: relative;
			height: 110px;
			overflow: hidden;

			img {
				// margin: auto;
				// position: absolute;
				// top: 50%;
				// left: 0;
				// right: 0;
				// transform: translateY(-50%);
				height: 110px;
			}
		}
		.subheading-7 {
			margin: 20px 0;
		}
		.body-text-1 {
			p {
				margin-bottom: 10px;

			}
		}

		.col-xs-6 {
			&:nth-child(3) {
				@include breakpoint(mobile) {
					clear: both;
				}
			}
		}
	}

	.accordion-area {
		margin-bottom: 50px;

		@include breakpoint(mobile) {
			margin-bottom: 35px;
		}

		.panel-default {
			border-color: transparent;
			box-shadow: none;

		}

		.panel-body {
			border-top-color: transparent;
			border-top: transparent;
			background: $tertiary-50;
			padding-bottom: 100px;
			padding: 40px 50px;

			@include breakpoint(mobile) {
				padding: 15px;
			}

			p {
				margin-bottom: 20px;
			}

		}

		.panel-heading {
			background-color: transparent;
    		border-color: transparent;
    		border-bottom: 1px solid rgba(183, 170, 159, 0.50);

    		h4 {
    			@include font-jenius-serif;
    			
    			a {
    				padding: 20px 10px;
    				display: block;
    				color: $accent-300;
    				font-size: 1.250em;
    				font-weight: normal;
    				text-decoration: none;

    				@include breakpoint(mobile) {
    					font-size: 1em;
    					padding: 10px;
    				}
    			}
    		}

    		&.active {
				border-bottom: 1px solid $tertiary-300;
				.icon {
					background: url(../img/icon-sprite-accordion.png) no-repeat;
					background-size: 65px;
					background-position: -35px 0;

					@include breakpoint(mobile) {
	    				margin-right: 20px;
					    height: 25px;
					    width: 25px;
					    background-size: 55px;
					    background-repeat: no-repeat;
					    background-position: -30px 0;
	    			}
				}

				h4 {
					a {
						color: $accent-300;
					}
				}
			}
		}

		.icon {
			position: relative;
			top: -3px;
			float: left;
			margin-right: 30px;
			height: 30px;
			width: 30px;
			background: url(../img/icon-sprite-accordion.png) no-repeat;
			background-size: 65px;
    		background-position: 0 0;

			@include breakpoint(mobile) {
				margin-right: 20px;
				height: 25px;
				width: 25px;
				background-size: 55px;
				background-position: 0 0;
			}
		}

		
	}

	.article-area {
		position: relative;
		height: 310px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		margin-top: 50px;

		@include breakpoint(tablet) {
			height: auto;
			margin-top: 0;
		}
		>.container {
			height: 310px;
			@include breakpoint(tablet) {
				height: auto;
			}
			.row {
				height: 310px;
				@include breakpoint(tablet) {
					height: auto;
				}
				.col-md-12 {
					height: 310px;
					@include breakpoint(tablet) {
						height: auto;
					}
				}
			}
		}

		.btn-area {
			@media screen and(max-width: 1030px) {
				margin-bottom: 0;
			}
			@include breakpoint(mobile) {
				text-align: center;
			}
			.btn-primary {
				@include breakpoint(mobile) {
					width: fit-content;
					padding: 8px 40px;
					font-size: 1em;
				}
			}
		}

		.obj {
			position: absolute;
			right: 0;
			top: 0;
			width: 550px;
			height: 310px;
			background-size: 100%;
    		background-position: 0 50px;
    		float: right;

    		&.obj-mobile {
    			position: relative;
			    width: 300px;
			    height: 210px;
			    margin: 30px auto 0;
			    background-position: 0 center;
			    float: none;
    		}

    		@media screen and(max-width: 1030px) {
    			display: none;
    		}
		}
		.text-area {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@media screen and(max-width: 1030px) {
				position: relative;
				padding: 50px 0;
				transform: initial;
				
			}

			@include breakpoint(mobile) {
				top: -20px;
				padding-bottom: 0;
				
			}
		}
	}

	.images-area {
		margin: 50px 60px;

		@include breakpoint(tablet) {
			margin: 30px 0;
		}

		.item-wrap {
			margin: 0 0 30px;

			@include breakpoint(mobile) {
				margin: 0 0 10px;
			}

			&:hover .vjs-big-play-button {
				opacity: 1;
				visibility: visible;
			}

			&:hover .vjs-poster {
				&:before {
					opacity: .5;
				}
			}

			&.big-video {
				position: relative;

				.plyr__video-wrapper {
				    width: 100%;
				    // height: 100%;
				    padding-bottom: 42%;
				}
				.plyr--fullscreen-active .plyr__video-wrapper {
					height: 100%;
					padding-bottom: 0;
				}
				video.plyr--setup {
					max-height: 100%;
				    position: absolute;
				    top: 0;
				    left: 0;
				    // z-index: 9;
				}

				.video-js {
					height: 425px;

					@include breakpoint(mobile) {
						height: 200px;
					}
				}
				.vjs-big-play-button {
					height: 115px;
					width: 115px;
					background-size: 100px;

					@include breakpoint(mobile) {
						height: 90px;
						width: 90px;
						background-size: 50px;
					}
					
				}

				&.active {
					&:before {
						background: none;
					}
				}
			}
			&.small-img {
				height: 275px;
				overflow: hidden;

				.img-wrap {
					height: 275px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					@include breakpoint(mobile) {
						height: 200px;
					}
				}

				@include breakpoint(mobile) {
					height: 200px;
				}
				
			}

			// .plyr audio, 
			// .plyr video {
			// 	height: 100%;
			// }

			.plyr--video {
				&:before {
					position: absolute;
					content: "";
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					background: #000;
					opacity: .4;
					// z-index: 1;
				}

				&.active {
					&:before {
						opacity: 0;
					}
				}
			}

			.plyr--video .plyr__controls {
				display: none;
				z-index: 91;
			}
		}

		.vjs-big-play-button {
			background: url(../img/icon-play2.png);
    		background-repeat: no-repeat;
    		margin: auto;
    		background-position: center;
		}

		.vjs-poster {
			cursor: pointer;
		}
	}
}