.block-slider {
	position: relative;
	// margin-top: 80px;

	@media screen and(max-width: 1030px) {
		margin-top: 60px;
	}

	@include breakpoint(tablet) {
		margin-top: 0;
	}

	ul {
		&.slider {
			padding: 0;
			margin: 0;
			display: block;
			overflow: hidden;

			.item {
				position: relative;
				height: 545px;
				list-style: none;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				z-index: 9;

				@include breakpoint(mobile) {
					height: 425px;
				}

				&:after {
					position: absolute;
					content: "";
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					// background: rgba(181,181,181,0);
					// background: -moz-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, #463521 100%);
					// background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(181,181,181,0)), color-stop(33%, rgba(181,181,181,0.23)), color-stop(100%, rgba(72,62,50,0.7)));
					// background: -webkit-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, #463521 100%);
					// background: -o-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, #463521 100%);
					// background: -ms-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, #463521 100%);
					// background: linear-gradient(to bottom, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, #463521 100%);
					// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5b5b5', endColorstr='#483e32', GradientType=0 );
					z-index: -1;

					@include breakpoint(mobile) {
						// background: rgba(181,181,181,0);
						// background: -moz-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
						// background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(181,181,181,0)), color-stop(33%, rgba(181,181,181,0.23)), color-stop(100%, rgba(72,62,50,0.7)));
						// background: -webkit-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
						// background: -o-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
						// background: -ms-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
						// background: linear-gradient(to bottom, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
						// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5b5b5', endColorstr='#483e32', GradientType=0 );
					}
				}

				.caption-area {
					position: relative;
					top: 50%;
					transform: translateY(-50%);

					@include breakpoint(mobile) {
						position: absolute;
					    transform: initial;
					    bottom: 25%;
					}
					h2 {
						@include font-jenius-serif;
						font-size: 2.250em;
						color: $accent-300;
						text-transform: uppercase;
						line-height: 1.3;

						@include breakpoint(mobile) {
							font-size: 1.8em;
						}
					}
					p {
						font-family: 'jenius-sans';
						height: 100px;
						font-size: 18px;
						color: #fff;
						width: 90%;
						line-height: 2;
						display: block;
					    overflow: hidden;
					    text-shadow:0px 0px 10px #000;
					    // height: 90px;
					}

					.row {
						width: 50%;

						@include breakpoint(mobile) {
							width: 100%;
						}
					}
				}
			}

			&.slider-single-page {
				.item {
					height: 418px;
					&:after {
						height: 418px;
					    // background: url(../img/shadow.png);
					    background-size: cover;
					    background-repeat: no-repeat;

					    @include breakpoint(mobile) {
					    	background: rgba(181,181,181,0);
							background: -moz-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
							background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(181,181,181,0)), color-stop(33%, rgba(181,181,181,0.23)), color-stop(100%, rgba(72,62,50,0.7)));
							background: -webkit-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
							background: -o-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
							background: -ms-linear-gradient(top, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
							background: linear-gradient(to bottom, rgba(181,181,181,0) 0%, rgba(181,181,181,0.23) 33%, rgba(14, 14, 14, 0.86) 100%);
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b5b5b5', endColorstr='#483e32', GradientType=0 );
					    }
					}
				}
			}

			.caption-area {
				h2 {
					color: $white;
					text-transform: capitalize;
				}

				.subheading-2 {
					width: 70%;

					@include breakpoint(mobile) {
						width: 100%;

						h3 {
							font-size: 1.1em;
						}
					}
				}
				.slick-dots {
					display: none;
				}
			}
		}
		&.slick-dots {
			bottom: 30px;
		    width: 1170px;
		    left: 15px;
		    right: 0;
		    margin: auto;

    		
    		@include breakpoint(mobile) {
    			width: 100%;
    			left: 15px;
    			right: 15px;
    		}
    		
			li {
				float: left;
				margin: 0 6px;
				background: $neutral-100;
				height: 15px;
				width: 15px;
				border-radius: 100%;
				z-index: 2;

				@include breakpoint(mobile) {
					height: 8px;
					width: 8px;
					float: left;

					&:first-child {
						margin-left: 0;
					}
				}

				&.slick-active {
					background: $white;
				}

				button {
					&:before {
						content: "";
					}
				}
			}
		}

		&.slider-mobile {
			.slick-dots {
				@include breakpoint(mobile) {
					display: none !important;
				}
			}
		}
	}
	.scroll-down {
		width: 100px;
    	margin: auto;
		position: absolute;
	    z-index: 1;
	    bottom: 25px;
	    left: 0;
	    right: 0;
	    text-align: center;
	    text-decoration: none;

	    &:hover img {
	    	transition: all .3s ease-in-out;
	    }

	    span {
	    	margin-top: 10px;
	    	display: block;
	    	color: $white;
	    	font-family: 'jenius-sans';
	    	font-size: 0.875em;
	    }
	    img {
	    	position: relative;
  			animation: arrow 1s infinite;
  			animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);

  			@include breakpoint(tablet) {
  				max-width: 20px;
  			}
	    }
	}


	@keyframes arrow {
		0% {
		    bottom: -9px;
		}
		50% {
		    bottom: -6px;
		}
		100% {
		    bottom: -9px;
		}
	}
}