.datepicker {
	table {
		tr {
			th {
				font-family: 'jenius-sans';
				font-weight: 600;
				color: $neutral-400;
			}
			td {
				font-family: 'jenius-sans';
				font-weight: 400;
				transition: all .3s ease-in-out;
				&.today {
					background-color: $accent-300;
					color: $white;
				}

				&.day {
					&:hover {
						background-color: $accent-300;
						color: $white;
					}	
				}

				&.old {
					color: #d2d2d2;
				}

				

				&.active {
					&.active {
						background-color: $accent-300;
						color: $white;
						transition: all .3s ease-in-out;

						&:focus,
						&:hover {
							background-color: $accent-300;
							color: $white;
						}
					}
				}
			}
		}
	}
} 