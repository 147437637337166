
.bundle-sahabat-daya {
	list-style: none;
	text-align: center;

	
	.sahabat-daya-list {
		width: 250px;
		margin: 0px 20px 20px;
		max-width: 200px;
		display: inline-block;
		vertical-align: top;

		.img-wrap {
		    position: relative;
		    height: 110px;
		    overflow: hidden;
		}

		.subheading-7 {
			margin: 20px 0px 10px;
    		min-height: 35px;
		}
	}
	
}