body {
    font-family: if(variable-exists(font_family), $font_default, 'Helvetica, Arial, sans-serif');
}

@each $value in $align {
    .text-#{$value} {
        text-align: #{$value} !important;
    }
}

@each $value in $vertical-align {
    .v-align-#{$value} {
        vertical-align: #{$value} !important;
    }
}

@each $value in $float {
    .float-#{$value} {
        float: #{$value} !important; 
    }
}


// OFFSET //
$offset: 20;

/* Width */
.width-full {
    width: 100% !important;
}

@for $i from 1 through $offset {
    .width-#{25 * $i} {
        width: 25px * $i !important;
    }
}

/* Margin */
.no-margin {
    margin: 0 !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}

.m-mb-1 {
    margin-bottom: 10px !important;
}

@for $i from 1 through $offset {
    .margin-#{5 * $i} {
        margin: 5px * $i !important;
    }

    @each $value in $position {
        .margin-#{$value}-#{5 * $i} {
            margin-#{$value}: 5px * $i !important;
        }
    }
}

/* Padding */
.no-padding {
    padding: 0 !important;
}

.row {
    padding: 0 !important;
}
.padding-0 {
    padding: 0 !important;
}
.padding-left-0 {
    padding-left: 0 !important;
}

.padding-right-0 {
    padding-right: 0 !important;
}

.m-p-0 {
    @include breakpoint (mobile) {
        padding: 0px !important;
    }
}
// width mobile only

.m-width-100 {
    @include breakpoint (mobile) {
        width: 100% !important;
    }
}
.m-width-80 {
    @include breakpoint(mobile) {
        width: 80% !important;
    }
}
.m-width-50 {
    @include breakpoint(mobile) {
        width: 50% !important;
    }
}

// width deskop only

.d-width-100 {
    @include breakpoint(wide) {
        width: 100% !important;
    }
}
.d-width-80 {
    @include breakpoint(mobile) {
        width: 80% !important;
    }
}



.float-0 {
    float: none !important;
}

.jcf-select-drop-content {
    border: none !important;
}

.jcf-focus {
    border-color: transparent !important;
}

.columns-default {
    @include breakpoint(mobile) {
        padding: 0 !important;
    }
}

img {
    max-width: 100%;
}

@for $i from 1 through $offset {
    .padding-#{5 * $i} {
        padding: 5px * $i !important;
    }

    @each $value in $position {
        .padding-#{$value}-#{5 * $i} {
            padding-#{$value}: 5px * $i !important;
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}



.row {

    &.row-0 {
        margin-left: 0;
        margin-right: 0;
        > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
        > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
        > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
        > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
        > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
        > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
        > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
        > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
        > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
        > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
        > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
        > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.row-5 {
        margin-left: -5px;
        margin-right: -5px;
        > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
        > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
        > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
        > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
        > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
        > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
        > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
        > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
        > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
        > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
        > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
        > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &.row-10 {
        margin-left: -10px;
        margin-right: -10px;
        > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
        > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
        > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
        > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
        > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
        > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
        > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
        > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
        > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
        > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
        > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
        > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &.row-relative {
        position: relative;
    }

    @include breakpoint(desktop) {

        &.row-inline {
            // font-size: 0px;
            > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
            > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
            > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
            > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
            > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
            > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
            > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
            > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
            > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
            > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
            > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
            > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
                display: inline-block;
                float: none;
                vertical-align: top;
            }
            &.middle {
                > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
                > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
                > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
                > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
                > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
                > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
                > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
                > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
                > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
                > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
                > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
                > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
                    vertical-align: middle;
                }
            }
            &.bottom {
                > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
                > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
                > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
                > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
                > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
                > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
                > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
                > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
                > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
                > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
                > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
                > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
                    vertical-align: bottom;
                }
            }
        }

        &.row-table {
            display: table;
            width: 100%;
            > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
            > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
            > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
            > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
            > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
            > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
            > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
            > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
            > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
            > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
            > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
            > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
                display: table-cell;
                float: none;
                vertical-align: top;
            }
        }

        &.row-0 {
            margin-left: 0;
            margin-right: 0;
            > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
            > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
            > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
            > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
            > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
            > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
            > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
            > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
            > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
            > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
            > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
            > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &.row-5 {
            margin-left: -5px;
            margin-right: -5px;
            > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
            > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
            > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
            > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
            > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
            > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
            > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
            > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
            > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
            > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
            > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
            > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        &.row-10 {
            margin-left: -10px;
            margin-right: -10px;
            > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
            > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
            > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
            > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
            > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
            > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
            > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
            > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
            > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
            > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
            > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
            > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        &.row-15 {
            margin-left: -15px;
            margin-right: -15px;
            > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, 
            > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, 
            > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, 
            > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, 
            > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, 
            > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, 
            > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, 
            > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, 
            > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, 
            > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, 
            > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, 
            > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.url {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
}

.small {
    font-size: 100%;
}