 @import "bourbon";

/* ABSTRACTS */
@import
    '../Abstracts/variables',
    '../Abstracts/functions',
	'../Abstracts/mixins',
	'../Base/fonts',
	'../Base/fonts-icon';

.block-article {
 	padding: 20px 0;

    @include breakpoint(tablet) {
        padding: 0;
    }

    .more-article {
        @include breakpoint(mobile) {
            border-top: 0;
        }
    }

    .thumbnails-person {
       li {
            height: auto;
        }

        &.thumbnails-person-question {
             li {
                // height: 300px;
                overflow: hidden;
                // min-height: 400px;

                @include breakpoint(mobile) {
                    min-height: auto;
                }
                 li {
                    height: auto;
                }
            }
        }
    }

    .heading-wrap {
        margin: 0 0 20px;
    }

    .view-wrap {
        margin: 0 0 30px;
        ul {
            display: block;
            padding: 0;
            margin: 0;
            &.view {
                >li {
                    display: inline-block;
                    margin-right: 20px;

                    @include breakpoint(mobile) {
                        margin-right: 3px;
                    }

                    
                }

                span {
                    font-size: 1em;
                    font-family: 'jenius-sans';
                    font-weight: lighter;
                    margin-left: 5px;

                    a {
                        text-decoration: none;
                        color: $neutral-200;
                        transition: all .3s ease-in-out;

                        &:hover {
                            color: $accent-300;
                        }
                    }

                    @include breakpoint(mobile) {
                        font-size: 0.9em;
                    }
                }
            }
            &.ratting {
                // float: right;
                >li {
                    display: inline-block;
                    margin-left: 10px;

                    &:first-child {
                        @include breakpoint(mobile) {
                            margin-left: 0;
                        }
                    }  
                }

                img {
                    max-width: 25px;

                    @include breakpoint(mobile) {
                        max-width: 20px;
                    }
                }

                span {
                    font-family: 'jenius-sans';
                    font-size: 0.9em;
                    color: #a6a6a6;
                    font-weight: lighter;
                    vertical-align: middle;
                    position: relative;
                    top: 2px;
                    margin-left: 2px;
                }
            }
        }
    }

    // .link-mobile-rating {
    //     display: none;
    //     @include breakpoint(mobile) {
    //         display: inline-block;
    //         position: absolute;
    //         right: 0;
    //         top: 0;
    //         margin-right:10px;
    //     }

    //     &.hide {
    //         @include breakpoint(mobile) {
    //             display: none;
    //         }
    //     }

    //     &.active {
    //         @include breakpoint(mobile) {
    //             display: block;
    //         }
    //     }

    // }

    // .block-rating-course {
    //     @include breakpoint(mobile) {
    //         visibility: hidden;
    //         opacity: 0;
    //         overflow: hidden;
    //         transition: all .3s ease-in-out;

    //         &.active {
    //             visibility: visible;
    //             opacity: 1;
    //         }
    //     }
    // }

    .ytb-wrap {
        .plyr__play-large {
            background: none;
        }
    }

    .load-more-reply {
        font-family: 'jenius-sans';
        font-size: 1.1em;
        color: #7c6a55;
        font-weight: normal;
        text-align: center;
        text-transform: capitalize;
    }


    .video-wrap {
        // height: 480px;
        overflow: hidden;

        .ytb-wrap {
            .plyr__play-large {
                display: none;
            }
        }

        &.slide-ppt {
            height: 550px;

            @include breakpoint(mobile) {
                height: auto;
            }

            iframe {
                height: 550px;
                width: 100%;

                @include breakpoint(mobile) {
                    height: 245px;
                }
            }
        }

        .plyr {
            video {
                max-height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .plyr__video-wrapper {
            width: 100%;
            // height: 100%;
            padding-bottom: 42%;
        }

        .plyr--fullscreen-active .plyr__video-wrapper {
            height: 100%;
            padding-bottom: 0;
        }

        &.slide-share {
            height: 480px;
            @include breakpoint(mobile) {
                height: auto;
            }
        }

        @include breakpoint(mobile) {
            height: auto;
        }
        .col-md-12 {
            @include breakpoint(mobile) {
                padding: 0;
            }
        }
        .plyr__play-large {
            background: url(../img/icon-play.png) no-repeat;
            border-radius: 0;
            box-shadow: none;

            @include breakpoint(mobile) {
                background-size: 35px;
            }
        }

        .plyr__controls {
            opacity: 0;
            padding: 10px;

            &.active {
                opacity: 1;
            }
        }

        // &:hover {
        //     .plyr__controls {
        //         opacity: 1;
        //     }
        // }
    }
    .plyr__video-wrapper {
        // height: 480px;


        // @include breakpoint(mobile) {
        //     height: 200px;
            
        // }


    }

    .plyr--ready {
        position: relative;

        &:after {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #000;
            opacity: .3;
            pointer-events: none;
        }

        &.plyr--playing {
            &:after {
                opacity: 0;
            }
        }

        // &.isIphone {
        //     &:after {
        //         z-index: -1;
        //     }
        // }
    }

    .download-wrap {
        margin: 30px 0 0;

        @include breakpoint(mobile) {
            text-align: center;
        }
        .subheading-3 {
            h3 {
                margin-top: 7px;

                @include breakpoint(mobile) {
                    margin-top: 0;
                    margin-bottom: 10px;
                    line-height: 2;
                }
            }
        }

        .btn-area {

            &.no-margin {
                margin: 0;

                &.right {
                    float: right;

                    @include breakpoint(mobile) {
                        float: none;
                    }
                }
            }
        }

        .btn-secondary {
            @include breakpoint(mobile) {
                width: auto;
                display: inline-block;
                padding: 8px 20px;
                font-size: 0.9em;
            }
        }
    }

    .content-wrap {
        margin-bottom: 30px;
    }

    .other-article {
        margin-top: 50px;
    }

    &.block-profile {
        padding-bottom: 0;

        @include breakpoint(mobile) {
            margin-bottom: 30px;
        }

        .heading {
            @include breakpoint(mobile) {
                text-align: center;
            }
        }

        .subheading-4 {
            h3 {
                font-size: 2.625em;
            }
        }

        .img-wrap {
            display: block;

            @include breakpoint(mobile) {
                margin-bottom: 30px;
            }
            
            img {
                // min-width: 165%;
                min-width: 125%;
                position: relative;
                // left: -15%;

                max-height: 320px;
                object-fit: contain;

                @include breakpoint(tablet) {
                    min-width: 100%;
                    left: 0;
                    margin: auto;
                    max-height: auto;
                }
            }
            
        }

        .question-area {
            margin-top: 0px;
        }

        .text-area {
            .btn-primary {
                @include breakpoint(mobile){
                    font-size: 1em;
                }
            }
            &.text-area-profile {
                margin-left: 80px;

                @include breakpoint(mobile) {
                    margin-left: 0;
                }

                .subheading-4,
                .body-text-1 {
                    @include breakpoint(mobile) {
                        text-align: center;
                    }
                }
            }
        }
    }

    &.block-article-detail {
        margin-top: 30px;
    }

    .body-text-detail {
        // padding-left: 50px;

        @include breakpoint(mobile) {
            padding-left: 0;
        }
    }
}

.sidebar-profile {
    position: absolute;
    right: 20%;
    top: 95px;
    background: $white;
    width: 14%;
    padding: 20px 20px 0;
    box-shadow: 0px 0px 15px -2px rgba(224,224,224,1);
    z-index: 9;

    @media screen and (max-width: 1366px) {
        right: 8%;
        width: 20%;  
    }

    @include breakpoint(tablet) {
        position: relative;
        right: 0;
        top: 0;
        width: 90%;
        box-shadow: none;
        border-left: 4px solid $accent-300;
        margin-left: 15px;
        margin-right: 20px;
        padding-left: 20px;
        padding-top: 0;
        margin-bottom: 30px;

    }

    .thumbnails-person {

        @include breakpoint(mobile) {
            padding-bottom: 30px;
        }

        .body-text-1 {
            p {
                @include breakpoint(mobile) {
                    font-size: 0.9em;
                }
            }
        }
        .btn-area {

            @include breakpoint(mobile) {
                margin-top: 0;
                text-align: left;
            }
            img {
                @include breakpoint(mobile) {
                    min-width: initial;
                }    
            }
            .btn-fourthary {
                @include breakpoint(mobile) {
                    font-size: 0.9em;
                }
            }
        }

        img {
            @include breakpoint(mobile) {
                min-width: 125%;
            }
        }

        .subheading-3 {
            @include breakpoint(mobile) {
                margin-bottom: 30px;
            }
        }

        .subheading-4 {
            h3 {
                @include breakpoint(mobile) {
                    font-size: 1.3em;
                    line-height: 1.6;
                }
            }
        }

        ul {
            li {
                text-align: center;
            }

            img {
                max-height: 185px;
            }
        }
    }

    .thumbnails-article {
        article {
            &.post-thumbnails {
                &:last-child {
                    border-bottom: 1px solid #e8e8e8;
                }
            }
        }
    }
}

.text-wrap {
    &.body-text-detail {
       .body-text-1 {
            p {
                margin: 20px 0;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

       
    }

    &.left-text-detail {
        position: relative;
        .item-left-detail {
            margin-bottom: 40px;
            @include breakpoint(mobile) {
                margin-bottom: 10px;
                margin-top: 15px;
            }
            .img-wrap {
                margin: 20px 0;
            }
        }
        &.border-bottom {
            @include breakpoint(mobile) {
                border-bottom: 1px solid rgba(113, 113, 113, 0.20);
                padding-bottom: 10px;
                margin-bottom: 15px;
            }
        }

        @include breakpoint(mobile) {
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
    }
}

.ratting-daya{
    @include breakpoint(mobile){
        position: absolute;
        top: 62px;
        right: 9px;
    }
.pasar-ratting{
    @include breakpoint(mobile){
    position:absolute;
    top:0px;
    right:7px;
    }
}
}
.ratting-detail{
    @include breakpoint(mobile){
        position:absolute;
        top:0;
        right:0;
        margin-top:0px;
  
    }

.article-ratting{
    @include breakpoint(mobile){
        position:absolute;
        top:20px;
        right:7px;
    }
}
}
.ratting-sub{
    @include breakpoint(mobile){
        margin-left:30px;
    }
}
.pasar-ratting{
    @include breakpoint(mobile){
        display: inline-block;
        position: absolute;
        top: 84px;
        margin-right: 10px;
    }
}
.more-article {
    padding: 30px 0 0;
    border-top: 1px solid rgba(139, 127, 125, 0.30);

    &.no-border {
        border-top: 0;

        &.border-mobile {
            @include breakpoint(mobile) {
                margin: 30px 0 0;
                border-top: 1px solid rgba(139, 127, 125, 0.30);
            }
        }
    }

    &.more-article-left {
        border-top: none;
        article {
            &.post-thumbnails {
                border-bottom: 1px solid #f0f0f0;
                padding-bottom: 15px;
                margin-bottom: 15px;

                &:last-child {
                    border-bottom: none;
                }
                .text-wrap {
                    padding-left: 0;
                    h3 {
                        font-size: 1em;
                    }
                }
            }
        }
    }
    article {
        &.post-thumbnails {
            .text-wrap {
                padding-left: 30px;
                h4 {
                    text-transform: uppercase;
                }
            }
        }
    }
}

video.plyr--setup {
    width: 100%;
    object-fit: contain;

    @include breakpoint(mobile) {
        object-fit: contain;
    }
}

.plyr__play-large {
    background: none;
    color: transparent;
    background: url(../img/icon-play2.png) no-repeat;
    height: 100px;
    width: 100px;
    background-size: cover;
    background-position: center;
    visibility: initial !important;

    @include breakpoint(mobile) {
        height: 50px;
        width: 50px;
    }

    svg {
        display: none;
    }
}

.media-area {
    // margin: 100px 0 60px;
    margin: 60px 0 80px;

    @include breakpoint(mobile) {
        margin: 40px 0;
    }
    .share {
        float: left;
    }
    ul {
        padding: 0;
        margin: 0;
        margin-left: 50px;
        display: block;
        // visibility: hidden;
        // opacity: 0;
        // transition: all .3s ease-in-out;

        @include breakpoint(mobile) {
            margin-left: 0;
            opacity: 1;
            visibility: visible;
        }
        >li {
            display: inline-block;
            margin: 0 8px;

            >a {
                position: relative;
                display: inline-block;
            }

            span {
                position: absolute;
                top: -30px;
                left: -15px;
                right: 0;
                font-size: 0.8em;
                background: #2d2d2d;
                width: 65px;
                text-align: center;
                padding: 3px;
                color: #fff;
                font-family: 'jenius-sans';
                border-radius: 3px;

                @include breakpoint(mobile) {
                    left: 0;
                    margin: auto;
                }

                &:after {
                    position: absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    bottom: -6px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    border-color: transparent;
                    border-style: solid;
                    border-width: 8px 8px 0px 8px;
                    border-top-color: #2d2d2d;
                    border-left-color: rgba(255,165,0,0);
                    border-right-color: rgba(255,165,0,0);
                }
            }

            @include breakpoint(mobile) {
                margin: 0 3px 10px;
                width: 85px;
            }
        }

        &.show {
            visibility: visible;
            opacity: 1;
        }
    }
}

.block-left-detail-mobile {
    border-left: 2px solid $accent-300;
    padding-left: 15px;

    .left-text-detail {
        @include breakpoint(mobile) {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}

.url-wrap {
    margin-top: 50px;

    @include breakpoint(mobile) {
        margin-top: 30px;
    }
    
    p {
        font-family: 'jenius-sans';
        font-size: 1.125em;
        font-weight: 300;
        color: $neutral-400;
        font-style: italic;
        margin: 0;
    }
}