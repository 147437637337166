.breadcrumbs {
	position: relative;
	padding: 30px 0 20px;
    z-index: 9;

	@include breakpoint(mobile) {
		padding: 20px 0;
		
	}

	ul {
		display: block;
		padding: 0;
		margin: 0;
		

		a {
			font-size: 1em;
			text-decoration: none;
			font-family: 'jenius-sans';
			color: $accent-300;
			font-weight: lighter;
			font-weight: normal;
		}
	}

	li {
		position: relative;
		list-style: none;
		display: inline-block;
		font-family: 'jenius-sans';
		font-size: 0.875em;
		font-weight: bold;
		padding-right: 15px;
		color: $accent-300;
		&:after {
			position: absolute;
			content: ">";
			right: 0;
			top: 0;
		}
		&:last-child {
			padding-left: 2px;
			&:after {
				content: "";
			}
		}
		
	}

	&.white-text {
		padding: 50px 0 20px;
		li {
			color: $tertiary-300;
			>a {
				color: $tertiary-300;
			}
		}

		@include breakpoint(mobile) {
			padding: 10px 0;
		}
	}

	&.breadcrumbs-pull {
		padding: 0;
		margin: 0;
		@media screen and(max-width: 1030px) {
			margin: 60px 0 5px;
		}
	}

	&.inner {
		position: absolute;
		left: 15px;
		top: 0;
	}
}

.breadcrumbs-push {

	@media screen and(max-width: 1030px) {
		margin-top: 70px;
		margin-bottom: 10px;
	}
}

.breadcrumbs-push-product {
	margin: 20px 0 40px;
}

.link-back-wrap {
	display: block;
	margin: 20px 0;

	a {
		font-family: 'jenius-sans';
		font-size: 1em;
		color: $accent-300;
	}

	img {
		width: 8px;
	    margin-right: 10px;
	    position: relative;
	    top: -1px;
	}
}

.desktop-view {
	//margin-top: 70px;
}

.mobile-view {
	//margin-top: 70px;
}
.desktop-view-breadcrumb {
	margin-top: 35px;
}
.mobile-view-breadcrumb {
	margin-top: 60px;
}