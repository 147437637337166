
.block-accordion {
	padding: 40px 0;

	@include breakpoint(mobile) {
		padding: 10px 0;
	}
	.panel {
		box-shadow: none;
	}
	
	.panel-default {
		border-color: initial;
		border: none;

		>.panel-heading {
			position: relative;
			border-color: initial;
			background-color: initial;
			border-bottom: 1px solid #dbd4ce;
			padding: 0;
			

			&:after {
				position: absolute;
				content: "";
				right: 0;
				top: 50%;
				background: url(../img/icon-sprite-accordion-plus.png) no-repeat;
				background-position: 0 0;
				background-size: 60px;
				height: 30px;
				width: 30px;
				transform: translateY(-50%);

				@include breakpoint(mobile) {
					background: url(../img/icon-sprite-accordion-orange.png) no-repeat;
					background-size: 45px;
					background-position: 0 0;
					height: 22px;
					width: 22px;
					right: initial;
					left: 22px;
					top: 23px;
					transform: initial;
				}
			}

			&.active {
				&:after {
					background: url(../img/icon-sprite-accordion-plus.png) no-repeat;
					background-position: -32px 0;
					background-size: 60px;
					height: 30px;
					width: 30px;
					transform: translateY(-50%);

					@include breakpoint(mobile) {
						background: url(../img/icon-sprite-accordion-orange.png);
						background-size: 45px;
						background-position: -22px 0;
						height: 22px;
						width: 22px;
						right: initial;
						left: 22px;
						transform: initial;
					}
				}
			}

			&+.panel-collapse {
				>.panel-body {
					border-top: none;
				}
			}

			h4 {
				@include font-jenius-serif;
				color: $accent-300;
				font-size: 1.313em;
				font-weight: 600;

				@include breakpoint(mobile) {
					font-size: 1em;
					line-height: 1.5;
				}

				a {
					padding: 20px 30px;
					display: block;
					text-decoration: none;
					position: relative;
    				z-index: 9;

					@include breakpoint(mobile) {
						position: relative;
					    padding-left: 60px;
					    top: 2px;
					}
				}
			}
		}

		.panel-body {
			background: $tertiary-50;
			padding: 20px 30px;
		}
	}

	.heading {
		margin-bottom: 50px;

		@include breakpoint(mobile) {
			margin-bottom: 0;
		}
	}
}