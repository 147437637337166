.media-area {
	height: 140px;
	@media screen and (max-width:768px) {
		height: auto;
		color: $accent-300;
	}
	.col-md-12 {
		padding: 0px;
		ul {
			@media screen and (max-width:768px) {
				// li:nth-child(2){
				// 	margin-left: 9vw;
				// }
				// li:nth-child(3n){
				// 	float: right;
				// }
				// li:nth-child(5){
				// 	margin-left: 9vw;
				// }
			}
		}
	}
}

.rating-konten {
	margin: 0px 0px 20px 5px;

	> p {
		color: $accent-300;
		font-size: 1.2em;
	}
	
	@media screen and (max-width:768px) {
		margin: 0px 0px 30px 5px;
	}

	.rating-konten-bintang {
		padding: 0;
		margin: 0;
		display: block;
		//max-width: 180px;

		@media screen and (max-width:768px) {
			width: 80%;
			margin-right: 5px;
			max-width: none;
		}

		.block-rating-course-label {
			@media screen and (max-width:768px) {
				text-align: left;
				margin-bottom: 15px;
			}
		}

		ul {
			margin-left: 5px;
			
			@media screen and (max-width:768px) {
				.block-rating-course {
					margin-left: 15px;
					
					.rating {
						background: url(../img/star_2.png);
						height: 35px;
						width: 35px;
						background-size: 35px;

						&.active {
							background: url(../img/star_2_active.png);
						}
					}
				}
			}
		}

	}

	.rating-konten-poin {
		padding-left: 0;
		p {
			// line-height: 1.9;
			line-height: 0;
			margin: 20px auto;
			font-size: 24px;

			@media screen and (max-width:768px) {
				// line-height: 1.5;
				// font-size: 4vw;
				// color: $neutral-300;
				font-size: 9vw;
				color: $neutral-300;
				margin-bottom: 30px;
				margin-left: 0px;
			}
		}
	}

	.rating-konten-review {
		padding-left: 50px;
		margin: 10px;
		p {
			color: $neutral-300;
			font-size: 1.2em;
		}
		
		@media screen and (max-width:768px) {
			text-align: center;
			padding: 0px;
			margin: 0px;
			margin-bottom: 15px;
		}
	}

	.rating-kontent-button {
		//background: #7C6A55;
		padding: 10px;
		border: 3px solid $tertiary-300;
		background-color: $tertiary-300;
		-webkit-border-radius: 50px;
		-moz-border-radius: 50px;
		text-align: center;
		transition: all ease-in-out .2s;
		cursor: pointer;
		// width: 200px;
		//width: 57%;
		color: $accent-300;
		&:hover,
		&:focus {
			background: $accent-300;
			border: 3px solid $accent-300;
			color: #fff;
		}

		@media screen and(max-width:768px) {
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&.btn-submited {
			background:#FDB916;
		}
	}

}

.share-konten {
	p {
		color: $accent-300;
		font-size: 1.2em;
	}
}

#modal-review {
	@media screen and (max-width:768px){
		padding-right: 0px!important;
	}
	.modal_review{
		width: auto;
		height: auto;
		@media screen and (max-width:768px){
			height: 100vh;
			overflow: auto;
		}
	}
	.modal-review-header {
		padding: 50px 50px 20px 50px;

		img {
			max-width: 100%;
			width: 60px;
			height: 60px;
			margin-right: 15px;
			padding: 0px;
			@media screen and (max-width:768px){
				margin: 0px auto;
				margin-bottom: 15px;
			}
		}

		h4 {
			font-size: 22px;
		}

		.modal-title {
			@include font-jenius-serif;
		}
	}
	.modal-dialog {
		@media screen and (max-width:768px){
			margin: 0px;
		}
	}
}

.modal_review{
	width: 705px;
	height: 712px;
	@media screen and (max-width:768px){
		width: auto;
		height: auto;
	}
	
	&.send-email {
		width: auto;
		height: auto;
	}

	.modal-send-email {
		margin: 20vh auto;

		@media screen and (max-width:768px){
			margin: 0px;
		}

	}

	.content-send-email {
		width: 30vw;
		margin: auto;

		@media screen and (max-width:768px){
			width: 100%;
			height: 100vh;
		}
	}

	button {
		font-size: 40px;
		color: $accent-300;
		opacity: 0.8;
		margin-right: 20px;

		@media screen and (max-width:768px){
			font-size: 38px;
			margin-right: 10px;
			opacity: 0.8;
		}
	}

	.modal-review-header {
		padding: 50px;
		border-bottom: 0px;

		&.email-header {
			padding-top: 50px;
			padding-left: 15px;
			padding-right: 15px;
			padding-bottom: 10px;

			h4 {
				display: block;
				max-width: 100%;
				text-align: center;
			}

			img {
				@media screen and (max-width:768px){
					height: 100px;
				}
			}
		}

		@media screen and (max-width:768px){
			padding-left: 30px;
			padding-right: 30px;
			text-align: center;
		}
		
		img {
			padding: 30px;
			border-radius: 50%;
			max-width: 60%;
			@media screen and (max-width:768px){
				border-radius: 50%;
				padding: 10px;
				display: block;
				margin: auto;
			}
		}

		h4 {
			display: inline-block;
			color: $accent-300;
			font-size: 20px;
			vertical-align: middle;

			@media screen and (max-width:768px){
				text-align: center;
				padding: 0px;
				max-width: 100%;
			}
		}
	}

	.email-body {
		// padding: 20px 50px 0px 50px;
		padding: 14px 50px 0px 50px;

		.desc {
			color: #000;
		}

		.input-label {
			p{
				color: $accent-300;
				font-weight: bold;
				font-size: 1vw;

				@media screen and (max-width:768px){
					font-size: 4vw;
					font-weight: normal;
				}
			}

			.form-group {
				//height: 40px;
				background-color: #F1F1F1;
				
				.input-group {
					.input-group-addon {
						border: 0px;
						background-color: #F1F1F1;
						border-right: 1px solid #ccc;
						i {
							color: #d5d5d5;
							font-size: 20px;
						}
					}

					input {
						padding: 10px;
						width: 100%;
						font-weight: normal;
						font-family: 'jenius-sans';
						color: #000;
					}
				}
			}
			
		}
	}

	.modal-review-body {
		padding-left: 50px;
		padding-right: 50px;

		@media screen and (max-width:768px){
			padding-left: 30px;
			padding-right: 30px;
		}

		.review-sumber {
			margin-bottom: 20px;
			.review-sumber-text {
				@media screen and (max-width:768px){
					padding-bottom: 10px;
				}

				
				h4 {
					line-height: 2;
					color: $accent-300;
					font-size: 16px;
					font-weight: bold;
					font-family: "jenius-sans";

					@media screen and (max-width:768px){
						line-height: 1.5;
						font-size: 4.5vw;
					}
				}
				h5 {
					line-height: 1.3;
					font-size: 14px;
					font-weight: bold;
					font-family: "jenius-sans";
				}
			}
		}

		.review-masukan {
			font-family: "jenius-sans", sans-serif;

			h4 {
				line-height: 2.2;
				color: $accent-300;
				font-size: 16px;
				font-weight: bold;

				@media screen and (max-width:768px){
					font-size: 14px;
				}
			}

			.review-masukan-form{
				margin-bottom: 20px;
				.form-control{
					background-color: #EBEBEB;
					// color: #969696;
					color: black;
					border: none;
					font-size: 16px;
					line-height: 1.5;
				}
				textarea {
					@media screen and (width:360px){
						height: 13vh;
					}
					@media screen and (width:411px){
						height: 20vh;
					}
					@media screen and (width:320px){
						height: 10vh;
					}
					@media screen and (width:375px){
						height: 20vh;
					}
					@media screen and (width:414px){
						height: 30vh;
					}
				}
			}

			.ratting {
				display: flex;
				@media screen and (max-width:768px){
					display: flex;
				}

				p {
					@media screen and (max-width:768px){
						margin: 0px;
						padding-left: 10px;
					}
				}

				.rating {
					margin: 0px;
					margin-right: 3px;
					height: 28px;
					width: 28px;
					// background-size: 35px;
					background: url(../img/icon-star-modal-review.png);
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					@media screen and (max-width:768px){
						height: 25px;
						width: 25px;
						// background-size: 25px;
						background: url(../img/starrr.png);
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
					}
					&.active {
						height: 28px;
						width: 28px;
						// background-size: 35px;
						background: url(../img/starrr_active.png);
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						@media screen and (max-width:768px){
							height: 25px;
							width: 25px;
							// background-size: 25px;
							background: url(../img/starrr_active.png);
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
						}
					}
				}

				span {
					// margin: 10px auto;
					margin: 10px 0px 10px 10px;
					@media screen and (max-width:768px){
						// margin: 5px auto;
						margin: 5px 0px 0px 10px;
					}
				}
			}

			.rating-konten-bintang {
				@media screen and (max-width:768px){
					padding: 0px;
				}

				.block-rating-course {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.modal-review-footer {
		border-top: none;

		.btn-batalkan {
			text-decoration: underline;
			margin-right: 10px;
			font-size: 15px;
			color: #000;
		}

		.btn-kirim {
			border-radius: 50px;
			color: $accent-300;
			padding-left: 25px;
			padding-right: 25px;
			background-color: $tertiary-300;
			font-size: 15px;

			&.voucher-kirim {
				font-family: "jenius-sans", sans-serif;
			}
		}
	}
}

.modal-batalkan {

	button {
		font-size: 40px;
		color: $accent-300;
		opacity: 0.8;
		margin-right: 20px;

		@media screen and (max-width:768px){
			font-size: 38px;
			margin-right: 10px;
			opacity: 0.8;
		}
	}

	.modal-header {
		text-align: center;
		padding: 50px 0px 20px 50px;
		border-bottom: none;
	}

	.description {
		h4 {
			text-align: center;
			color: $accent-300;
			font-size: 2em;
		}
	}

	.modal-body {
		p {
			text-align: center;
		}
	}

	.modal-footer {
		border-top: none;
		text-align: center;

		button {
			color: #FFF;
			background-color: #7C6A56;
			font-size: 1em;
			border-radius: 20px;
			border: transparent;
			padding: 5px 50px;
			font-weight: normal;
    		font-family: 'jenius-sans';
		}

		button:nth-child(1){
			border: 1px solid #7C6A56;
			color: #7C6A56;
			background-color: #FFF;
			padding: 5px 30px;
		}
		
	}
}

#noLoginModal {
	.content-modal {
		&.content-thanks {
			.subheading-3 {
				&.text-center {
					@media screen and (max-width:768px){
						padding: 20px 20px 0px 20px;
					}
					h3 {
						@media screen and (max-width:768px){
							margin-bottom: 10px;
						}
					}
				}
			}
		}
	}
	.icon-modal .icomoon.icomoon-lock-circle {
		font-size: 96px;
		color: $accent-300;
	}
}

//modal terimakasih
.content-thanks {
	width: 600px;

	@media screen and (max-width:768px){
		width: auto;
	}
	
	.modal-header{
		text-align: center;
		border-bottom: none;
	}

	.modal-body {
		p {
			text-align: center;
			font-size: 1.2em;
		}
	}

	.icon-modal {
		text-align: center;
		@media screen and (max-width:768px){
			margin-top: 50px;
		}
	}
}

// Profil Ahli
.label-pertanyaan-terjawab {
	font-style: italic;
    color: $neutral-300;
	font-size: 1.3em;

	@media screen and (max-width:768px){
		text-align: center;
		font-size: 1em;
	}
}

.label-looking {
	margin: 20px 0px;
	padding: 0px;
	@media screen and (max-width:768px){
		margin: 0px;
	}
	&.hidden-xs {
		p {
			color: #969696;
			margin-top: 5px;
			margin-left: 10px;
		}
	}
	
}

//Star Peluang Usaha
.block-update-profile {
	&.shadow {
		@media screen and (max-width:768px){
			margin-bottom: 0px;
		}
	}
}

.profile-wrap {
	.subheading-4 {
		h3 {
			@media screen and (max-width:768px){
				font-size: 2em;
				font-weight: normal;
			}
		}
	}
}

.content-wrap-desc {
	.heading {
		display:flex;
		margin-bottom: 20px;

		@media screen and (max-width:768px){
			margin-bottom: 30px;
		}

		h2 {

			@media screen and (max-width:768px){
				padding-left: 0px;
				text-align: center;
				font-size: 2em;
				font-weight: normal;
				font-family: 'jenius-sans', sans-serif;
				// margin: 0px auto;
			}
		}

		img {
			margin-left: 10px;
			margin-right: 25px;

			@media screen and (max-width:768px){
				margin-left: 0px;
				margin-right: 10px;
			}
		}

		&.h-voucher {
			@media screen and (max-width:768px){
				margin-bottom: 20px;
			}

			h2 {
				@media screen and (max-width:768px){
					margin-bottom: 0px;
					display: inline-block;
					padding: 0px;
				}
			}
		}
	}

	.heading-date {
		position: absolute;
		top: 0px;
		right: 15px;
		padding: 10px 0px 0px 0px;

		@media screen and (max-width:768px){
			position: static;
			padding-bottom: 20px;
			color: $neutral-300;
		}
		img {
			margin-right: 0px;
		}
	}

	//sub detail
	.status-content {
		.status-content-box:last-child{
			.vertical-line{
				display: none;
			}
		}

		.status-content-box {
			position: relative;
			padding-bottom: 20px;

			.content-mark {
				@media screen and (max-width:768px){
					padding-right: 0;
				}

				.mark-status {
					position: relative;
					text-align: center;
	
					.round {
						padding: 10px;
						background-color: #FFF;
						border-radius: 50%;
						position: absolute;
						top: 0px;
						right: -5px;
						border: 1px solid #7C6A55;

						@media screen and (max-width:768px){
							right: auto;
							left: 0px;
						}

						&.active {
							background-color: #FDC130;
							border: 1px solid #FDC130;
						}
					}
				}
			}

			.content-label {
				@media screen and (max-width:768px){
					padding-left: 0;
				}

				.label-status {
					display: flex;
					padding-bottom: 10px;
	
					span {
						border: 1px solid #7C6A55;
						border-radius: 5px;
						padding: 5px 15px;
						color: #7C6A55;
						font-size: 10px;
					}
				}

				.status-datetime {
					display: flex;
					padding-bottom: 10px;
					color: #7C6A55;
	
					.status-date {
						margin-right: 20px;
	
						img {
							margin-right: 0px;
						}
					}
				}

				.status-description {
					p {
						font-size: 1.1vw;
						color: #7C6A55;
						@media screen and (max-width:768px){
							font-size: 4vw;
						}
	
						&.active {
							color: #000;
						}
					}
				}
			}

			.vertical-line {
				border: 1px solid #7C6A55;
				position: absolute;
				top: 0px;
				width: 1px;
				left: 36px;
				height: 100%;
				width: 1px;

				@media screen and (max-width:768px){
					left: 10px;
				}
			}
		}
	}
	//end sub detail

	.content-list:last-child{
		margin-bottom: 50px;
	}

	.content-list {
		a, a:hover {
			margin-bottom: 0px;
			padding-top: 5px;
			font-size: 1.1em;
			color: #000;
			text-decoration: none!important;
			cursor: pointer;
			font-weight: normal;
			font-family: 'jenius-sans';

			@media screen and (max-width:768px){
				font-size: 4vw;
			}
			
		}
	}

	.sort-status {
		margin-bottom: 10px;
		.sort-status-wrapper {
			position: relative;
		}

		.status-label {
			background-color: #F0F0F0;
			padding: 12px 0px;

			label, p {
				margin-bottom: 0px;
				color: $neutral-300;
				font-size: 12px;
				font-weight: bold;
			}

			label {
				@media screen and (max-width:768px){
					font-weight: normal;
					font-family: 'jenius-sans', sans-serif;
				}
			}

			p {
				text-align: center;
			}
		}

		//untuk sort date in mobile
		.show {
			@media screen and (max-width:768px) {
				padding: 15px;
			}

			.status-list {
				@media screen and (max-width:768px) {
					background-color:#FFF;
					width: 40%;
				}

				span {
					&.jcf-select.jcf-unselectable.jcf-select-form-control{
						@media screen and (max-width:768px) {
							background-color:#FFF;
						}
					}

					.jcf-select-text {
						@media screen and (max-width:768px) {
							background-color:#FFF;
						}
					}
				}
			}

			.status-label {
				@media screen and (max-width:768px) {
					background-color: #FFF;
					text-align: center;
					width: 20%;
				}
				
			}
		}

		.status-list {
			background-color: #F0F0F0;
			padding: 8px 0px;

			&.active-list {
				@media screen and (max-width:768px) {
					background-color:#F0F0F0;
				}
			}

			span{
				&.jcf-select {
					background-color: #F0F0F0;

					&.jcf-unselectable {

						&.jcf-select-form-control {
							border: none;
							background-color: none;
							margin-bottom: 0px;
							min-width: auto;
							width: 100%;

							@media screen and (max-width:768px) {
								width: 100%;
							}

							.jcf-list-content {
								//Untuk menghilangkan list pertama option
								@media screen and (max-width:768px) {
									width: 52.5vh;
									background-color: #FFF;
									padding-bottom: 30px;

									ul {
										li:nth-child(1){
											border-bottom: none;
											padding: 0px;
										}
									}

									li {
										padding: 10px 5px;
										border-bottom: 1px solid #8A8A8A;

										ul {
											li {
												border-bottom: none;
												padding-left: 0px;
											}
										}
									}
								}

								.jcf-option {
									&.jcf-selected {
										background: $accent-300 !important;
										color: $tertiary-300 !important;
									}

									&.jcf-hover {
										background: none;
									}
								}  
							}

							&.jcf-drop-active{

								@media screen and (max-width:768px) {
									background-color: #F0F0F0;
								}

								.jcf-select-opener {
									
									@media screen and (max-width:768px) {
										background-color: #F0F0F0;
										background:  url(../img/cancel_19_19.png) no-repeat;
										position: absolute;
										top: 15%;
									}
								}

								//menghilangkan background select di mobile
								.jcf-select-text {
									@media screen and (max-width:768px) {
										background-color: #F0F0F0;
										color: $neutral-300;
										font-size: 13px;
										font-weight: bold;
									}
								}
							}
						}
					}
				}

				&.jcf-select-text {
					background-color: #F0F0F0;
					color: $neutral-300;
					font-size: 13px;
					// font-weight: bold;
				}

				.jcf-select-opener {
					background-color: none;
					background: url(../img/icon-arrow-down.png) no-repeat;
					position: absolute;
					top: 35%;
				}

				&.jcf-focus {
					border: none;

					.jcf-select-opener {
						background: url(../img/icon-arrow-up.png) no-repeat;
						position: absolute;
						top: 35%;

						@media screen and (max-width:768px){
							background-color: none;
							background: url(../img/icon-arrow-down.png) no-repeat;
							position: absolute;
							top: 35%;
						}
					}
				}

				.jcf-select-drop-content {
					min-width: auto;
					width: 130%; 

					@media screen and (max-width:768px){
						padding-top: 10px;
					}
				}

				&.icon-arrow {
					position: absolute;
					right: 2px;
					top: 15px;
					padding: 10px;

					@media screen and (max-width:768px){
						background: url(../img/icon-arrow-down.png) no-repeat;
					}

					&.show {
						@media screen and (max-width:768px){
							background: url(../img/cancel.png) no-repeat;
						}
					}
				}
			}

			span.jcf-option.jcf-disabled{
				display: none;
			}

			button {
				color: $neutral-200;
			}

		}
	}

	.content-list-wrap {
		padding: 40px 0px;
		border-bottom: 1px solid #7C6A55;

		@media screen and (max-width:768px){
			padding: 20px 0px;
			border-bottom: 0.5px solid #7C6A55;
		}
	
		.content-label {
			display: flex;
			align-items: center;
			margin-bottom: 20px;
			color: $neutral-200;

			i {
				margin: 0px 10px 0px 0px;
				color: $neutral-200;
				opacity: 0.5;
			}

			a {
				// margin: 0px 20px 0px 0px;
				color: $neutral-200;
				text-decoration: none!important;
				cursor: pointer;
				font-weight: normal;
				font-family: 'jenius-sans', sans-serif;

			}

			span {
				border: 1px solid $tertiary-300;
				border-radius: 5px;
				position: relative;

				margin: 5px 0px 0px 20px;
				color: $accent-300;
				cursor: pointer;
				font-weight: normal;
				font-family: 'jenius-sans';
				padding: 3px 15px;
				font-size: 13px;

				@media screen and (max-width:768px){
					position: absolute;
					right: 15px;
				}

				a, a:hover {
					// margin: 0px;
					// padding: 5px 15px;
					color: $accent-300;
					font-size: 13px;
					text-decoration: none!important;
					cursor: pointer;
				}

				.notif {
				    background-color: red;
					padding: 5px;
					border-radius: 50%;
					position: absolute;
					right: -2px;
					top: -4px;
				}
			}

		}

		.content-description {
			.content-bold{
				a, a:hover{
					font-weight: bold;
					text-decoration: none!important;

					@media screen and (max-width:768px){
						font-weight: normal;
						font-family: 'jenius-sans';
					}
				}
			}

			.content-unread {
				font-weight: bold;
				color: #000;
				a, a:hover {
					font-weight: bold;
					p {
						span {
							font-weight: bold;
						}
					}

					@media screen and (max-width:768px){
						font-size: 4vw;
					}
				}
			}

			a, a:hover {
				margin-bottom: 0px;
				padding-top: 5px;
				font-size: 1.1em;
				color: #000;
				text-decoration: none!important;
				cursor: default;
				font-weight: normal;
				font-family: 'jenius-sans';


				@media screen and (max-width:768px){
					font-size: 4vw;
				}
				p {
					span {
						margin-bottom: 0px;
						padding-top: 5px;
						font-size: 1.1em!important;
						color: #000!important;
						text-decoration: none!important;
						cursor: pointer;
						font-weight: normal;
						font-family: 'jenius-sans';
						@media screen and (max-width:768px){
							font-size: 4vw;
						}
					}
				}
			}

			.btn-action {
				@media screen and (max-width:768px){
					text-align: center;
					margin-top: 20px;
				}
			}

			button {
				font-weight: normal;
				border: 1px solid $neutral-300;
				padding: 5px 30px;
				border-radius: 20px;
				color: $neutral-300;
				font-size: 13px;
				font-family: 'jenius-sans', sans-serif;
			}

			.rating-konten-bintang {
				padding: 0px;

				@media screen and (max-width:768px){
					// padding: 10px 0px 0px 15px;
					padding: 10px 0px 0px 0px;
				}

				.rating {
					background: url(../img/icon_star_20p_n.png) no-repeat;
					margin: 0px;
					
					&.active {
						background: url(../img/icon_star_20p.png) no-repeat;
					}
					
				}

				

				.form-rating-course {
					display: flex;

					p {
						padding: 0px;
						font-weight: normal;
						color: #969696;

						@media screen and (max-width:768px) {
							margin: 0px;
							padding-left: 10px;
						}
					}
				}
			}

			.content-wrapper-voucher {
				// margin: 34px auto;
				margin: 9px 0px 0px 0px;
				@media screen and (max-width:768px){
					margin: 0px;
					padding: 0px;
					margin-top: 7px;
				}
			}
			
		}

		.content-review {
			background-color: #E5E1DD;
			margin-top: 30px;

			@media screen and (max-width:768px){
				margin-top: 0px;
			}

			.review-wrap {
				display: flex;
				align-items: center;
				height: 70px;
				text-align: center;

				div:nth-child(1){
					text-align: right;
				}

				@media screen and (max-width:768px){
					margin-top: 0px;
					display: inline-table;
				}

				.review-text {
					@media screen and (max-width:768px){
						display: flex;
						align-items: center;
						padding: 10px 50px;
					}

					.review-icon {
						padding-right: 0px;

						@media screen and (max-width:768px){
							height: 30px;
							width: 35px;
							padding-right: 0px;
						}
					}

					.review-desc {
						p {
							margin-bottom: 0px;
							padding-top: 5px;
						}

						@media screen and (max-width:768px){
							text-align: center;
							padding: 0px;
						}

						p {
							@media screen and (max-width:768px){
								font-size: 4vw;
							}
						}
					}
				}

				.btn-action {
					@media screen and (max-width:768px){
						padding-bottom: 10px;
						font-weight: normal;
						font-family: 'jenius-sans', sans-serif;
					}
				}
				
			}

			p {
				font-size: 1.2em;
			}
		}
	}

	.content-voucher {
		.voucher-wrap {
			padding: 20px 0px 0px 0px;

			.content-promo {
				padding: 0px;

				@media screen  and (max-width:768px){
					margin-bottom: 10px;
				}

				h6 {
					color: $neutral-300;
    				margin-bottom: 10px;
				}

			}

			div:nth-child(1){
				padding-right: 20px;

				@media screen and (max-width:768px){
					padding-right: 0px;
				}
			}

			.box-voucher {
				padding: 20px;
				background-color: #F0F0F0;

				span {
					font-size: 16px;
					color: #000;
					margin-right: 20px;
				}

				a {
					float: right;
					color: $accent-300;
					text-decoration: underline;
				}
			}
		}

		.btn-send-email {
			@media screen and (max-width:768px){
				padding-left: 15px;
			}

			.btn-gold-white {
				@media screen and (max-width:768px){
					font-size: 3vw;
				}
			}
		}
	}

	ul {
		line-height: 1.5;

		li {
			&.content-header {
				color: $accent-300;
				font-size: 16px;
				margin-bottom: 10px;

				@media screen and (max-width:768px){
					font-size: 14px;
				}

				p {
					color: #000;
				}
			}

			ul {
				padding-left: 20px;
				color: #2D2D2D;

				li {
					font-size: 16px;
					font-family: 'jenius-sans', sans-serif;
					font-weight: normal;

					@media screen and (max-width:768px){
						font-size: 14px;
					}
				}
			}
		}
	}

}
//End peluang usaha

//custom in something condition
.btn-berikan-review {
	background-color: $accent-300;
    border: 1px solid transparent;
    color: #FFF;
    padding: 5px 15px;
	border-radius: 20px;

	@media screen and (max-width:768px){
		padding: 5px 30px;
	}
}

.content-status-custom {
	@media screen and (max-width:768px){
		cursor: pointer;
		
		ul {
			li {
				list-style-type: none;
				padding: 10px 0px 10px 0px;
				border-bottom: 1px solid #8A8A8A;
				color: $neutral-200;
				font-family: 'jenius-sans', sans-serif;
				font-weight: normal;
			}

			li:hover {
				color: $accent-300;
			}
		}
	}
}

.add-margin {
	padding: 0px;

	@media screen and (max-width:768px){
		margin-bottom: 13px;
		padding: 0px;
	}

	p {
		@media screen and (max-width:768px){
			overflow-wrap: break-word;
		}
	}

	
}

.box-gradient {
	border: 1px solid #969696;
    border-radius: 5px;
	overflow: hidden;
	cursor: pointer;
	width: 268px;
	height: 98px;

	&.voucher-img {
		width: 100%;
		height: 100%;
		@media screen and (max-width:768px){
			width: 100%;
			height: 100%;
		}
	}

	@media screen and (max-width:768px){
		width: 100%;
		height: 150px;
	}
	
	img {
		width: 268px;
		height: 98px;
		@media screen and (max-width:768px){
			width: 100%;
		}
	}
	
	a {
		@media screen and (max-width:768px){
			padding-top: 0px!important;
		}
	}

	span {
		position: absolute;
		top: 5px;
		right: 10px;
		z-index: 1;
		font-size: 14px;

		@media screen and (max-width:768px){
			right: 5px;
			font-size: 3vw;
		}
	}

	&::after {
		content: " ";
		display: block;
		position: absolute;
		right: 15px;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		background: rgba(124,106,85,0.85);
		background: -moz-linear-gradient(left, rgba(124,106,85,0.85) 0%, rgba(255,255,255,0.39) 100%);
		background: -webkit-gradient(left top, right top, color-stop(0%, rgba(124,106,85,0.85)), color-stop(100%, rgba(255,255,255,0.39)));
		background: -webkit-linear-gradient(left, rgba(124,106,85,0.85) 0%, rgba(255,255,255,0.39) 100%);
		background: -o-linear-gradient(left, rgba(124,106,85,0.85) 0%, rgba(255,255,255,0.39) 100%);
		background: -ms-linear-gradient(left, rgba(124,106,85,0.85) 0%, rgba(255,255,255,0.39) 100%);
		background: linear-gradient(to right, rgba(124,106,85,0.85) 0%, rgba(255,255,255,0.39) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7c6a55', endColorstr='#ffffff', GradientType=1 );
		
	}

	&.box-gradient-detaill {
		width: 905px;
		// height: 328px;
		height: 100%;
		border-radius: 5px;
		border: none;
		@media screen and (max-width:768px){
			width: 100%;
			height: 100%;
		}
		img{
			width: 100%;
			height: 100%;
			@media screen and (max-width:768px){
				width: 100%;
			}
		}
	}
}

.breadcrumbs {
	ul {
		li {
			&.active {
				&.visible-xs {
					@media screen and (max-width:768px){
						color: $accent-300;
						text-decoration: none;
					}
				}
			}
		}
	}
}

//rating label
.rating-info {
	// background: url(../img/icon_star_20p_n.png) no-repeat;
	background: url(../img/icon-star-modal-review.png) no-repeat;
	margin: 0px;
	display: inline-block;
	border: none !important;
	height: 35px;
	width: 35px;
	transition: all .3s ease-in-out;
	cursor: auto;
	border-radius: 0;
	
	&.active {
		// background: url(../img/icon_star_20p.png) no-repeat;
		background: url(../img/icon-star-modal-review-active.png) no-repeat;
	}

	@media screen and (max-width:768px) {
		// background: url(../img/icon_star_20p_n.png) no-repeat;
		background: url(../img/icon-star-modal-review.png) no-repeat;
		// height: 35px;
		// width: 35px;
		// margin-right: 10px;
		height: 40px;
		width: 40px;
		margin-right: 0vw;

		&.active {
			// background: url(../img/icon_star_20p.png) no-repeat;
			background: url(../img/icon-star-modal-review-active.png) no-repeat;
		}
	}
	@media screen and (width: 360px) {
		margin-right: 1%;
	}
	@media screen and (width: 411px) {
		margin-right: 3%;
	}
	@media screen and (width: 320px) {
		margin-right: -2%;
	}
	@media screen and (width: 375px) {
		margin-right: 2%;
	}
	@media screen and (width: 414px) {
		margin-right: 2.5vw;
	}
	
}

//untuk label rating pojok kanan atas
.label-rating {
	background-color: #FFFFFF;
	max-width: 30%;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 7px;
    position: absolute;
    right: 6px;
    top: 7px;
    opacity: 0.8;
    -webkit-box-shadow: 0px 0px 34px -4px rgba(112,112,112,0.36);
	-moz-box-shadow: 0px 0px 34px -4px rgba(112,112,112,0.36);
	box-shadow: 0px 0px 34px -4px rgba(112,112,112,0.36);
	line-height: 1.5;
	width: 45px;
	height: 22px;
	z-index: 1;
	@media screen and (max-width:768px) {
		width: 52px;
		border-radius: 5px;
	}
	
	p {
		color:$neutral-300;
		text-align: center;
		margin: 0px; 
		font-size: 13px;
		font-weight: bold;
		font-family: 'jenius-sans', sans-serif;
	}

	.icon-bintang {
		background: url("../img/label_rating.png") no-repeat;
		display: inline-block;
		padding: 0;
		vertical-align: middle;
		width: 12px;
		height: 12px;
	}
	.fa.fa-star {
		color: $accent-300;
	}
}

.lbl-padding {
	padding: 0px;
	
	@media screen and (max-width:768px){
		padding-right: 15px;
		padding-left: 15px;
	}
	&.btn-clear-pading {
		@media screen and (max-width:768px){
			padding-top: 15px;
		}
	}

	label {
		padding: 0px;
	}

	&.clear-date {
		position: absolute;
    	right: -65px;
	}
}

.sort-by-date-content {
	background-color: #F0F0F0;
	@media screen and (max-width:768px){
		display: none;
		background-color:none;
	}

	&.show {
		@media screen and (max-width:768px){
			display: block;
			background-color:#FFF!important;
		}
	}
}

.sort-by-date-filter {
	.icon-sort {
		background: url(../img/icon-arrow-down.png) no-repeat;
		position: absolute;
		top: 35%;
		right: 0px;
		height: 15px;
		width: 25px;
		
		&.show {
			@media screen and (max-width:768px){
				background: url(../img/cancel_19_19.png) no-repeat;
			}
		}
	}
}

//end custom

.section-access-counter {
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.19);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.19);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.19);
	text-align: center;
    transition: 0.5s all;
	position: fixed;
    left: 0;
    right: 0;
	bottom: 0;
	background: #fff;
	z-index: 9;
	@media screen and (max-width:768px){
		bottom: 0;
		padding: 10px;
	}

	p {
		color: #969696;
		font-size: 16px;
		margin-top: 10px;
		@media screen and (max-width:768px){
			font-size: 4vw;
			margin: 0px;
		}
		span {
			color: $neutral-300;
			font-weight: bold;
		}
		a {
			color: $accent-300;
			font-weight: bold;
		}
	}

	&.scrollBot {
		bottom: 185px; //awalnya 185px
		// @media screen and (max-width:768px){
		// 	bottom: 45%;
		// }
		// @media screen and (height:640px){
		// 	bottom: 57%;
		// }
		// @media screen and (height:731px){
		// 	bottom: 40%;
		// }
		// @media screen and (height:823px){
		// 	bottom: 36%;
		// }
		// @media screen and (height:568px){
		// 	bottom: 64%;
		// }
		// @media screen and (height:667px){
		// 	bottom: 53%;
		// }
		// @media screen and (height:736px){
		// 	bottom: 40%;
		// }
		// @media screen and (height:812px){
		// 	bottom: 43%;
		// }
	}

	#register-access-counter {
		margin-left: 8px;
	}
}
.block-content{
	&.block-article {
		&.block-article-detail {
			@media screen and (max-width:768px){
				padding-bottom: 80px;
			}
		}
	}
}

// <!-- Begin modal register training -->
#registerTraining {
	.content-modal {
		.body-modal {
			.konten-form {
				margin: 0px;
			}

			.form-province {
				margin-bottom: 15px;
			}
		}
	}

	.form-group {
		label {
			&.label-field {
				opacity:1;
				display:block;
				visibility: visible; 
			}
		}
	}

	.form-province {
		.input-wrap {
			height: 50px;
		}
	}
}

#registerThanksAbout {
	.modal-header {
		img {
			@media screen and (max-width:768px){
				width: 80px;
			}
		}
	}
	.subheading-3 {
		&.text-center {
			@media screen and (max-width:768px){
				padding: 0px;
			}

			&.title-thanks {
				@media screen and (max-width:768px){
					padding: 55px 20px 0;
				}
			}

		}
	}
	.btn-area  {
		@media screen and (max-width:768px){
			width: 40%;
		}
	}
}

.block-market {
	.product {
		&.big-product {
			img {
				@media screen and (max-width:768px){
					top: 60%!important;
				}
			}
		}
	}
}

#btn-open-marketplace {
	float: left;
	@media screen and (max-width:768px){
		float: none;
		width: 50%;
		font-size: 1em;
	}
}

#modal-review-peluang-usaha {
	@media screen and (max-width:768px){
		padding-right: 0px;
	}
	.modal_review {
		width: auto;
		height: auto;
		.modal-review-header {
			img {
				// max-width: 30%;
				max-width: 100%;
				width: 80px;
				height: 80px;
				margin-right: 15px;
				padding: 0px;
				@media screen and (max-width:768px){
					margin: 10px auto;
				}
			}
			h4 {
				@media screen and (max-width:768px){
					font-size: 6vw;
				}
			}
		}
	}
	.modal-header {
		&.modal-review-header {
			padding: 50px 50px 20px 50px;
		}
	}
	.modal-dialog{
		@media screen and (max-width:768px){
			margin: 0px;
		}
	}
}

#modal-send-via-email {
	.content-send-email {
		button {
			&.close {
				font-size: 30px;
			}
		}
		.modal-review-header {
			&.email-header  {
				h4 {
					font-size: 1.8vw;
				}
				.email-body {
					.input-label {
						margin-top: 10px;
					}
				}
			}
		}
	}
		
}

.btn-beli-produk {
	padding: 0px;
}

.block-market {
	.product {
		&.small-product {
			@media screen and (max-width:768px){
				margin: 100px 0 20px!important;
			}
		}
	}
}

.media-area {
	.col-md-12 {
		ul {
			@media screen and (max-width:768px){
				text-align: left;
			}
			li {
				@media screen and (max-width:768px){
					width: 24vw!important;
					margin: 0 4.5vw 10px 0px;
				}
			}
		}
	}
	
}

//List in Dashboard Phase 2
.block-dashboard {
	.option-area {
		ul {
			li {
				@media screen and (max-width:768px){
					list-style: none!important;
					display: block!important;
					border-bottom: 1px solid #e5e5e5!important;
					width: 100%!important;
				}
			}
		}
	}
}
#select-list-dashboard {
	@media screen and (max-width:768px){
		padding: 0px;
		margin-bottom: 10px;
	}
	
	span {
		@media screen and (max-width:768px){
			padding: 15px 5px;
			padding-right: 30px;
			font-family: 'jenius-sans', sans-serif;
			color: $neutral-300;
			font-size: 0.9em;
			font-weight: bold;
			background: #F0F0F0;
		}
		&.icon-sort {
			@media screen and (max-width:768px){
				background: url(../img/icon-arrow-down.png) no-repeat;
				position: absolute;
				top: 35%;
				right: 0px;
				height: 15px;
				width: 25px;
			}
		}
	}
}

.overlay {
	@media screen and (max-width:768px){
		position: fixed;
		background: rgba(0,0,0,0);
		transition: all .2s;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 0;
		display: none;
		&.show {
			background: rgba(0,0,0,.5);
			display: block!important;
			z-index: 2;
		}
	}
}

.block-dashboard {
	.option-area {
		ul {
			&.list-dashboard {
				@media screen and (max-width:768px){
					display: block;
				}
				
				&.show {
					@media screen and (max-width:768px){
						background-color: #FFF;
					}
				}
			
				&.display_none {
					@media screen and (max-width:768px){
						display: none;
					}
				}
			}
			
		}
	}
}


.list-content-dashboard {
	&.show {
		@media screen and (max-width:768px){
			z-index: 2;
			position: relative;
		}
	}
}

.media-area {
	ul {
		&.ratting {
			@media screen and (max-width:768px){
				text-align: left;
			}
		}
	}
}

.btn-clear-date {
	border: 1px solid $accent-300;
    border-radius: 20px;
    margin: 5px 0px 5px 15px;
    color: $accent-300;
	font-size: 14px;
	display: block;
	@media screen and (max-width:768px){
		margin: 0 auto;
	}
	&.no-active {
		display: none;
	}
	i {
		&.fa {
			font-size: 18px;
		}
	}
	&:hover, &.focus {
		color: $accent-300;
	}
	&.btn {
		&:hover, &.focus {
			color: $accent-300;
		}
	}
}

#noLoginModal-artikel {
	.subheading-3 {
		&.text-center {
			@media screen and (max-width:768px){
				padding: 20px 20px 0px 20px;
			}
			h3 {
				@media screen and (max-width:768px){
					margin-bottom: 10px;
				}
			}
		}
	}
	.icon-modal {
		img {
			width: 110px;
			margin-left: 20px;
		}
	}
	.btn-area {
		&.text-center {
			@media screen and (max-width:768px){
				width: 40%;
			}
		}
	}
}


.jcf-list .jcf-list-content {
	border: 1px solid #ccc;
}

.email-error {
	font-size:12px;
	color:red;
	font-style: italic;
}