// Form Pengajuan Produk
// Imam

$backgroud_header : $tertiary-300;
$active_header : $accent-300;
$noactive_header : $neutral-300;
$caption_header : #7C6A55;
$placeholder_color : #D6D4D4;
$white : #FFF;
$border_btn : #717171;
$error_color : red;

@mixin label_number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5ch;
    height: 2.5ch;
    border-radius: 100%;
    margin-right: 0.35rem;
    color: $white;
    font-size: 18px;
    background-color: $active_header;
    @media screen and (max-width:768px){
        padding: 0.4rem;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        font-size: 14px;
    }
}
@mixin list-a {
    background: none;
    border: none;
    color: $active_header;
    border-radius: 0px;
}


.pengajuan-pinjaman-pegawai {
    margin-top: 86px;
    @media screen and (max-width:768px){
        padding: 0px;
        margin: 0px;
        margin-top: 60px;
    }
    .pengajuan-pinjaman {
        @media screen and (max-width:768px){
            padding: 0px;
            margin: 0px;
        }
    }
    .breadcrumbs {
        @media screen and (max-width:768px){
            margin-left: 20px;
        }
    }
    .pengajuan-pinjaman-header {
        .label-header {
            font-family: 'jenius-sans';
            font-weight: 600;
            letter-spacing: 1px;
            font-size: 13px;
            margin: 0px auto;
            border: none;
            padding: 20px;
            background-color: $backgroud_header;
            text-align: center;
            @media screen and (max-width:768px){
                padding: 10px;
            }
            li:first-child {
                margin-right: 100px;
                @media screen and (max-width:768px){
                    margin-right: 0px;
                    float: left;
                }
            }
            li {
                color: $noactive_header;
                float: none;
                display: inline-block;
                position: relative;
                @media screen and (max-width:768px){
                    float: right;
                    width: 50%;
                }
                &.active {
                    color: $active_header;
                    span {
                        @include list-a;
                        strong {
                            @include label_number;
                            color: $tertiary-300;
                            background-color: $active_header;
                            opacity: 1;
                        }
                    }
                }
                span {
                    @include list-a;
                    color: $noactive_header;
                    position: relative;
                    display: block;
                    padding: 10px 15px;
                    @media screen and (max-width:768px){
                        // font-size: 2.4vw;
                        // padding: 10px;
                        // text-align:left;

                        font-size: 2vw;
                        display: flex;
                        align-items: center;

                        strong {
                            float: left;
                        }
                    }
                    @media screen and (width:320px){
                        font-size: 2.3vw;
                    }
                    @media screen and (width:414px){
                        font-size: 2.5vw;
                    }
                    @media screen and (width:411px){
                        font-size: 2.5vw;
                    }
                    strong {
                        @include label_number;
                        background-color: $neutral-300;
                        color: $tertiary-300;
                        opacity: 0.3;
                    }
                }
            }
            li:first-child:after {
                content: "";
                width: 40%;
                height: 1px;
                background: $noactive_header;
                position: absolute;
                top: 24px;
                left: 100%;
                @media screen and (width:360px){
                    width: 5vw;
                    top: 20px;
                    left: 150px;
                }
                @media screen and (width:411px){
                    width: 10vw;
                    top: 20px;
                    left: 166px;
                }
                @media screen and (width:320px){
                    width: 9vw;
                    top: 20px;
                    left: 135px;
                }
                @media screen and (width:375px){
                    width: 7vw;
                    top: 20px;
                    left: 150px;
                }
                @media screen and (width:414px){
                    width: 10vw;
                    top: 20px;
                    left: 168px;
                }
            }
        }
        .form-pengajuan-pinjaman {
            padding-left: 100px;
            padding-right: 100px;
            @media screen and (max-width:768px){
                padding-left: 30px;
                padding-right: 30px;
            }
            h3 {
                text-align: center;
                color: $accent-300;
                margin: 40px auto;
                font-size: 26px;
                @media screen and (max-width:768px){
                    font-size: 7vw;
                }
            }
            
            .wrapper-footer-btn {
                margin: 20px auto;
                text-align: center;
                @media screen and (max-width:768px){
                    display: flex;
                    margin: 20px auto; 
                    margin-bottom: 40px;
                }
                .btn-gold-white {
                    @media screen and (max-width:768px){
                        padding: 10px 20px!important;
                        margin: 10px auto;
                    }
                    &.prev {
                        background-color: $white;
                        border: 1px solid $border_btn;
                        color: $border_btn;
                        font-weight: bold;
                        opacity: 0.4;
                        margin-right: 20px;
                        @media screen and (max-width:768px){
                            display: flex;
                            margin: 10px auto;
                        }
                    }
                }
            }
            .form-informasi-kepegawaian{
                margin-bottom: 20px;
                @media screen and (max-width:768px){
                    margin-bottom: 10px;
                }
                h4 {
                    @include font-jenius-serif;
                    margin-bottom: 20px;
                    color: $active_header;
                    @media screen and (max-width:768px){
                        font-size: 4.5vw;
                        margin-bottom: 10px;
                    }
                }
                .wrapper-form {
                    @media screen and (max-width:768px){
                        padding: 0px;
                    }
                    .radio {
                        label {
                            @media screen and (max-width:768px){
                                padding: 0px;
                            }
                        }
                    }
                    .label-caption {
                        padding-left: 0px;
                        label {
                            &.caption {
                                margin-bottom: 0px;
                                margin-left: 0px;
                                width: 100%;
                            }
                            &.desc {
                                margin-left: 0px;
                                font-size: 14px;
                                font-family: "jenius-sans";
                                color: #2D2D2D;
                                opacity: 0.5;
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .checkbox-group {
                        margin: 0px;
                        margin-top: 10px;
                    }
                }
                .wrapper-form:nth-child(even){
                    padding-left: 0px;
                }
                .alamat-form-usaha {
                    padding: 0px;
                }
            }
            
        }
        .form-group {
            margin-bottom: 35px;
            @media screen and (max-width:768px){
                margin-bottom: 23px;
            }
            .input-group{
                width: 100%;
                .form-control {
                    padding-left: 10px;
                    border-radius: 5px;
                }
            }
            textarea {
                height: 100%;
            }
            label {
                font-size: 14px;
                font-family: "jenius-sans";
                &::after {
                    content: "*";
                    color: red;
                    font-size: 14px;
                    margin-left: 5px;
                }
            }
            .checkbox {
                label{
                    padding: 0px;
                    margin-bottom: 10px;
                    margin-left: 0px;
                    span {
                        &.jcf-checkbox {
                            margin-right: 10px;
                        }
                    }
                }
            }
            .radio {
                @media screen and (max-width:768px){
                    margin: 0px;
                }
                label {
                    padding: 0px;
                    margin-right: 50px;
                    // span:nth-child(2) {
                    //     margin-left: 35px;
                    // }
                    span {
                        &.jcf-radio:nth-child(even){
                                margin-left: 30px;
                            }
                        
                    }
                }
            }
            .has-error {
                display: none;
                float: right;
                color: $error_color;

                @media screen  and (max-width:768px){
                    display: block;
                    opacity: 0;
                }

                &.is-btpn-usaha-error, &.aset-error, &.is-pinjaman-usaha-error {
                    float: left;
                    margin-bottom: 20px;
                    @media screen and (max-width:768px){
                        float: right;
                        margin-bottom: 10px;
                    }
                }
                &.active {
                    display: block;

                    @media screen  and (max-width:768px){
                        display: block;
                        text-align: right;
                        color: red;
                        opacity: 1;
                        font-size: 3.3vw;
                    }
                }
            }
            
            //custom select option
            select {
                cursor: pointer;
            }
            span {
                &.jcf-select {
                    &.jcf-unselectable {
                        &.jcf-select-form-control{
                            height: 50px;
                            width: 100%;
                            margin-bottom: 0px;
                            border: 1px solid #d5d5d5;
                            .jcf-select-text {
                                margin: 0px;
                                padding: 10px;
                                opacity: 1;
                                .jcf-option-selected-custom  {
                                    opacity: 0.5;
                                }
                            }
                            .jcf-select-opener {
                                background-color: #FFF;
                                background: url(../img/icon-arrow-down.png) no-repeat;
                                position: absolute;
                                top: 35%;
                            }
                            &.jcf-focus {
                                border: 1px solid #d5d5d5!important;
                                .jcf-select-opener {
                                    background-color: #FFF;
                                    background: url(../img/icon-arrow-up.png) no-repeat;
                                    position: absolute;
                                    top: 35%;
                                }
                            }

                        }
                    }
                }
                .jcf-option.jcf-selected {
                    background: $accent-300;
                    color: $tertiary-300;
                }
            }
            //end custom select option

            //custom radio button

        }
    }
}



