.pagination {
	display: block;
	ul {
		display: block;
		margin: 0;
		padding: 0;
		text-align: center;

		
	}

	li {
		list-style: none;
		display: inline-block;
		margin: 0 2px;

		&:first-child {
			border-top: 0 !important;
			border-bottom: 0;
		}

		a {
			color: #cecece;
			padding: 10px 12px;
			display: block;
			background: $white;
			text-decoration: none;
			font-family: 'jenius-sans';
			box-shadow: 0px 0px 14px 0px #e8e8e8;
			transition: all .3s ease-in-out;

			&:hover {
				color: $tertiary-300;
			}

			&.active {
				pointer-events: none;
				background: $tertiary-300;
				color: $accent-300;
			}

			img {
				width: 9px;
			}
		}

	}
	
}