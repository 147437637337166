.btn-area {
    margin: 20px 0;

    .btn-primary {
        display: inline-block;
        padding: 8px 40px;
        background: $tertiary-300;
        font-size: 1em;
        text-transform: uppercase;
        color: $accent-300;
        border-radius: 20px;
        text-decoration: none;
        outline: none;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        border: 1px solid $tertiary-300;

        @include breakpoint(mobile) {
            display: block;
            padding: 8px;
            text-align: center;
            width: 95%;
            margin: auto;
            font-size: 1em;
        }

        &:focus {
            color: $white;
            background-color: $accent-300;
            border: 1px solid $accent-300;
        }

        &:hover {
            color: $white;
            background: $accent-300;
            border: 1px solid $accent-300;
        }
        &:disabled {
            background: $neutral-75;
            border: 1px solid $neutral-75;
            color: $neutral-100;
        }
    }
    .btn-secondary {
        display: inline-block;
        padding: 5px 40px;
        //background: $accent-300;
        border-radius: 20px;
        border: solid 1px $tertiary-300;
        font-size: 1em;
        text-transform: uppercase;
        color: $accent-300;
        letter-spacing: 1px;
        text-decoration: none;
        outline: none;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        @include breakpoint(mobile) {
            display: block;
            padding: 8px;
            text-align: center;
            width: 80%;
            margin: auto;
            font-size: 0.8em;
        }

        &.bg-gray {
            background: $neutral-75 !important;
            box-shadow: none !important;
        }

        &.bg-orange, &.bg-brown, &.bg-orange-dark, &.bg-orange-noshadow {
            background: $tertiary-300;
            box-shadow: 0px 0px 22px 0px $tertiary-300;

            &:hover {
                background: $tertiary-400;
                border-color: $tertiary-400;
            }
        }

        &.bg-white {
            background: $white;
            border: 1px solid $neutral-75;
            color: $neutral-75;

            &:hover {
                background: $neutral-75;
                box-shadow: none;
            }
        }

        &.disabled {
            &:hover {
                background: $neutral-75;
                box-shadow: none;
            }
        }

        &.ratting-active {
            background: $accent-300;

            &:hover {
                background: $accent-300;
                box-shadow: none;
            }
        }

        &.bg-brown {
            color: $accent-300;
            border: solid 1px $tertiary-300;
            background-color: transparent !important;
            box-shadow: unset;
            &:hover, &:focus {
                border-color: $tertiary-300;
                background-color: $tertiary-300 !important;
            }
        }

        &:focus {
            background-color: $accent-300;
        }

        &:hover {
            background: $accent-300;
            // box-shadow: 0px 0px 22px 0px rgba(251,184,50,1);
        }
    }

    .btn-thirdary {
        display: inline-block;
        padding: 20px;
        display: block;
        color: $accent-300;
        background: $white;
        text-transform: uppercase;
        font-size: 1em;
        font-family: 'jenius-sans';
        text-decoration: none;
        box-shadow: 0px 1px 8px 1px rgba(240, 240, 240, 1);
        transition: all 0.3s ease-in-out;

        @include breakpoint(mobile) {
            font-size: 0.8em;
            padding: 15px;
        }

        &:hover {
            background: $accent-300;
            color: $white;
        }

        &:focus {
            background-color: $white;
        }
    }
    .btn-links {
        display: flex;
        img {
            margin-left: 20px;

            @include breakpoint(mobile) {
                margin-left: 10px;
            }
        }
    }
    .btn-fourthary {
        font-family: 'jenius-sans';
        color: $accent-300 !important;
        font-size: 1em;
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        border-color: $tertiary-300;

        &:hover {
            color: $accent-300;
            border-bottom: 2px solid $tertiary-300 !important;
            text-decoration: none;
            padding-bottom: 4px;
        }
    }

    .btn-fifhtary {
        display: inline-block;
        font-family: 'jenius-sans';
        color: $white;
        padding: 8px 40px;
        font-size: 1em;
        background: $accent-300;
        border-radius: 30px;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &:focus {
            background-color: $accent-300;
        }

        &:hover {
            background: $accent-300;
        }
    }

    .btn-seventery {
        display: inline-block;
        font-family: 'jenius-sans';
        color: $accent-300;
        padding: 8px 40px;
        font-size: 1em;
        background: $white;
        border: 1px solid $accent-300;
        border-radius: 30px;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        &:focus {
            background-color: $white;
        }
    }

    .btn-blue {
        display: inline-block;
        font-family: 'jenius-sans';
        color: #fff;
        padding: 8px 25px;
        font-size: 1em;
        background: #4868ad;
        border: 1px solid #4868ad;
        border-radius: 30px;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        min-width: 280px;
        margin-bottom: 20px;

        img {
            display: inline-block;
            margin-right: 10px;
        }

        + .btn-primary {
            min-width: 280px;
        }
    }

    .btn-w-margin{
      margin: auto 10px;
    }
}
