.modul-wrap {
	margin-top: 20px;

	@include breakpoint(mobile) {
		margin-bottom: 20px;
	}

	.module-list {
		border-bottom: 1px solid #e8e8e8;
	}
	ul {
		display: block;
		margin: 0;
		padding: 0;
		
	}

	li {
		list-style: none;
		border-bottom: 1px solid #e8e8e8;
		padding: 15px 0;

		@include breakpoint(mobile) {
			padding: 0 0 15px;
			border-bottom: none;
		}

		&:first-child {
			border-top: 1px solid #e8e8e8;
		}

		h3 {
			@include font-jenius-serif;
			font-size: 1em;
			font-weight: lighter;
			line-height: 2;

			a {
				outline: none;
				color: rgba(45, 45, 45, 0.40);
				transition: all .3s ease-in-out;

				@include breakpoint(mobile) {
					color: $neutral-400;
				}

				&:hover {
					text-decoration: none;
					color: #6b6b6b;
				}
			}
		}

		h4 {
			font-family: 'jenius-sans';
			font-size: 0.875em;
			font-weight: 600;
			text-transform: uppercase;
			line-height: 1.8;

			@include breakpoint(mobile) {
				font-size: 0.7em;
			}

		}

		span {
			font-family: 'jenius-sans';
			font-size: 0.875em;
			color: #888888;
		}

		&.active {
			a {
				color: #6b6b6b;
			}
		}
	}

	.slick-slide {
		padding: 0 20px 0;
		outline: none;
	}
	.slick-arrow {
		top: 37px;
    	transform: translateY(-50%);
	}

	.slick-next {
		right: 0;
		height: 25px;
		width: 14px;
		background: url(../img/icon-arrow-next.png);
		background-repeat: no-repeat;
		background-size: 13px;
		z-index: 1;

		&:before {
			content: "";
		}
	}
	.slick-prev {
		height: 25px;
		width: 14px;
		background: url(../img/icon-arrow-prev.png);
		background-repeat: no-repeat;
		background-size: 13px;
		left: 0;
		z-index: 1;

		&:before {
			content: "";
		}
	}
	
}