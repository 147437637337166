:root {
    --colorBrandOrangeO50: #fef3e9;
    --colorBrandOrangeO75: #fbcca3;
    --colorBrandOrangeO100: #f9b77d;
    --colorBrandOrangeO200: #f79745;
    --colorBrandOrangeO300: #f5821f;
    --colorBrandOrangeO400: #ac5b16;
    --colorBrandBrownB50: #e5e1dd;
    --colorBrandBrownB75: #c9c2b9;
    --colorBrandBrownB100: #b3a99c;
    --colorBrandBrownB200: #928372;
    --colorBrandBrownB300: #7c6a55;
    --colorBrandBrownB400: #574a3b;
    --colorBrandGreenG50: #e6edea;
    --colorBrandGreenG75: #96b4aa;
    --colorBrandGreenG100: #6b9586;
    --colorBrandGreenG200: #2b6752;
    --colorBrandGreenG300: #00482f;
    --colorBrandGreenG400: #003221;
    --colorBrandNeonN50: #f9fbe6;
    --colorBrandNeonN75: #e7ef96;
    --colorBrandNeonN100: #dde96b;
    --colorBrandNeonN200: #cedf2b;
    --colorBrandNeonN300: #c4d900;
    --colorBrandNeonN400: #899800;
    --colorBrandLightgreenG50: #f1fcd5;
    --colorBrandLightgreenG75: #ecfbc7;
    --colorBrandLightgreenG100: #e2f8ab;
    --colorBrandLightgreenG200: #d9f68f;
    --colorBrandLightgreenG300: #cff473;
    --colorBrandLightgreenG400: #aecf5d;
    --colorBrandTanT50: #f9f6f3;
    --colorBrandTanT75: #e5dbcf;
    --colorBrandTanT100: #dacbbb;
    --colorBrandTanT200: #cab59d;
    --colorBrandTanT300: #bfa689;
    --colorBrandTanT400: #867460;
    --colorBrandSinayaGreen: #002415;
    --colorBrandSinayaNeutralgrey: #eaeced;
    --colorBrandSinayaprioritasGreen: #002415;
    --colorBrandSinayaprioritasNeutralgold: #d39c60;
    --colorNeutralBlack: #000000;
    --colorNeutralWhite: #ffffff;
    --colorNeutralGreyG50: #f8f8f8;
    --colorNeutralGreyG75: #ececed;
    --colorNeutralGreyG100: #afb1b4;
    --colorNeutralGreyG200: #8e9195;
    --colorNeutralGreyG300: #5e6167;
    --colorNeutralGreyG400: #3d4148;
    --colorSemanticYellowY50: #fef8ec;
    --colorSemanticYellowY75: #fae3b2;
    --colorSemanticYellowY100: #f9d793;
    --colorSemanticYellowY200: #f6c664;
    --colorSemanticYellowY300: #f4ba44;
    --colorSemanticYellowY400: #ab8230;
    --colorSemanticBlueB50: #e6ecfe;
    --colorSemanticBlueB75: #96b2fb;
    --colorSemanticBlueB100: #6b92f9;
    --colorSemanticBlueB200: #2b63f7;
    --colorSemanticBlueB300: #0043f5;
    --colorSemanticBlueB400: #002fac;
    --colorBtpnTextHeadingDefault: var(--colorNeutralGreyG400);
    --colorBtpnTextHeadingPrimary: var(--colorBrandGreenG300);
    --colorBtpnTextHeadingInverse: var(--colorNeutralWhite);
    --colorBtpnTextBodyDefault: var(--colorNeutralGreyG400);
    --colorBtpnTextBodyPrimary: var(--colorBrandGreenG300);
    --colorBtpnTextBodyInverse: var(--colorNeutralWhite);
    --colorBtpnButtonPrimaryBgDefault: var(--colorBrandGreenG300);
    --colorBtpnButtonPrimaryBgHover: var(--colorBrandLightgreenG300);
    --colorBtpnButtonPrimaryFgDefault: var(--colorNeutralWhite);
    --colorBtpnButtonPrimaryFgHover: var(--colorNeutralWhite);
    --colorBtpnButtonSecondaryBgDefault: var(--colorBrandGreenG300);
    --colorBtpnButtonSecondaryBgHover: var(--colorBrandLightgreenG300);
    --colorBtpnButtonSecondaryFgDefault: var(--colorNeutralWhite);
    --colorBtpnButtonSecondaryFgHover: var(--colorNeutralWhite);
    --colorBtpnButtonTertiaryBgDefault: var(--colorBrandLightgreenG300);
    --colorBtpnButtonTertiaryFgDefault: var(--colorNeutralWhite);
    --colorBtpnBackgroundPrimary: var(--colorBrandGreenG300);
    --colorBtpnBackgroundSecondary: var(--colorBrandBrownB300);
    --colorBtpnBackgroundDefault: var(--colorNeutralWhite);
    --colorBtpnBackgroundInverse: var(--colorNeutralBlack);
    --colorBtpnNavbarBgPrimary: var(--colorBrandGreenG300);
    --colorBtpnNavbarBgSecondary: var(--colorBrandNeonN300);
    --colorBtpnNavbarBgDefault: var(--colorNeutralWhite);
    --colorBtpnNavbarTextDefault: var(--colorBrandGreenG300);
    --colorBtpnNavbarTextHover: var(--colorBrandLightgreenG300);
    --colorBtpnIconPrimary: var(--colorBrandGreenG300);
    --colorBtpnIconSecondary: var(--colorBrandBrownB300);
    --colorBtpnIconDefault: var(--colorNeutralBlack);
    --colorBtpnIconInverse: var(--colorNeutralWhite);
    --colorBtpnSeparatorDefaukt: var(--colorNeutralGreyG200);
    --colorBtpnSeparatorPrimary: var(--colorBrandGreenG300);
    --colorBtpnSeparatorSecondary: var(--colorBrandBrownB300);
    --colorBtpnDropdownBgDefault: var(--colorBrandGreenG300);
    --colorBtpnDropdownBgHover: var(--colorBrandLightgreenG300);
    --colorBtpnDropdownFgDefault: var(--colorNeutralWhite);
    --colorBtpnDropdownBorderHover: var(--colorSemanticBlueB300);
    --colorBtpnSearchBg: var(--colorBrandLightgreenG300);
    --colorBtpnSearchFg: var(--colorNeutralWhite);
    --colorBtpnSearchIcon: var(--colorBrandLightgreenG300);
    --colorBtpnTabBgActive: var(--colorBrandGreenG300);
    --colorBtpnTabBgDefault: var(--colorBrandBrownB50);
    --colorBtpnTabFgActive: var(--colorNeutralWhite);
    --colorBtpnTabFgDefault: var(--colorBrandBrownB300);
    --colorBtpnCardBgDefault: var(--colorNeutralWhite);
    --colorBtpnCardBgHover: var(--colorBrandGreenG300);
    --colorBtpnCardBgHover2: var(--colorBrandGreenG300);
    --colorBtpnFootercreditBg: var(--colorBrandGreenG300);
    --colorBtpnFootercreditFg: var(--colorNeutralWhite);
    --colorBtpnFooterhubungiBgDefault: var(--colorNeutralGreyG75);
    --colorBtpnFooterhubungiFgDefault: var(--colorBrandGreenG300);
    --colorBtpnFooterhubungiFgHeading: var(--colorBrandGreenG300);
    --colorBtpnFooterhubungiFgTelp: var(--colorBrandLightgreenG300);
    --colorBtpnDotsPrimary: var(--colorBrandGreenG300);
    --colorBtpnDotsSecondary: var(--colorBrandGreenG300);
    --colorBtpnCardmisiBgDefault: var(--colorNeutralGreyG50);
    --colorBtpnCardmisiBgHover: var(--colorBrandGreenG300);
    --colorBtpnCardmisiFgDefault: var(--colorNeutralGreyG100);
    --colorBtpnCardmisiFgHover: var(--colorNeutralWhite);
    --colorBtpnCardartikelTextheadingDefault: var(--colorNeutralWhite);
    --colorBtpnCardartikelTextheadingHover: var(--colorBrandLightgreenG300);
    --colorBtpnCardartikelTextdescHover: var(--colorNeutralWhite);
    --colorBtpnCardoverlayBgDefault: #cff47366;
    --colorBtpnPaginationBgActive: var(--colorBrandGreenG300);
    --colorBtpnPaginationBgDefault: var(--colorNeutralWhite);
    --colorBtpnPaginationFgActive: var(--colorNeutralWhite);
    --colorBtpnPaginationFgDefault: var(--colorBrandGreenG300);
    --colorBtpnCardlaporanBgDefault: var(--colorNeutralGreyG50);
    --colorBtpnCardlaporanFgDefault: var(--colorBrandGreenG300);
    --colorBtpnMegamenuTextDefault: var(--colorBrandGreenG300);
}
