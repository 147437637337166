.wysywyg-area {
	font-family: 'jenius-sans';
	// font-family: 'jenius-sans' !important;
	font-size: 1.3em;
	color: $neutral-400;
	line-height: 1.9;
	margin-bottom: 20px;

	h1 {
		@include font-jenius-serif;
		// font-family: 'Gotham-Bold' !important;
		font-size: 3em;
		color: $accent-300;
		margin-bottom: 15px;

		@include breakpoint(mobile) {
			font-size: 2em;
		}
	}
	h2 {
		@include font-jenius-serif;
		// font-family: 'Gotham-Bold' !important;
		font-size: 2.250em;
		color: $accent-300;
		margin-bottom: 15px;

		@include breakpoint(mobile) {
			font-size: 1.5em;
		}
	}

	h3 {
		@include font-jenius-serif;
		// font-family: 'Gotham-Bold' !important;
		font-size: 1.8em;
		color: $accent-300;
		margin-bottom: 15px;

		@include breakpoint(mobile) {
			font-size: 1.2em;
		}
	}

	h4 {
		@include font-jenius-serif;
		// font-family: 'Gotham-Bold' !important;
		font-weight: 600;
		font-size: 1.4em;
		color: $accent-300;
		margin-bottom: 15px;

		@include breakpoint(mobile) {
			font-size: 1em;
		}
	}

	h5 {
		@include font-jenius-serif;
		// font-family: 'Gotham-Bold' !important;
		font-size: 1em;
		color: $accent-300;
		margin-bottom: 15px;

		@include breakpoint(mobile) {
			font-size: 0.8em;
		}
	}

	h6 {
		@include font-jenius-serif;
		// font-family: 'Gotham-Bold' !important;
		font-size: 0.8em;
		color: $accent-300;
		margin-bottom: 15px;

		@include breakpoint(mobile) {
			font-size: 0.5em;
		}
	}

	p {
		font-family: 'jenius-sans';
		// font-family: 'jenius-sans' !important;
		position: relative !important;
		font-size: inherit !important;
		color: $neutral-300 !important;
		line-height: 1.9;
		margin: 0 0 10px !important;
		float: none !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		top: 0 !important;

		// br {
		// 	content: "";
		// 	line-height: 2;
		// }

		p {
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
			font-size: inherit !important;
		}

		span {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		strong {
			position: relative !important;
			font-weight: 800;
			float: none !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
		}


		em {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		small {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		i {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		b {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		sup {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		code {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		kbd {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		samp {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}
		abbr {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		mark {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		del {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		ins {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		q {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		cite {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		sub {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		sup {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		@include breakpoint(mobile) {
			font-size: 1.1em;
		}
	}

	span {
		position: relative !important;
		font-family: 'jenius-sans' !important;
		font-size: inherit !important;
		color: $neutral-300 !important;
		line-height: 1.9;
		font-weight: 400;
		float: none !important;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		top: 0 !important;

		strong {
			position: relative !important;
			font-weight: 800;
			float: none !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
		}


		em {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		small {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		i {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		b {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		sup {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		code {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		kbd {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		samp {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		abbr {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		mark {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		del {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		ins {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		q {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		cite {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		sub {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		sup {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		// br {
		// 	display: none;
		// }

		@include breakpoint(mobile) {
			font-size: 1.1em;
		}
	}

	strong {
		position: relative !important;
		font-family: 'jenius-sans';
		// font-family: 'jenius-sans' !important;
		font-size: inherit !important;
		color: $neutral-300;
		line-height: 1.9;
		font-weight: 800;
		left: 0 !important;
		right: 0 !important;
		bottom: 0 !important;
		top: 0 !important;


		em {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		small {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		i {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		b {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		sup {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		code {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		kbd {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}


		samp {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		abbr {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		mark {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		del {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		ins {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		q {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		cite {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		sub {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		sup {
			font-size: inherit !important;
			left: 0 !important;
			right: 0 !important;
			bottom: 0 !important;
			top: 0 !important;
			float: none !important;
		}

		@include breakpoint(mobile) {
			font-size: 1.1em;
		}
	}

	ul {
		display: block;
		margin: 0 20px 0;

		>li {
			font-family: 'jenius-sans';
			// font-family: 'jenius-sans';
			font-size: inherit !important;
			color: $neutral-300;
			line-height: 1.9;
			margin-bottom: 10px;

			p {
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
				font-size: inherit !important;
			}

			span {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			strong {
				position: relative !important;
				float: none !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
			}


			em {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			small {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			i {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			b {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			sup {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			code {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			kbd {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			samp {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			abbr {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			mark {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			del {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			ins {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			q {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			cite {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			sub {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			sup {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}
		}
	}

	ol {
		margin: 0 20px 0;

		>li {
			font-family: 'jenius-sans';
			// font-family: 'jenius-sans';
			font-size: inherit !important;
			color: $neutral-300;
			line-height: 1.9;
			margin-bottom: 10px;

			p {
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
				font-size: inherit !important;
			}

			span {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			strong {
				position: relative !important;
				float: none !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
			}


			em {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			small {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			i {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			b {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			sup {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			code {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			kbd {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}


			samp {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			abbr {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			mark {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			del {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			ins {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			q {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			cite {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			sub {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}

			sup {
				font-size: inherit !important;
				left: 0 !important;
				right: 0 !important;
				bottom: 0 !important;
				top: 0 !important;
				float: none !important;
			}
		}
	}

	img {
		float: none !important;
		display: block;
		max-width: 100%;
		height: auto !important;
		width: auto !important;

		&.pull-right {
			margin-left: 15px;
			margin-bottom: 15px;
		}

		&.pull-left {
			margin-right: 15px;
			margin-bottom: 15px;
		}
	}

	table {
		margin: 20px 0;
	}


	td {
		font-family: 'jenius-sans';
		// font-family: 'jenius-sans';
		font-size: 1.125em;
		color: $neutral-300;
		line-height: 1.9;
	}

	br {
		clear: both;
	}

	&.wysywyg-area {
		p {
			margin-bottom: 0;
		}
	}

	table {
		width: 100%;

		thead {
			th {
				background: #fdb813;
				// border-top-left-radius: 5px;
				// border-top-right-radius: 5px;
			}
		}

		th {
			padding: 15px;
			text-align: center;
			color: $white;
			font-family: 'jenius-sans';
			// font-family: 'jenius-sans';
			font-size: 1.2em;
			font-weight: lighter;
		}

		tr {
			td {
				font-family: 'jenius-sans';
				// font-family: 'jenius-sans';
				color: $neutral-300;
				font-size: 1em;
				font-weight: lighter;
				border: 1px solid #ededed;
				padding: 10px 20px;
				background: $white;

				p {
					font-size: inherit !important;
					margin-bottom: 0;
					line-height: 1.5;
				}
			}
		}

		&.inner {
			tr {
				td {
					border: none;
					padding: 0 4px;
					vertical-align: top;
					line-height: 1.8;
				}
			}
		}
	}

	&.wysywyg-area-2 {
		p {
			font-size: 1em !important;

			&:blank {
				display: none;
			}
		}

		span {
			font-size: 1em !important;
		}

		ol {
			>li {
				font-size: 1em !important;
			}
		}

		ul {
			>li {
				font-size: 1em !important;
			}
		}
	}

}

.text-area-profile {
	height: 300px;
	overflow: auto;

	@include breakpoint(mobile) {
		height: auto;
	}

	.wysywyg-area {
		font-size: 1em;
	}
}