// use this if using supernova
// @import "../../../design-system/node_modules/@salt-salty/design-system.style/colors.css";

// use this if using manual script converter from figma token studio json into css
// https://gitlab.com/yazidzm010101/figma-token-to-css
@import "./colors.scss";

// FONT FAMILY
$font_idx: 1;
$font_default: 'jenius-sans', 'Helvetica', Arial, sans-serif !default;

// Font Family Ex. (name, 'fonts/path')
$font_family: (
    Gotham-Bold: 'fonts/Helvetica-Neue-Bold',
    Gotham-Book: 'fonts/HelveticaNeue-Regular',
    Gotham-Light: 'fonts/Helvetica-Neue-Light',
    Helvetica: 'fonts/Helvetica-Neue',
    Helvetica-Oblique: 'fonts/Helvetica-Oblique',
    Helvetica-LightOblique: 'fonts/Helvetica-LightOblique',
    Helvetica-BoldOblique: 'fonts/Helvetica-BoldOblique',
    Helvetica-Bold: 'fonts/Helvetica-Neue-Bold',
    Helvetica-Regular: 'fonts/Helvetica-Neue',
    Helvetica-Light: 'fonts/Helvetica-Neue',
    helveticaltstd-light: 'fonts/Helvetica-Neue',
    NellaSueDemo: 'fonts/NellaSueDEMO',
    Verdana: 'fonts/Verdana',
    FontAwesome: 'fonts/fontawesome-webfont',
);


//COLORS

$primary-50: var(--colorBrandOrangeO50);
$primary-75: var(--colorBrandOrangeO75);
$primary-100: var(--colorBrandOrangeO100);
$primary-200: var(--colorBrandOrangeO200);
$primary-300: var(--colorBrandOrangeO300);
$primary-400: var(--colorBrandOrangeO400);

$secondary-50: var(--colorSemanticYellowY50);
$secondary-75: var(--colorSemanticYellowY75);
$secondary-100: var(--colorSemanticYellowY100);
$secondary-200: var(--colorSemanticYellowY200);
$secondary-300: var(--colorSemanticYellowY300);
$secondary-400: var(--colorSemanticYellowY400);

$accent-50: var(--colorBrandGreenG50);
$accent-75: var(--colorBrandGreenG75);
$accent-100: var(--colorBrandGreenG100);
$accent-200: var(--colorBrandGreenG200);
$accent-300: var(--colorBrandGreenG300);
$accent-400: var(--colorBrandGreenG400);

$tertiary-50: var(--colorBrandLightgreenG50);
$tertiary-75: var(--colorBrandLightgreenG75);
$tertiary-100: var(--colorBrandLightgreenG100);
$tertiary-200: var(--colorBrandLightgreenG200);
$tertiary-300: var(--colorBrandLightgreenG300);
$tertiary-400: var(--colorBrandLightgreenG400);

$neutral-50: var(--colorNeutralGreyG50);
$neutral-75: var(--colorNeutralGreyG75);
$neutral-100: var(--colorNeutralGreyG100);
$neutral-200: var(--colorNeutralGreyG200);
$neutral-300: var(--colorNeutralGreyG300);
$neutral-400: var(--colorNeutralGreyG400);

$selection-background-color: $tertiary-75;
$selection-color: $accent-300;


// Others Colors
$red: #ec644b; // -> trad green
$green: #5CB85C; 
$light-green: #90c695;
// $blue: #4183d7; 
$blue: #3ed3dc; // -> light blue
$white: #ffffff;
$black: #000000;

$font_default: 'jenius-sans';
$root-font-family: 'jenius-sans';



// BUTTONS
// Default Button Ex. (button-class, background-code, font-color)
$buttons: (
    'btn-red',
    $red,
    #FFFFFF),
    ('btn-blue', $blue, #FFFFFF),
    ('btn-green', $green, #FFFFFF
);

// text-align
$align: left center right;

// vertical-align
$vertical-align: top middle bottom;

// float
$float: left right;

// position
$position: top right bottom left;
// media-query
$media-query: 
    (laptop, (min-width: 1366px)),
    (desktop, (min-width: 1200px)),
    (desktop-down, (max-width: 1200px)),
    (wide, (min-width: 992px)),
    (wide-down, (max-width: 991px)),
    (tablet, (min-width: 768px) and (max-width: 991px)),
    (tablet-down, (max-width: 768px)),
    (mobile, (max-width: 767px)),
    (small-mobile, (max-width: 360px)),
    (iphone, (max-width: 320px)
);