@import "bourbon";

/* ABSTRACTS */
@import
    '../Abstracts/variables',
    '../Abstracts/functions',
	'../Abstracts/mixins',
	'../Base/fonts',
	'../Base/fonts-icon'; 

.block-tabs-stories,
.block-tabs-training {
	.nav {
		padding: 0;
		margin: 0;
		display: block;

		>li {
			float: none;
			padding: 10px 0;
			display: inline-block;
			width: 48%;
    		text-align: center;
    		margin: 0 10px;
    		border-bottom: 1px solid $accent-300;

    		@include breakpoint(mobile) {
    			padding: 5px 0;
    			margin: 0 3px;
    			width: 46%;
    		}

    		&:first-child {
    			margin-left: 0;
    			float: left;
    		}

    		&:last-child {
    			margin-right: 0;
    			float: right;
    		}

			>a {
				font-family: 'jenius-sans';
				font-size: 2em;
				color: $accent-300;
				text-transform: uppercase;

				@include breakpoint(mobile) {
					font-size: 1.2em;
				}

				&:hover {
					background: initial;
				}
			}

			&.active {
				border-bottom: none;
				position: relative;

				&:after {
					position: absolute;
					content: "";
					left: 0;
					right: 0;
					bottom: 0;
					height: 10px;
					background: $tertiary-300;

					@include breakpoint(mobile) {
						height: 5px;
					}
				}
				a {
					color: $accent-300;
					background-color: initial;
					font-weight: 800;

					&:focus {
						color: $tertiary-300;
						background-color: initial;
					}
				}
			}
		}
	}

	.tab-content {
		margin-top: 40px;

		@include breakpoint(mobile) {
			margin-top: 30px;
		}
	}

	.thumbnails-default {
		article {
			&.post-thumbnails {
				width: 100%;

				&:first-child {
					margin-left: 0;
				}

				.heading-Alt {
					h4 {
						text-transform: capitalize;
					}
				}
			}
		}

		.btn-area {
			.btn-thirdary {
				width: 100%;
				display: inline-block;
			}
		}
	}

}

.block-tabs-stories {
	.thumbnails-default {
		.post-thumbnails {
			&:last-child {
				@include breakpoint(mobile) {
					margin-bottom: 15px;
				}
			}
		}
	}
	@include breakpoint(mobile) {
		margin-bottom: 30px;
	}
}

.video-wrap {
	&.video-fullwidth {
		@include breakpoint(mobile) {
			margin: 0 -15px;
		}
	}
}

.block-tabs-training {
	.heading {
		@include breakpoint(mobile) {
			text-align: center;
		}
	}
	.nav {
		border-bottom: 1px solid #8b837a;
		>li {
			border-bottom: none;
			width: auto;
			padding: 5px 0;

			&:first-child {
				float: none;
			}

			&:last-child {
				float: none;
			}

			&.active {
				>a {
					font-family: 'jenius-sans';
					font-weight: 600;
				}
				&:after {
					bottom: -2px;
					height: 3px;
				}
			}

			>a {
				padding-left: 0;
				font-size: 1.1em;

				@include breakpoint(mobile) {
					font-size: 0.8em;
    				padding: 10px 5px;
				}
			}
		}
	}

	.list-training {
		margin-bottom: 40px;
	}
	.item-training {
		padding: 15px 0;
		position: relative;
		border-bottom: 1px solid #e5e5e5;

		&:last-child {
			border-bottom: none;

			@include breakpoint(mobile) {
				border-bottom: 1px solid #e5e5e5;
			}
		}

		.checklist {
			display: inline-block;
			vertical-align: top;
			margin-right: 15px;
    		margin-left: 15px;
		}
		.text {
			a {
				text-decoration: none;
			}
			
			&.text-category {
				width: 85%;
				display: inline-block;

				@include breakpoint(mobile) {
					width: 80%;
				}

				h3 {
					font-family: 'jenius-sans';
					font-size: 1em;
					font-weight: 600;
					text-transform: uppercase;
				}

				&.blue {
					h3 {
						a {
							$blue: #3ed3dc;
							color: $blue;
						}
					}
				}

				&.dark-green {
					h3 {
						a {
							// color: $accent-300;
							color: $neutral-300;
						}
					}
				}

				&.light-green {
					h3 {
						a {
							// color: $light-green;
							color: $tertiary-300;
						}
					}
				}

				&.red {
					h3 {
						a {
							// color: $red;
							color: $accent-300;
						}
					}
				}
			}

			&.text-title {
				width: 90%;

				@include breakpoint(mobile) {
					width: 80%;
    				margin-left: 46px;
				}

				h3 {
					font-family: 'jenius-sans';
					font-size: 1em;
					font-weight: 400;
					line-height: 1.5;

					a {
						color: $neutral-400;
					}
				}
			}

			.date {
				position: absolute;
				right: 20px;
				top: 0;
				font-family: 'jenius-sans';
				font-size: 1em;
				font-weight: 400;
				color: $neutral-200;

				@include breakpoint(mobile) {
					top: -20px;
				}
			}
		}
	}

	&.block-tabs-ahli {
		.tab-content {
			margin-top: 0;
		}
		.nav {
			border-bottom: none;
			>li {
				border-bottom: none;
				width: 49%;
				padding: 5px 0;
				border-bottom: 1px solid $accent-300;
				margin: 0;

				&:first-child {
					margin-right: -4px;
				}
	    		
	    		&:last-child {
	    			margin-left: -2px;
	    		}

	    		@include breakpoint(mobile) {
	    			width: 50%;
	    		}
			}
		}
	}
}