/* FLOATING TANYA AHLI */
/* ---------------- */

.floating-tanya-ahli {
  position: fixed;
  bottom: 200px;
  right: 0;
  z-index:9;
  cursor:pointer;

  @media screen and (max-width:768px){
    bottom: 8rem;
  }

  img {
    transition:0.25s ease-in-out;
    width: 200px;
    
    @media screen and (max-width:768px){
      width: 150px;
    }
  }


  &:hover {
    img {
      width: 250px;
    }

  }
}

/* MODAL TANYA PERSONA */
/* ---------------- */
#modal-persona {
  .text-orange {
    color: $accent-300;
  }
  .instruction {
    line-height:2;
  }
}

.dropdown-trigger-custom {
  .dropdown-toggle {
    background: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    outline:none !important;
    background:#fff!important;
    box-shadow: none !important;
    display:block;
    width:100%;
    padding-top:0;

    .caret {
      position: absolute;
      right: 10px;
      top: 45%;
    }
  }
  .dropdown-menu {
    padding:0;

    li {
      margin-bottom:0;
      padding:20px;
      border-bottom:1px solid #ddd;

      p {
        color: $accent-300;
        margin:0;
      }

      span {
        color: $accent-300;
        font-size:10px;
      }

      &:hover {
        background: #eee;
      }
    }
  }

  &.box {
    max-width:380px;

    .dropdown-toggle {
      border: 1px solid #ccc;
      padding: 15px;
    }

    .dropdown-menu {
      width: 100%;
      border-radius: 0;
      max-height: 335px;
      overflow: auto;
    }

    .ahli-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        margin:0;
        color: $accent-300;
      }

      img {
        display:block;
        margin-right:20px;
        max-width: 50px;
      }

      span {
        white-space: pre-wrap;
      }
    }
  }
}

#modal-tanya-ahli {
  h5 {
    color: $accent-300;
    margin-bottom:10px;
  }
  textarea {
    background: #ebebeb;
    border-radius: 0;
    outline: none!important;
    resize: none;
  }
}


/* PRODUK PERBANKAN */
/* ---------------- */

.produk-perbankan {
  position: relative;
  background: url('../img/persona/bg-kebutuhan-menabung.jpg') no-repeat;
  background-size: 100% 100%;
  text-align:center;
  align-items:center;
  display:flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden; 
  padding: 2rem 2rem !important;

  @include breakpoint(mobile) {
    padding: 0.5rem 1rem !important;
  }

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;    
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  @include breakpoint(mobile){
    background-image: url('../img/persona/bg-kebutuhan-menabung-xs.png');
  }

  h3 {
    max-width: 1024px;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    @include breakpoint(mobile){
      margin-top: 2rem;
    }
  }

  > * {
    position: relative;
  }
}

/* AKSES PASAR */
/* ---------------- */
.akses-pasar {
  h2 {
    color: $accent-300;
    margin-bottom:15px;
  }
  .thumbnail {
    text-align: center;
    border: 1px solid #eee !important;
    border-radius: 0px;
    box-shadow: 0px 0px 10px #eee;
    padding: 20px;
    outline: none!important;
    text-decoration: none;

    h3 {
      @include font-jenius-serif;
      color: $accent-300;
      margin: 15px 0;
    }

    p {
      color: $neutral-300;
      line-height: 1.5;
    }

    &:hover {
      * {
        outline:none;
        text-decoration: none;
      }
    }
  }
  .btn-primary {
    @include breakpoint (mobile) {
      width: 80%;
    }
  }
}

.business-list {
  a {
    border:1px solid $secondary-300;
    background-color: $secondary-300;
    padding:20px;
    width: 150px;
    height:100px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    text-align: center;
    border-radius:6px;
    margin:0 20px 5px 0;

    @include breakpoint(mobile){
      margin-bottom:15px;
    }

    &:hover {
      color: #fff;
      text-decoration: none;
      background: #cb8f00;
    }
  }
}

/* PELATIHAN ONLINE TERKAIT, ARTIKEL TERKAIT */
/* ----------------------------------------- */
.related-list {
  &-item {
    width: 40%;
    display: inline-block;
    margin-bottom:20px;
    margin-right: 8%;
    border-bottom: 1px solid #ccc;
    color: #333;
    text-decoration: none;

    @include breakpoint(mobile){
      width: auto;
      display: block;
      margin:0;
      padding: 12px 0 10px;
    }

    h4, h3 {
      @include font-jenius-serif;
      font-size: 14px;
      margin-bottom:5px;
    }

    h4, h4 * {
      font-family: 'jenius-sans';
    }

    p {
      color: $neutral-200;

      .view-count {
        border-left: 1px solid $neutral-75;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        padding-left: 5px;

        img {
          vertical-align: top;
        }
      }
    }
  }
}

.modal-connect {
  .btn-area {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-header,
  .modal-footer {
    border:none;
  }
  .modal-body {
    padding:0;

    p {
      line-height: 2;

      @include breakpoint(mobile){
        font-size: 3vw;
      }

    }
  }
  .modal-footer {
    padding-top:0;
  }
  h4 {
    color: #7c6a55;
    line-height: 1.3;
    font-size: 24px;

    @include breakpoint(mobile){
      font-size:5vw;
    }
  }
  .close {
    float: right;
    font-size: 31px;
    font-weight: normal;
    line-height: 1;
    color: #f8b834;
    text-shadow: 0 1px 0 #fff;
    opacity: 1;
  }
  .modal-idt{
    margin: 20vh auto;
    @include breakpoint(mobile){
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.modal-thanks{
  .modal-thx{
    margin: 20vh auto;
    @include breakpoint(mobile){
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.iframe-responsive {
    overflow: hidden;
    height: 2000px;
    width: 100%;

    @media screen and (max-width: 768px){
      height: 7200px;
    }

    @media screen and (max-width: 330px){
      height: 6500px;
    }
}

.iframe-form {
    overflow: hidden;
    height: 1000px;
    width: 100%;
    // min-height: 80vh;
    // max-height: 1000px;
    &.frm-pinjaman{
      position: relative;
      height: 700px;
      // max-height: 2200px;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      overflow: scroll;
      overflow-x: hidden;
      margin-left: 1rem;
      margin-top: -10px;
      &::-webkit-scrollbar {
          width: 0px;
          background: transparent; /* Chrome/Safari/Webkit */
        }
        @media screen and (max-width: 768px){
          margin-top: 2rem;
          margin-left: 0px;
        }
    }
    @media screen and (max-width: 768px){
      height: 1100px;
    }
}

.wrraper-iframe-tabungan{
  @media screen and (max-width: 768px){
    height: 1100px;
    width: 100%;
  }
}

.wrapper-iframe{
  width: 100%;
  overflow: hidden;
  background-color: #f8f8f8;
}



.business-matching-list {
  li {
    border-bottom:1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:10px 0;

    p {
      margin:5px 0 0 0;
    }

    .btn {
      min-width: 180px;

      @include breakpoint(mobile){
        min-width: 110px;
      }
    }
  }
}

.update-profile-action {
  ul {
    display:flex;
    align-items:center;
    justify-content:center;

    li {
      list-style:none;
      margin:0 5px;
    }
  }
}
