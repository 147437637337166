/* The loading spinner container */
.loading {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);
    // opacity: 0.3;
}

.loading-wheel {
    border: 8px solid #f3f3f3; /* Light grey background */
    border-top: 8px solid #fabb05; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite; /* Spin animation */
    margin: 0 auto; /* Center the spinner */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include breakpoint(mobile) {
        left: 40%;
        top: 45%;
    }
}

/* Spin animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
