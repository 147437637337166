.container-fluid {
	&.header-produk-keuangan {
		margin-top: 86px;
		padding-left: 0px;
		padding-right: 0px;

		@media screen and (max-width:768px){
			margin-top: 60px;
		}

		.image-banner {
			position: relative;

			.image-banner-overlay {
				position: absolute;
				width: 100%;
				height: 100%;
			
				background: rgba(0,0,0,0.82);
				background: -moz-linear-gradient(top, rgba(0,0,0,0.82) 0%, rgba(0,0,0,0.49) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.82)), color-stop(100%, rgba(0,0,0,0.49)));
				background: -webkit-linear-gradient(top, rgba(0,0,0,0.82) 0%, rgba(0,0,0,0.49) 100%);
				background: -o-linear-gradient(top, rgba(0,0,0,0.82) 0%, rgba(0,0,0,0.49) 100%);
				background: -ms-linear-gradient(top, rgba(0,0,0,0.82) 0%, rgba(0,0,0,0.49) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0.82) 0%, rgba(0,0,0,0.49) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d361c', endColorstr='#7c6a55', GradientType=0);
			}
			

			img {
				// object-fit: contain;
				width: 100%;
				height: 250px;
				object-fit: cover;
			}

			h2 {
				position: absolute;
				bottom: 15px;
				color: #FFF;
				font-size: 2em;
				left: 10%; 
				@media screen and (max-width: 768px) {
					font-size: 2em;
					margin-left: 20px;
					left: 0;
				}
			}
		}
	}

	&.perhitungan-pinjaman, &.section-ajukan {
		background-color: $neutral-50;

		.container {
			&.perhitungan-pinjaman-konten {
				@media screen  and (max-width:768px){
					padding-left: 0px;
					padding-right: 0px;
				}

				h2 {
					color: $accent-300;
					padding: 50px 50px 30px 50px;
					text-align: center;
					font-family: 'jenius-sans';
					font-size: 3em;

					@media screen and (max-width:768px){
						font-size: 7.5vw;
						padding: 0px;
						line-height: 1.3;
						padding: 20px 0px;
					}
				}

				p {
					color: #000;
					font-size: 1.2em;
					text-align: center;
					margin-bottom: 30px;

					@media screen  and (max-width:768px){
						font-size: 3.7vw;
					}
				}

				.form-inline {
					margin-bottom: 50px;

					@media screen  and (max-width:768px){
						padding-left: 15px;
						padding-right: 15px;
					}

					.perhitungan-pinjaman-form {
						margin-bottom: 30px;

						.jenis-rate {
							.jcf-select-text {
								display:none;
							}
							select {
								position:static;
								opacity:1;
								border:0;
								//font-size:8px;
							}
						}

						@media screen  and (max-width:768px){
							margin-bottom: 10px;
						}
	
						.form-group {
							margin-bottom: 15px;
							@media screen  and (max-width:768px){
								margin-bottom: 10px;
							}

							p {
								color: #000;
								font-size: 1em;
								margin-bottom: 0px;
								text-align: left;

								@media screen  and (max-width:768px){
									font-size: 3vw;
								}
							}
	
							.input-group {
								max-width: 100%;
								width: 100%;
	
								.form-control {
									padding-left: 10px;
									@media screen and (max-width:768px){
										font-size: 2.3vw;
									}
								}

								//custom select option
								select {
									cursor: pointer;
									opacity:1;
									border:none;
									

									option {
										&.selected-custom {
											@media screen and (max-width:768px){
												font-size: 2.3vw;
											}
										}
									}
								}

								#rate {
									@media screen and (max-width:768px){
										font-size: 2.3vw;
									}
								}

								span {
									&.jcf-select {
										&.jcf-unselectable {
											&.jcf-select-form-control{
												height: 50px;
												width: 100%;
												margin-bottom: 0px;
												border: 1px solid #d5d5d5;

												@media screen and (max-width:768px){
													font-size: 2.3vw;
												}


												.jcf-select-text {
													margin: 0px;
													padding: 10px;
													opacity: 1;

													.jcf-option-selected-custom  {
														opacity: 0.5;
													}
												}

												.jcf-select-opener {
													background-color: #FFF;
													background: url(../img/icon-arrow-down.png) no-repeat;
													position: absolute;
													top: 35%;
												}

												&.jcf-focus {
													border: 1px solid #d5d5d5!important;

													.jcf-select-opener {
														background-color: #FFF;
														background: url(../img/icon-arrow-up.png) no-repeat;
														position: absolute;
														top: 35%;
													}
												}

											}
										}
									}
								}

								&.jenis-suku {
									max-width: 100%;
									margin-right: 10px;

									// @media screen and (max-width:768px){
									// 	display: inline-flex;
									// }

									.jcf-select-text {
										@media screen and (max-width:768px){
											font-size: 3vw;
										}
									}

									img {
										@media screen and (max-width:768px){
											padding-top: 10px;
										}
									}
									
								}

								.addon-img {
									width: 100px;
									@media screen  and (max-width:768px){
										width: 55px;
									}
								}

								.input-group-addon {
									@media screen  and (max-width:768px){
										width: 55px;
									}
								}
							}

							span {
								&.has-error {
									display: none;
									float: right;
									color: red;

									@media screen  and (max-width:768px){
										display: block;
										opacity: 0;
										height: 30px;
									}

									&.active {
										display: block;
										@media screen  and (max-width:768px){
											display: block;
											float: left;
											color: red;
											opacity: 1;
											font-size: 3vw;
										}
									}								
								}
							}
						}
					}

					.btn-hitung {
						text-align: center;

						button {
							padding: 5px 40px;
							background-color: $tertiary-300;
							border: 1px solid transparent;
							-webkit-border-radius: 30px;
							-moz-border-radius: 30px;
							border-radius: 30px;
							color: $accent-300;
							font-family: 'jenius-sans';
							font-size: 1.2em;
							transition: .2s all ease-in-out;
							&:focus,
							&:hover {
								color: $white;
								background-color: $accent-300;
								border: 1px solid $accent-300;
							}

							@media screen and (max-width:768px){
								font-size: 4vw;
								margin-left: auto;
    							margin-right: auto;
							}
						}
					}
				}

				.perhitungan-angsuran-pinjaman {
					margin-bottom: 50px;
					background-color: #FFF;
					-webkit-box-shadow: -2px 3px 44px -7px rgba(0,0,0,1);
					-moz-box-shadow: -2px 3px 44px -7px rgba(0,0,0,1);
					box-shadow: 0px 0px 33px -10px rgba(0,0,0,1);

					@media screen  and (max-width:768px){
						height: 55%;
					}

					h4 {
						padding: 40px;
						text-align: center;
						font-size: 1.8em;
						color: #000;
						font-family: 'jenius-sans';

						@media screen and (max-width:768px){
							    padding: 30px 0px 20px 0px;
    							font-size: 4vw;
						}
					}

					p {
						color: #484848;

						@media screen and (max-width:768px){
							line-height: 3;
							margin: 0px;
						}
					}

					.hasil {
						text-align: center;
						h3 {
							color: $accent-300;
							font-size: 2.8em;
							margin-bottom: 10px;
							@media screen and (max-width:768px){
								font-size: 7.5vw;
							}
						}
						p {
							color: #888888;
							font-size: 1.1em;
							@media screen and (max-width:768px){
								font-size: 3vw;
							}
						
						}
						sup {
							position: static;
						}
					}

					.perhitungan-berikutnya {
						margin-bottom: 30px;
						text-align: center;

						a {
							color: #4D361C;
							text-decoration: underline;
							font-size: 1.2em;
							@media screen and (max-width:768px){
								font-size: 3vw;
							}
						}
					}
					

					.btn-perhitungan-wrapper {
						margin-bottom: 30px;
						text-align: center;
						a {
							font-family: 'jenius-sans';
							border-radius: 30px;
							padding: 10px 30px;

							&:hover {
								text-decoration: none;
							}

							&.btn-white-gray {
								margin-right: 10px;
								@media screen and (max-width:768px){
									padding: 10px 55px;
								}
							}
						}
						
					}
				}


			}

			&.ajukan-konten {
				@media screen  and (max-width:768px){
					padding-left: 0px;
					padding-right: 0px;
				}

				h2 {
					color: $accent-300;
					padding: 50px 50px 30px 50px;
					text-align: center;
					font-family: 'jenius-sans';
					font-size: 2em;
					font-weight: 800;

					@media screen and (max-width:768px){
						font-size: 7.5vw;
						padding: 0px;
						line-height: 1.3;
						padding: 20px 20px;
					}
				}

				p {
					color: #000;
					font-size: 14px;
					text-align: center;
					margin-bottom: 30px;

					@media screen  and (max-width:768px){
						font-size: 3.7vw;
					}
				}

				.btn-ajukan {
					text-align: center;
					margin-bottom: 50px;
					@media screen  and (max-width:768px){
						padding: 0px 50px;
						margin-bottom: 0px;
					}

					a {
						margin-right: 20px;
						@media screen  and (max-width:768px){
							margin-right: 0px;
						}

						img {
							width: 150px;
							@media screen  and (max-width:768px){
								margin-bottom: 10px;
							}
						}
					}
				}
			}
		}
	}

	&.section-ajukan-pinjaman {
		background-color: #FFF;

		.ajukan-pinjaman-konten {
			text-align: center;

			h2 {
				color: $accent-300;
				padding: 50px 50px 30px 50px;
				text-align: center;
				font-family: 'jenius-sans';
				font-size: 2em;
				font-weight: 800;

				@media screen and (max-width:768px){
					font-size: 7.5vw;
					padding: 0px;
					line-height: 1.3;
					padding: 20px 20px;
				}
			}
			.ajukan-pinjaman {
				margin-bottom: 50px;
			}
		}
	}

	&.pengajuan-produk {
		background: url(../img/bg-form-pengajuan.png) no-repeat;
		background-size: cover;

		.container {
			&.pengajuan-produk-konten {
				text-align: center;
				padding: 0px 150px 0px 150px;
				color: #FFF;

				@media screen and (max-width:768px){
					padding: 0px;
				}

				h2 {
					font-size: 2.5em;
					padding: 50px;

					@media screen and (max-width:768px){
						padding: 30px 0px 30px 0px;
						font-size: 8vw;
						font-family: 'jenius-sans';
					}
				}

				p {
					font-size: 1.2em;
					margin-bottom: 50px;

					@media screen and (max-width:768px){
						font-size: 4.5vw;
					}
				}

				.pengajuan-produk-form{
					//margin-bottom: 30px;

					@media screen and (max-width:768px){
						margin-bottom: 0px;
					}

					.form-group {

						@media screen and (max-width:768px){
							padding-left: 10px;
    						padding-right: 10px;
						}

						.input-group {
							.input-custom-mobile {
								@media screen and (max-width:768px){
									max-width: 68vw;
									min-width: 68vw;
								}
							}

							.input-group-addon {
								padding: 0px 20px;
								font-size: 30px;

								@media screen and (max-width:768px){
									padding: 0px;
								}

								img {
									max-width: 30px;
									
									@media screen and (max-width:768px){
										height: 25px;
									}
								}

								&.kota-madya {
									font-size: 20px;
									
									span {
										&.jcf-select {
											&.jcf-unselectable {
												&.jcf-select-form-control{
													.jcf-select-text {
														font-size: 3vw!important;
													}
												}
											}
										}
									}
									
								}

								&.provinsi {
									padding: 0px 25px;
								}

								.fa {
									opacity: 0.5;
									@media screen and (max-width:768px){
										// &.fa-envelope-o {
											font-size: 7.2vw;
										// }
										&.fa-user {
											font-size: 10vw;
										}
										&.fa-phone {
											font-size: 9.2vw;
										}
										&.fa-map-o {
											font-size: 6.2vw;
										}
									}
								}
							}

							.form-control {
								padding-left: 30px;
							}

							//custom select option
							select {
								cursor: pointer;
							}

							&.input-provinsi {
								span {
									
									&.jcf-select {
										&.jcf-unselectable {
											&.jcf-select-form-control{

												@media screen and (max-width:768px){
													max-width: 68vw;
													min-width: 68vw;
												}

												.jcf-select-text {
													padding-right: 11px;
												}
											}
										}
									}
								}
							}

							span {
								&.jcf-select {
									&.jcf-unselectable {
										&.jcf-select-form-control{
											height: 50px;
											width: 100%;
											margin-bottom: 0px;
											border: 1px solid #d5d5d5;

											.jcf-select-text {
												color: #717171;
												text-align: left;
												padding: 10px 23px;
												font-family: 'jenius-sans';
												font-size: 1.125em;
												opacity: 1;

												.jcf-option-selected-custom  {
													opacity: 0.5;
													@media screen and (max-width:768px){
														font-size: 3vw!important;
													}
												}
											}

											.jcf-select-opener {
												background-color: #FFF;
												background: url(../img/icon-arrow-down.png) no-repeat;
												position: absolute;
												top: 35%;
											}

											&.jcf-focus {
												border: 1px solid #d5d5d5!important;

												.jcf-select-opener {
													background-color: #FFF;
													background: url(../img/icon-arrow-up.png) no-repeat;
													position: absolute;
													top: 35%;

													@media screen and (max-width:768px) {
														background: url(../img/icon-arrow-up.png) no-repeat;
													}
												}

												// &.jcf-drop-active{
												// 	.jcf-select-opener {
												// 		background-color: #F0F0F0;
												// 		background: url(../img/icon-arrow-up.png) no-repeat;
												// 		position: absolute;
												// 		top: 35%;
		
												// 		@media screen and (max-width:768px) {
												// 			background-color: #F0F0F0;
												// 			background: url(../img/icon-arrow-up.png) no-repeat;
												// 			position: absolute;
												// 			top: 35%;
												// 		}
												// 	}
												// }
											}

										}
									}
								}
							}

							
						}
					}
				}

				.syarat-ketentuan {
					padding: 0px 150px;
					margin-bottom: 30px;
					font-family: 'jenius-sans';
					font-size: 1.2em;

					@media screen and (max-width:768px){
						padding: 0px;
						margin: 0px;
						margin-bottom: 20px;
					}

					.input-checbox-mobile {
						padding-left: 35px;
					}

					.deskripsi-ketentuan {
						text-align: left;
						padding: 0px 35px 0px 5px;
						p {
							margin: 0px;
						}
					}
				}

				.deskripsi-link {
					margin: 10px 0px;
					font-size: 5vw;

					@media screen and (max-width:768px){
						padding-left: 15px;
						padding-right: 15px;
						font-size: 4.5vw;
					}

					a {
						color: #FDB916;
					}
				}

			}
		}
	}
}


.input-group-addon {
	background-color:#FFF;

	@media screen and (max-width:768px){
		width: 55px;
	}
}


//button custom
.btn-gold-white {
	padding: 10px 50px;
    background-color: $tertiary-300;
    border-radius: 50px;
	margin: 30px auto;
	color: $accent-300;
	font-family: 'jenius-sans';
	text-transform: uppercase;
	cursor: pointer;
	transition: .2s all ease-in-out;

	&.disabled {
		opacity: 0.4;
		cursor: pointer;
	}

	&:hover {
		color: $white;
		text-decoration: none;
		background-color: $accent-300;
		border: 1px solid $accent-300;
	}
	&:focus {
		color: $white;
		background-color: $accent-300;
		border: 1px solid $accent-300;
	}

	@media screen and (max-width:768px){
		margin: 0px;
		padding: 10px 45px!important;
		font-size: 3.5vw;
	}

	&.btn-gold-white-landing {
		@media screen and (max-width:768px){
			width: 100%;
    		padding: 8px!important;
		}
	}
}

.btn-white-gray {
	color: $secondary-300;
	border: 1px solid $secondary-300;
	font-family: 'jenius-sans';
	border-radius: 30px;
    padding: 10px 30px;

	&:hover {
		color: #fff;
		background-color: $secondary-300;
		text-decoration: none;
	}

	@media screen and (max-width:768px){
		margin: 0px;
		font-size: 3.5vw;
	}
}
//end button custom

//Container
.container {
	.upper-content {
		color: #FFF;

		.directory {
			position: absolute;
			top: 10px;

			@media screen and (max-width: 768px) {
				font-size: 2.5vw;
			}

			span > a {
				color: #FFF;
				font-style: italic;
			}
		}

		// h2 {
		// 	position: absolute;
		// 	top: 13%;
		// 	color: #FFF;
		// 	font-size: 2.5em;
		// 	@media screen and (max-width: 768px) {
		// 		top: 10%;
		// 		font-size: 8vw;
		// 	}
		// }
	}

	&.detail-produk-keuangan {
		margin-top: 30px;
    	margin-bottom: 30px;
		
		.konten-detail {
			.deskripsi {
				margin-bottom: 30px;

				p {
					margin-left: 80px;
					line-height: 2;
					color: $neutral-300;
					font-size: 1.2em;

					@media screen and (max-width:768px){
						margin: 0px 2%;
						font-size: 4vw;
						margin-bottom: 20px;
					}
				}
			}
	
			.konten-detail-group {
				ul {
					font-family: 'jenius-sans';
					font-size: 1.2em;
					margin-bottom: 30px;

					@media screen and (max-width:768px){
						font-size: 3vw;
    					display: flex;
					}

					&.group-kredit {
						li {
							// border: none;
							font-size: 1.8em;
							font-weight: normal;
							padding-right: 30px;
							
							&.cara-mendaftar &.active {
								padding-right: 30px;
							}

							a {
								border-bottom: 1px solid #000;
								color: $accent-300;
								padding-bottom: 10px;

								@media screen and (max-width:768px){
									border-bottom: 1px solid $accent-300;
								}
							}

							&.active > a {
								border-bottom: 10px solid $tertiary-300;
								border-top: none;
								border-left: none;
								border-right: none;
								color: $accent-300;
								padding-bottom: 0px;
							}
							
						}
					}
				}
	
				.tab-content {
					.tab-pane {
						padding-left: 100px;

						@media screen and (max-width:768px){
							padding-left: 20px;
						}

						ul {
							@media screen and (max-width:768px){
								display: block;
							}
							li {
								font-size: 18px;
								&.content-caption {
									font-weight: bold;
									color: $accent-300;

									@media screen and (max-width:768px){
										// padding-left: 20px;
										font-size: 5vw;
									}

									ul {
										padding-left: 20px;

										li {
											&.content-description {
												font-weight: normal;
												color: #000;

												@media screen and (max-width:768px){
													font-size: 4vw;
													display: list-item!important;
												}
											}
										}
									}
								}
							}
						}

						h4 {
							font-weight: bold;
							color: $accent-300;
							line-height: 2;

							@media screen and (max-width:768px){
								font-size: 5vw;
							}
						}

						p {
							font-size: 18px;

							@media screen and (max-width:768px){
								font-size: 4vw;
								margin: 0px;
							}

							a {
								font-weight: bold;
								color: $accent-300;
							}
						}
					}
				}
			}
		}
	}
}

//Modal Perhitungan
#modalPerhitungan23Bulan {
	.modal-dialog {
		.modal-content {
			width: 800px;

			@media screen and (max-width:768px){
				width: 100%;
			}

			.modal-header {
				text-align: center;
				border-bottom: none;
				margin-bottom: 10px;

				@media screen and (max-width:768px){
					margin-bottom: 0px;
				}

				button {
					color: #F68121;
					opacity: 1;
					font-size: 30px;
				}
				.close {
					margin-top: -20px;
				}

				h4 {
					color: $accent-300;
					font-size: 2em;
					padding: 30px 0px 5px 0px;

					@media screen and (max-width:768px){
						font-size: 6vw;
						padding: 30px 0px 10px 0px;
						color: #717171;
					}
				}
			}

			.modal-body {
				padding-top: 0px;

				.deskripsi {
					text-align: center;

					p {
						color: #676767;
						font-family: 'jenius-sans';
						line-height: 1.5;
						font-size: 14px;
						margin-bottom: 30px;

						@media screen and (max-width:768px){
							font-size: 4vw;
							color: #2D2D2D;
						}
					}
				}

				.table-responsive {
					border-radius: 10px;
					overflow: auto;

					&.angsuran-pinjaman-table {
						height: 300px;

						@media screen and (max-width:768px){
							overflow: auto;
							white-space: nowrap;
							//width: 270px;
							height: 200px;
						}
					}

					.table {
						thead {
							color: $accent-300;
							background-color: $tertiary-300;
							tr {
								th {
									font-family: 'jenius-sans';
									text-align: center;  
								}
							}
						}

						tbody {
							tr {
								td {
									text-align: center;
									font-weight: normal;
    								font-family: 'jenius-sans';
								}

								td:nth-child(n+4){
									background-color: #E5E1DD;
								}

								@media screen and (max-width:768px){
									border-radius: 10px;

									td:nth-child(1) {
										background-color: $tertiary-300;
										color: $accent-300;
										font-family: 'jenius-sans';
										font-weight: normal;
									}

									td:nth-child(n+4){
										background-color:#FFF;
									}
								}
							}

							@media screen and (max-width:768px){
								tr:nth-child(n+3){
									td:nth-child(n+2){
										background-color:#E5E1DD;
									}
								}
							}
						}
					}
				}

				.ringkasan-informasi {
					h4 {
						font-size: 1.2vw;

						&.caption {
							color: $accent-300;
							line-height: 2;

							@media screen and (max-width:768px){
								font-size: 4.2vw;
							}
						}
						
						&.caption-detail {
							color: #2D2D2D;
							line-height: 2;
							font-weight: normal;
							font-family: 'jenius-sans';

							@media screen and (max-width:768px){
								font-size: 4vw;
								line-height: 1.5;
							}

							a {
								font-weight: bold;
								color: $accent-300;
							}
						}
					}
				}
			}
		}
	}
}

//Custom input date
.form-control {
	&.input-date-custom {
		background-color: #F0F0F0;
		height: 26px;
		padding: 0px 0px 0px 5px;
		border: 0.5 solid #707070;
		box-shadow: none;
		background-color: none;
		font-weight: normal;
		font-family: 'jenius-sans';
		text-transform: capitalize;
		font-size: 12px;

		&:focus {
			border-color: none;
			outline: none;
			box-shadow: none;
			background-color: none;
			font-weight: normal;
			font-family: 'jenius-sans';
			text-transform: capitalize;
			font-size: 12px;
		}
	}

	
}

#pengajuan-konten-id {
	.syarat-ketentuan {
		label {
			display: inline;
		}
	}
}

#frm_kalkulator_jangka_waktu{
	opacity:1 !important;
	border:none;

	+ .jcf-select-text {
		display: none;
	}
}

.check-privacy {
	@media screen and (max-width:768px){
		margin-top: 20px;
	}
}

.form-area-business {
	.form-area {
		label {
			&.error {
				@media screen and (max-width:768px){
					text-align: right;
				}
				&.show-error {
					@media screen and (max-width:768px){
						text-align: right;
					}
				}
			}
		}
	}
}

#formBusiness {
	&.form-pengajuan-pinjaman-kecil {
		.btn-primary {
			@include breakpoint (mobile) {
				width: 40%;
				font-size: 1em;
			}
		}
		.form-group {
			margin-bottom: 23px;

			@media screen and (max-width:768px){
				margin-bottom: 10px;
			}

			&.remember {
				label {
					&.error {
						top: 50px;
					}
				}
			}

			label {
				&.error {
					position: absolute;
					top: 80px;
					left: 0;
					@media screen and (max-width:768px){
						position: absolute;
						top: 73px;
					}
					@include breakpoint (mobile) {
						position: relative;
						top: 5px;
					}
				}
			}

			&.privacy-wrapper {
				label {
					&.error {
						position: absolute;
						top: 40px;
						@media screen and (max-width:768px){
							position: absolute;
							top: 30px;
						}
					}
				}
			}

			.jcf-select {
				margin-bottom: 23px;
				@media screen and (max-width:768px){
					margin-bottom: 0px;
				}
			}
		}
	}
}
